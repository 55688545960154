import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useAppSelector } from "@hooks/useAppSelector";
import { getMainPagesSelector } from "@store/selectors";
import { getYouTubeVideoId, isYouTubeUrl } from "@utils/youtubeUrl";
import styles from "./Video.module.scss";

export const Video = () => {
  const { item } = useAppSelector(getMainPagesSelector);
  const renderFirst = useAppSelector((state) => state.barca.firstRender);
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  if (!item?.videos) {
    return null;
  }

  return (
    <motion.div className={styles.videoBlock} ref={ref}>
      <div className={styles.videoPlayer}>
        {(renderFirst || inView) &&
          (isYouTubeUrl(item.videos.video) ? (
            <iframe
              className={styles.video}
              src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                item.videos.video
              )}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            />
          ) : (
            <video
              key={item.videos.id}
              className={styles.video}
              poster={item.videos.preview_path || ""}
              controls
            >
              <source src={item.videos.video} type="video/mp4" />
              Your browser does not support the video tag.
              <track kind="captions" srcLang="en" label="English" />
            </video>
          ))}
      </div>
    </motion.div>
  );
};
