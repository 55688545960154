export const SliceConstants = {
  SignIn: "barcaSlice/signin",
  MainPages: "barcaSlice/mainPages",
  SignInSocial: "barcaSlice/signinSocial",
  VacanvieBish: "barcaSlice/vacancieB",
  VacanvieJal: "barcaSlice/vacancieJ",
  GetProject: "barcaSlice/project",
  GetTrainersB: "barcaSlice/trainersB",
  GetTrainersJ: "barcaSlice/trainersJ",
  GetTrainer: "barcaSlice/trainer",
  GetNews: "newsSlice/news",
  GetNewsJalalAbad: "newsSlice/newsJalalAbad",
  GetNewsView: "newsViewSlice/newsView",
  ReplyVacancy: "barcaSlice/replyVacancy",
  GetVacancieById: "barcaSlice/vacanciesById",
  CreateNewsComment: "commentsSlice/newsComment",
  GetNewsCommentsById: "commentsSlice/newsCommentsById",
  GetNewsCommentsUpById: "commentsSlice/newsCommentsUpById",
  GetNewsCommentsDownById: "commentsSlice/newsCommentsDownById",
  GetNewsCommentsChildrenById: "commentsSlice/newsCommentsChildrenById",
  GetNewsCommentsUpChildrenById: "commentsSlice/newsCommentsUpChildrenById",
  GetNewsCommentsDownChildrenById: "commentsSlice/newsCommentsDownChildrenById",
  GetNewsCommentsReplyById: "commentsSlice/newsCommentsReplyById",
  UpdateNewsCommentById: "commentsSlice/updateNewsCommentById",
  DeleteNewsCommentById: "commentsSlice/deleteNewsCommentById",
  GetSocial: "barcaSlice/social",
  SignUp: "barcaSlice/signup",
  SendCode: "barcaSlice/sendCode",
  CheckCode: "barcaSlice/checkCode",
  Application: "barcaSlice/application",
  ForgotPassword: "barcaSlice/forgotPassword",
  ConfirmPassword: "barcaSlice/confirmPassword",
  GetProfile: "barcaSlice/profile",
  GetReply: "barcaSlice/reply",
  ReadAllReply: "barcaSlice/allReply",
  PutProfile: "barcaSlice/putprofile",
  Logout: "barcaSlice/logout",
  GetServices: "barcaSlice/services",
  GetCafes: "barcaSlice/cafe",
  GetMuseum: "barcaSlice/museum",
  GetCamp: "barcaSlice/camp",
  GetClinics: "barcaSlice/clinics",
  GetChampionship: "barcaSlice/championshipt",
  GetContact: "barcaSlice/contact",
  GetInfoPage: "barcaSlice/infoPage",
  SignInGoogle: "barcaSlice/signInGoogle",
  SignInFacebook: "barcaSlice/SignInFacebook",
  SignInVK: "barcaSlice/signInVK",
  SignInYandex: "barcaSlice/SignInYandex",
};

export const SliceName = {
  Auth: "auth",
  Barca: "barca",
  News: "news",
  NewsView: "newsView",
  NewsComments: "newsComments",
};
