/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Links } from "@enums/links";
import { Badge, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { ImagesAlt } from "@constants/alt";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "@ui-kit/selectors";
import { useNavigate } from "react-router-dom";
import { getProfileSelector, getReplySelector } from "@store/selectors";
import { RoutePath } from "@router/routePath";
import { useAppSelector } from "@hooks/useAppSelector";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { logout } from "@store/slices";
import { CircleNotifications } from "@mui/icons-material";
import { useState } from "react";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey } from "@enums/localStorage";
import { NotificationModal } from "./NotificationModal";
import styles from "./LoginHeader.module.scss";

export const LoginHeader = () => {
  const { t } = useTranslation("translation");
  const profile = useAppSelector(getProfileSelector);
  const { unread } = useAppSelector(getReplySelector);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout()).then(() => {
      LocalStorage.removeItem(LocalStorageKey.AccessToken);
    });
  };

  const onToggle = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.headerLogin}>
      {profile.user.email ? (
        <>
          <div className={styles.badge} onClick={onToggle}>
            <Badge
              size="small"
              offset={[-1, 4]}
              count={unread}
              overflowCount={99}
            >
              <CircleNotifications style={{ color: "white" }} />
              <NotificationModal open={open} onClose={onClose} />
            </Badge>
          </div>
          <Button
            className={styles.email}
            onClick={() => navigate(RoutePath.profile)}
          >
            <UserOutlined /> {profile.user.email}
          </Button>
          <Button
            className={styles.loginButton}
            onClick={() => onLogout()}
            icon={
              <img
                src={Links.logoHeader}
                alt={ImagesAlt}
                className={styles.imgButton}
              />
            }
          >
            {t("login.logout")}
          </Button>
        </>
      ) : (
        <>
          <Button
            icon={<UserOutlined />}
            className={styles.registerButton}
            onClick={() => navigate(RoutePath.login)}
          >
            {t("login.submit")}
          </Button>
          <Button
            className={styles.loginButton}
            onClick={() => navigate(RoutePath.register)}
            icon={
              <img
                src={Links.logoHeader}
                alt={ImagesAlt}
                className={styles.imgButton}
              />
            }
          >
            {t("login.register")}
          </Button>
        </>
      )}
      <LanguageSelector />
    </div>
  );
};
