import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useEffect, useRef } from "react";
import { getProject } from "@store/slices";
import { useAppSelector } from "@hooks/useAppSelector";
import { getProjectSelector } from "@store/selectors";
import { Loader } from "@ui-kit/loader";
import { EmptyPage } from "@features/emptyPage";
import { useInView } from "framer-motion";
import { getYouTubeVideoId, isYouTubeUrl } from "@utils/youtubeUrl";
import { SwiperUI } from "@ui-kit/swiperUI";
import { SEO } from "@utils/SEO";
import { Routes } from "@enums/routes";
import styles from "./Project.module.scss";

const Banner = ({ banner }: { banner: string }) => {
  const refPhoto = useRef(null);
  const isInView = useInView(refPhoto, { once: true });

  return (
    <div className={styles.photoContainer}>
      <div
        ref={refPhoto}
        className={cn(styles.photoBlock, { [styles.active]: isInView })}
      >
        <img src={banner} alt="" loading="lazy" />
      </div>
    </div>
  );
};

export const ProjectLayout = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { item, loading } = useAppSelector(getProjectSelector);

  useEffect(() => {
    dispatch(getProject());
  }, []);

  if (loading && !item) {
    return (
      <div className={styles.loading}>
        <Loader />
      </div>
    );
  }

  if (!item) {
    return <EmptyPage title={t("aboutUs.menu.project")} text={t("empty")} />;
  }

  return (
    <div className={styles.container}>
      <SEO
        title={t("aboutUs.menu.project")}
        url={Routes.ABOUT_US_PROJECT}
        image={item.banners?.banner}
      />

      <div className={styles.project}>
        <div className={styles.header}>
          <h1 className={cn(styles.title)}>
            {item.title || t("aboutUs.menu.project")}
          </h1>
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: item.text || "" }}
        />
        {item.resources?.map((resource) => (
          <div key={resource.title} className={styles.info}>
            <h2 className={styles.heading}>{resource.title}</h2>
            <p>{resource.text}</p>
            <div className={styles.btnBlock}>
              <a
                href={resource.btn_link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.btn}
              >
                {t("aboutUs.menu.btn")}
              </a>
            </div>
          </div>
        ))}

        {item.banners?.banner && (
          <>
            <br />
            <br className={styles.mobile} />
            <Banner banner={item.banners.banner} />
          </>
        )}
        {item.videos?.video && (
          <>
            <br />
            <div className={styles.info}>
              <h2 className={styles.heading}>{t("aboutUs.menu.videoTitle")}</h2>
              <div className={styles.videoBlock}>
                <div className={styles.videoPlayer}>
                  {isYouTubeUrl(item.videos.video) ? (
                    <iframe
                      className={styles.video}
                      src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                        item.videos.video
                      )}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    />
                  ) : (
                    <video
                      className={styles.video}
                      poster={item.videos.preview_path || ""}
                      controls
                    >
                      <source src={item.videos.video} type="video/mp4" />
                      Your browser does not support the video tag.
                      <track kind="captions" srcLang="en" label="English" />
                    </video>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {!!item.images.length && (
          <div className={styles.swiperBlock}>
            <SwiperUI images={item.images} />
          </div>
        )}
      </div>
    </div>
  );
};
