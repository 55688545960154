import React from "react";
import MaskedTextInput from "react-text-mask";
import { codeMask } from "@validates/mask";
import styles from "./FormMaskedInput.module.scss";

export const FormMaskedInput = ({ ...rest }) => {
  return (
    <MaskedTextInput
      {...rest}
      type="text"
      mask={codeMask}
      className={styles.input}
    />
  );
};
