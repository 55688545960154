import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Slide,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  InfoCircleOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { useAlert } from "../../hooks/useAlert";
import { boxStyles, useStyles } from "./alertPopup.styles";

const iconAlertType = {
  error: <WarningOutlined />,
  warning: <WarningOutlined />,
  success: <CheckCircleOutlined />,
  info: <InfoCircleOutlined />,
};

export const AlertPopup = (): JSX.Element => {
  const { t } = useTranslation();
  const { text, type } = useAlert();
  const theme: any = useTheme();
  const classes = useStyles({ theme });

  if (text && type && true) {
    return (
      <div className={classes.alert}>
        <Slide direction="left" in mountOnEnter unmountOnExit>
          <Alert
            icon={
              <Box sx={boxStyles(type as AlertColor)}>
                {iconAlertType[type as AlertColor]}
              </Box>
            }
            severity={type as AlertColor}
          >
            <AlertTitle>{t(`alert.${type}`)}</AlertTitle>
            {text}
          </Alert>
        </Slide>
      </div>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};
