/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { CaretRightOutlined, CloseOutlined } from "@ant-design/icons";
import { LanguageSelectorBurger } from "@ui-kit/selectors";
import { Routes } from "@enums/routes";
import { DetailsMenu, MenuItems } from "./components/DetailsMenu";
import { servicesMenu, filialMenu, aboutUsMenu, articlesMenu } from "./utils";
import styles from "./Header.module.scss";

interface BurgerMenuProps {
  toggleMenu: () => void;
  closeMenuAndNavigate: (path: string) => void;
}

export const BurgerMenu: FC<BurgerMenuProps> = ({
  toggleMenu,
  closeMenuAndNavigate,
}) => {
  const { t } = useTranslation();
  const [menu, setMenu] = useState<{
    data: MenuItems[];
    backText: string;
  } | null>(null);

  const handleShowMenuClick = (data: MenuItems[], backText: string) => {
    setMenu({ data, backText });
  };

  const onClose = () => {
    setMenu(null);
  };

  return (
    <motion.div
      className={styles.background}
      animate={{
        x: 0,
        backgroundColor: "#181733",
      }}
      exit={{
        x: "100%",
        transition: { duration: 0.2 },
      }}
      initial={{ x: "100%" }} // Starts from right (fully hidden)
      transition={{ ease: "linear", duration: 0.2 }}
    >
      <div className={styles.blockBorder2} style={{ height: 55 }}>
        <button onClick={toggleMenu} type="button">
          <CloseOutlined className={styles.closeIcon} />
        </button>
      </div>
      <div className={styles.innerItemsBack}>
        <ul className={styles.listItems}>
          <div
            className={styles.blockBorder}
            onClick={() =>
              handleShowMenuClick(servicesMenu, t("burger.services"))
            }
          >
            <motion.li
              initial={{
                x: "-100px",
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 0.4,
              }}
              className={styles.academy}
            >
              {t("burger.services")}
              <CaretRightOutlined />
            </motion.li>
          </div>{" "}
          <div
            className={styles.blockBorder}
            onClick={() =>
              handleShowMenuClick(filialMenu, t("burger.filial.title"))
            }
          >
            <motion.li
              initial={{
                x: "-100px",
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 0.4,
              }}
              className={styles.academy}
            >
              {t("burger.filial.title")}
              <CaretRightOutlined />
            </motion.li>
          </div>
          <div
            className={styles.blockBorder}
            onClick={() => closeMenuAndNavigate(Routes.CLINIC)}
          >
            <motion.li
              initial={{
                x: "-100px",
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 0.4,
              }}
              className={styles.academy}
            >
              <NavLink to={Routes.CLINIC}>{t("clinic.title")}</NavLink>
            </motion.li>
          </div>
          <div
            className={styles.blockBorder}
            onClick={() => closeMenuAndNavigate(Routes.CHAMPIONSHIP)}
          >
            <motion.li
              initial={{
                x: "-100px",
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 0.4,
              }}
              className={styles.academy}
            >
              <NavLink to={Routes.CHAMPIONSHIP}>
                {t("burger.championship")}
              </NavLink>
            </motion.li>
          </div>
          <div className={styles.blockBorder}>
            <motion.li
              initial={{
                x: "-100px",
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 0.4,
              }}
              className={styles.academy}
            >
              <NavLink to={process.env.REACT_APP_STORE_URL || ""}>
                {t("burger.store")}
              </NavLink>
            </motion.li>
          </div>
          <div
            className={styles.blockBorder}
            onClick={() =>
              handleShowMenuClick(aboutUsMenu, t("burger.aboutUs.title"))
            }
          >
            <motion.li
              initial={{
                x: "-100px",
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 0.4,
              }}
              className={styles.academy2}
            >
              {t("burger.aboutUs.title")}
              <CaretRightOutlined />
            </motion.li>
          </div>{" "}
          <div
            className={styles.blockBorder}
            onClick={() =>
              handleShowMenuClick(articlesMenu, t("burger.news.title"))
            }
          >
            <motion.li
              initial={{
                x: "-100px",
              }}
              animate={{
                x: 0,
              }}
              transition={{
                duration: 0.4,
              }}
              className={styles.academy2}
            >
              {t("burger.news.title")}
              <CaretRightOutlined />
            </motion.li>
          </div>
        </ul>
        <LanguageSelectorBurger />
      </div>
      <AnimatePresence>
        {menu && (
          <DetailsMenu
            onClose={onClose}
            closeMenuAndNavigate={closeMenuAndNavigate}
            backTitle={menu.backText}
            items={menu.data}
          />
        )}
      </AnimatePresence>
    </motion.div>
  );
};
