import { lazy } from "react";
import { RegisterPage } from "@pages/RegisterPage";
import { AppRoutes, Routes } from "@enums/routes";
import { RouteProps } from "react-router-dom";
import { LoginPage } from "@pages/LoginPage";
import { MainPage } from "@pages/MainPage";
import { ForgotPasswordPage } from "@pages/ForgotPasswordPage";
import { ConfirmPasswordPage } from "@pages/ConfirmPasswordPage";
import { BishkekPage } from "@pages/BishkekPage";
import { JalalAbad } from "@pages/JalalAbad";
import { CoachsPage } from "@pages/CoachsPage";
import { NewsPage } from "@pages/NewsPage";
import { AcademyCampPage } from "@pages/AcademyCampPage";
import { CafePage } from "@pages/CafePage";
import { MuseumPage } from "@pages/MuseumPage";
import { CafeJalalAbadPage } from "@pages/CafeJalalAbadPage";
import { ProfilePage } from "@pages/ProfilePage";
// import { NotFoundPage } from "@pages/NotFoundPage";
import { VacanciesPage } from "@pages/VacanciesPage";
import { ContactPage } from "@pages/ContactPage";
import { ProjectPage } from "@pages/ProjectPage";
import { PrivacyPage } from "@pages/PrivacyPage";
import { FAQPage } from "@pages/FAQPage";
import { ClinicPage } from "@pages/ClinicPage";
import { ChampionshipPage } from "@pages/ChampionshipPage";

const NotFoundPage = lazy(() => import("@pages/NotFoundPage/NotFoundPage"));

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: Routes.MAIN,
  [AppRoutes.LOGIN]: Routes.LOGIN,
  [AppRoutes.REGISTER]: Routes.REGISTER,
  [AppRoutes.ABOUT_US]: Routes.ABOUT_US,
  [AppRoutes.VACANCIE]: Routes.VACANCIE,
  [AppRoutes.VACANCY_BISH_DETAILS]: Routes.VACANCY_BISH_DETAILS,
  [AppRoutes.VACANCY_JAL_DETAILS]: Routes.VACANCY_JAL_DETAILS,
  [AppRoutes.ABOUT_US_PROJECT]: Routes.ABOUT_US_PROJECT,
  [AppRoutes.ABOUT_US_COACHES]: Routes.ABOUT_US_COACHES,
  [AppRoutes.VACANCIE_JALAL_ABAD]: Routes.VACANCIE_JALAL_ABAD,
  [AppRoutes.COACH_ID]: Routes.COACH_ID,
  [AppRoutes.ARTICLES]: Routes.ARTICLES,
  [AppRoutes.ARTICLES_COMMON]: Routes.ARTICLES_COMMON,
  [AppRoutes.ARTICLES_BISH]: Routes.ARTICLES_BISH,
  [AppRoutes.ARTICLES_JA]: Routes.ARTICLES_JA,
  [AppRoutes.ARTICLE_COMMON]: Routes.ARTICLE_COMMON,
  [AppRoutes.ARTICLE_BISH]: Routes.ARTICLE_BISH,
  [AppRoutes.ARTICLE_JA]: Routes.ARTICLE_JA,
  [AppRoutes.SERVICES]: Routes.SERVICES,
  [AppRoutes.SERVICES_CAFE]: Routes.SERVICES_CAFE,
  [AppRoutes.SERVICES_CAFE_JA]: Routes.SERVICES_CAFE_JA,
  [AppRoutes.ACADEMY_CAMP]: Routes.ACADEMY_CAMP,
  [AppRoutes.ACADEMY_CAMP_J]: Routes.ACADEMY_CAMP_J,
  [AppRoutes.MUSEUM]: Routes.MUSEUM,
  [AppRoutes.CLINIC]: Routes.CLINIC,
  [AppRoutes.JALAL_ABAD]: Routes.JALAL_ABAD,
  [AppRoutes.BISHKEK]: Routes.BISHKEK,
  [AppRoutes.CHAMPIONSHIP]: Routes.CHAMPIONSHIP,
  [AppRoutes.FAQ]: Routes.FAQ,
  [AppRoutes.CONTACT]: Routes.CONTACT,
  [AppRoutes.PRIVACY]: Routes.PRIVACY,
  [AppRoutes.FORGOT_PASSWORD]: Routes.FORGOT_PASSWORD,
  [AppRoutes.CONFIRM_PASSWORD]: Routes.CONFIRM_PASSWORD,
  [AppRoutes.PROFILE]: Routes.PROFILE,
  [AppRoutes.NOT_FOUND]: Routes.NOT_FOUND,
};

export const routeConfig: Record<AppRoutes, RouteProps> = {
  [AppRoutes.MAIN]: {
    path: RoutePath.main,
    element: <MainPage />,
  },
  [AppRoutes.LOGIN]: {
    path: RoutePath.login,
    element: <LoginPage />,
  },
  [AppRoutes.REGISTER]: {
    path: RoutePath.register,
    element: <RegisterPage />,
  },
  [AppRoutes.VACANCIE]: {
    path: RoutePath["vacancies/bishkek"],
    element: <VacanciesPage />,
  },
  [AppRoutes.VACANCIE_JALAL_ABAD]: {
    path: RoutePath["vacancies/jalal-abad"],
    element: <VacanciesPage />,
  },
  [AppRoutes.VACANCY_BISH_DETAILS]: {
    path: RoutePath["vacancies/bishkek/:id"],
    element: <VacanciesPage />,
  },
  [AppRoutes.VACANCY_JAL_DETAILS]: {
    path: RoutePath["vacancies/jalal-abad/:id"],
    element: <VacanciesPage />,
  },
  [AppRoutes.ABOUT_US]: {
    path: RoutePath["about-us"],
    element: <NotFoundPage />,
  },
  [AppRoutes.ABOUT_US_PROJECT]: {
    path: RoutePath["about-us/project"],
    element: <ProjectPage />,
  },
  [AppRoutes.ABOUT_US_COACHES]: {
    path: RoutePath["about-us/coaches/barca-academy"],
    element: <CoachsPage />,
  },
  [AppRoutes.PROFILE]: {
    path: RoutePath.profile,
    element: <ProfilePage />,
  },
  [AppRoutes.COACH_ID]: {
    path: RoutePath["coach/:id"],
    element: <CoachsPage />,
  },
  [AppRoutes.ARTICLES]: {
    path: RoutePath.news,
    element: <NotFoundPage />,
  },
  [AppRoutes.ARTICLES_COMMON]: {
    path: RoutePath["news/common"],
    element: <NewsPage />,
  },
  [AppRoutes.ARTICLES_BISH]: {
    path: RoutePath["news/bishkek"],
    element: <NewsPage />,
  },
  [AppRoutes.ARTICLES_JA]: {
    path: RoutePath["news/jalal-abad"],
    element: <NewsPage />,
  },
  [AppRoutes.ARTICLE_COMMON]: {
    path: RoutePath["news/common/:id"],
    element: <NewsPage />,
  },
  [AppRoutes.ARTICLE_BISH]: {
    path: RoutePath["news/bishkek/:id"],
    element: <NewsPage />,
  },
  [AppRoutes.ARTICLE_JA]: {
    path: RoutePath["news/jalal-abad/:id"],
    element: <NewsPage />,
  },
  [AppRoutes.SERVICES]: {
    path: RoutePath.services,
    element: <NotFoundPage />,
  },
  [AppRoutes.SERVICES_CAFE_JA]: {
    path: RoutePath["services/jalal-abad/cafe"],
    element: <CafeJalalAbadPage />,
  },
  [AppRoutes.SERVICES_CAFE]: {
    path: RoutePath["services/bishkek/cafe"],
    element: <CafePage />,
  },
  [AppRoutes.MUSEUM]: {
    path: RoutePath["services/bishkek/museum"],
    element: <MuseumPage />,
  },
  [AppRoutes.CLINIC]: {
    path: RoutePath.clinic,
    element: <ClinicPage />,
  },
  [AppRoutes.JALAL_ABAD]: {
    path: RoutePath["jalal-abad"],
    element: <JalalAbad />,
  },
  [AppRoutes.ACADEMY_CAMP]: {
    path: RoutePath["services/bishkek/academy-camp"],
    element: <AcademyCampPage />,
  },
  [AppRoutes.ACADEMY_CAMP_J]: {
    path: RoutePath["services/jalal-abad/camp"],
    element: <AcademyCampPage />,
  },
  [AppRoutes.BISHKEK]: {
    path: RoutePath.bishkek,
    element: <BishkekPage />,
  },
  [AppRoutes.CHAMPIONSHIP]: {
    path: RoutePath.championship,
    element: <ChampionshipPage />,
  },
  [AppRoutes.FAQ]: {
    path: RoutePath["asked-questions"],
    element: <FAQPage />,
  },
  [AppRoutes.CONTACT]: {
    path: RoutePath.contact,
    element: <ContactPage />,
  },
  [AppRoutes.PRIVACY]: {
    path: RoutePath["privacy-policy"],
    element: <PrivacyPage />,
  },
  [AppRoutes.FORGOT_PASSWORD]: {
    path: RoutePath["forgot-password"],
    element: <ForgotPasswordPage />,
  },
  [AppRoutes.CONFIRM_PASSWORD]: {
    path: RoutePath["forget-password"],
    element: <ConfirmPasswordPage />,
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath["*"],
    element: <NotFoundPage />,
  },
};
