import { FC } from "react";
import { Helmet, HelmetProps } from "react-helmet-async";
import { useTranslation } from "react-i18next";

type SEOProps = {
  title?: string;
  titleNew?: string;
  description?: string;
  image?: string;
  url: string;
  keywords?: string;
} & HelmetProps;

export const SEO: FC<SEOProps> = (props) => {
  const { i18n } = useTranslation();
  const { title, titleNew, description, url, image, ...others } = props;

  const desc =
    description ||
    "Откройте для себя Barça Experience: Уникальный Проект в Центральной Азии BARÇA Experience – это не просто проект, это целый мир футбольной страсти, который был создан для поклонников футбола в самом сердце Центральной Азии.";

  const img = image || "https://s3.barcelona.kg/static/academyLogo2.webp";
  const urlBase = `https://experience.barcelona.kg${url || ""}`;

  return (
    <Helmet {...others}>
      <title>{!titleNew ? `Barça Experience - ${title}` : titleNew}</title>
      <meta name="description" content={desc} />
      <link rel="canonical" href={urlBase} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image" content={img} />
      <meta name="twitter:url" content={urlBase} />
      <meta name="twitter:site" content="@FCB" />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={img} />
      <meta property="og:url" content={urlBase} />
      <meta property="og:site_name" content="Barça Experience" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={i18n.language || "ru"} />
      <meta property="og:locale:alternate" content="ru_RU" />

      <meta name="robots" content="index, follow" />
      <meta httpEquiv="content-language" content={i18n.language || "ru"} />
      <script>
        {`
          (function (w, d, u) {
            var s = d.createElement('script'); s.async = true; s.src = u + '?' + (Date.now() / 60000 | 0);
            var h = d.getElementsByTagName('script')[0]; h.parentNode.insertBefore(s, h);
          })(window, document, 'https://cdn-ru.bitrix24.ru/b29374898/crm/site_button/loader_4_e46hzr.js');
        `}
      </script>
    </Helmet>
  );
};
