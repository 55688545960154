/* eslint-disable no-nested-ternary */
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getMainPagesSelector } from "@store/selectors";
import { Loader } from "@ui-kit/loader";
import cn from "classnames";
import styles from "./Slider.module.scss";

export const OfferSlider = () => {
  const { loading, item } = useSelector(getMainPagesSelector);
  const [slide, setSlide] = useState<number>(0);

  return (
    <div className={styles.slider}>
      {loading ? (
        <div className={styles.loading}>
          <div className={styles.slide}>
            <Loader className={styles.loader} />
          </div>
          <div className={styles.slide}>
            <Loader className={styles.loader} />
          </div>
          <div className={styles.slide}>
            <Loader className={styles.loader} />
          </div>
        </div>
      ) : item?.banners.length ? (
        item.banners.slice(0, 3).map((banner, index) => (
          <Link
            key={banner.id}
            to={banner.link}
            target="_blank"
            className={cn(styles.link, {
              [styles.isActive]: slide === banner.id,
            })}
            onMouseEnter={() => setSlide(banner.id)}
          >
            <img src={banner.banner} alt={`banner${index}`} />
            <div className={styles.content}>
              <h4 className={styles.title}>{banner.title}</h4>
              {banner.description && (
                <p className={styles.desc}>{banner.description}</p>
              )}
            </div>
            <div className={styles.gradient} />
          </Link>
        ))
      ) : (
        <div className={styles.loading}>
          <div className={styles.slide} />
          <div className={styles.slide} />
          <div className={styles.slide} />
        </div>
      )}
    </div>
  );
};
