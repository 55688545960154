import { ItemType } from "antd/es/menu/hooks/useItems";
import { TFunction } from "i18next";

export type IMenuItem = {
  key: React.Key;
  href: string;
  label: string;
  children?: IMenuItem[];
} & ItemType;

export const getLink = (item: IMenuItem, t: TFunction): IMenuItem => {
  return {
    ...item,
    label: t(item.label),
  };
};
