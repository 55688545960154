/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC } from "react";
import { t } from "i18next";
import { motion } from "framer-motion";
import { CaretLeftOutlined } from "@ant-design/icons";
import styles from "./DetailsMenu.module.scss";

export type MenuItems = {
  to?: string;
  title: string;
};

interface ServiceBlockProps {
  closeMenuAndNavigate: (path: string) => void;
  onClose: () => void;
  backTitle: string;
  items: MenuItems[];
}

export const DetailsMenu: FC<ServiceBlockProps> = ({
  closeMenuAndNavigate,
  onClose,
  backTitle,
  items,
}) => {
  return (
    <motion.div
      className={styles.additionalBlock}
      animate={{
        x: 0,
        backgroundColor: "#181733",
      }}
      exit={{
        x: "100%",
        transition: { duration: 0.2 },
      }}
      initial={{ x: "100%" }}
      transition={{ ease: "linear", duration: 0.2 }}
    >
      <div className={styles.listItems}>
        <motion.div
          initial={{
            x: "100%",
          }}
          animate={{
            x: 0,
          }}
          transition={{
            duration: 0.2,
            ease: "easeInOut",
          }}
          className={styles.blockBorder3}
          onClick={onClose}
        >
          <CaretLeftOutlined />
          <li className={styles.academy2}>{t(backTitle)}</li>
        </motion.div>
        {items.map((item, index) => (
          <motion.div
            key={item.title}
            initial={{
              x: "100%",
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: Number(`0.${index + 2}`),
              delay: 0.1,
              ease: "easeInOut",
            }}
            className={styles.blockBorder2}
            onClick={() =>
              item.to ? closeMenuAndNavigate(item.to) : undefined
            }
          >
            <li className={styles[item.to ? "academy2" : "academy"]}>
              {t(item.title)}
            </li>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};
