/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { AuthApi } from "@constants/api";
import { SliceConstants, SliceName } from "@constants/slices";
import { LocalStorageKey, LocalStorageValues } from "@enums/localStorage";
import { Routes } from "@enums/routes";
import {
  IReply,
  INetworks,
  IProfile,
  IService,
  ITrainers,
  IVacancies,
  IGetParams,
  CityTypes,
  IServiceWithTo,
  IProject,
  IContact,
  ContactType,
  IInfoPage,
  InfoPageType,
  IContactRes,
  IChampionshipService,
  InfoPageViewType,
  IExpPagesRes,
} from "@enums/slices";
import { LocalStorage } from "@localStorage/localStorage";
import { getMainPage } from "@mappers/mainPage";
import {
  CheckCode,
  ConfirmSendPasswordType,
  ForgotPasswordType,
  SendCode,
  SignInType,
  SignSendUpType,
} from "@models/login";
import { AlertColor } from "@mui/material";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "@utils/axios";
import { message } from "antd";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export interface BarcaState {
  loading: boolean;
  email: string;
  password: string;
  emailConfirm: boolean;
  mainPages: {
    loading: boolean;
    item: IExpPagesRes | null;
  };
  project: {
    item: IProject | null;
    loading: boolean;
  };
  bishkek_trainers: {
    trainers: ITrainers[];
    amount: number;
    loading: boolean;
  };
  jalal_abad_trainers: {
    trainers: ITrainers[];
    amount: number;
    loading: boolean;
  };
  trainer: {
    item: ITrainers | null;
    loading: boolean;
  };
  networks: {
    common: INetworks[];
    news: INetworks[];
  };
  services: {
    loading: boolean;
    items: IServiceWithTo[];
    hasService: boolean;
    error?: string;
  };
  cafe: {
    loading: boolean;
    item: IService | null;
    error?: string;
  };
  cafe_j: {
    loading: boolean;
    item: IService | null;
    error?: string;
  };
  museum: {
    loading: boolean;
    item: IService | null;
    error?: string;
  };
  camp: {
    loading: boolean;
    item: IService | null;
    error?: string;
  };
  clinics: {
    loading: boolean;
    item: IService | null;
    error?: string;
  };
  championship: {
    loading: boolean;
    item: IChampionshipService | null;
    error?: string;
  };
  application: {
    status?: "error" | "success";
    error?: string;
    loading: boolean;
  };
  profile: {
    loading: boolean;
    user: IProfile;
  };
  vacancieBish: {
    loading: boolean;
    vacancies: IVacancies[];
    amount: number;
    error?: string;
  };
  vacancieJal: {
    loading: boolean;
    vacancies: IVacancies[];
    amount: number;
    error?: string;
  };
  personalVisible: boolean;
  vacancieById: IVacancies | null;
  replyVacancy: {
    loading: boolean;
    status?: "success" | "error";
  };
  reply: {
    items: IReply[];
    amount: number;
    unread: number;
    loading: boolean;
  };
  contact: {
    loading: boolean;
    items: IContactRes[];
    error?: string;
  };
  infoPage: {
    loading: boolean;
    items: IInfoPage[];
    error?: string;
  };
  firstRender: boolean;
}

const initialState: BarcaState = {
  loading: false,
  email: "",
  password: "",
  emailConfirm: false,
  mainPages: {
    loading: false,
    item: null,
  },
  project: {
    item: null,
    loading: false,
  },
  bishkek_trainers: {
    trainers: [],
    amount: 0,
    loading: false,
  },
  jalal_abad_trainers: {
    trainers: [],
    amount: 0,
    loading: false,
  },
  trainer: {
    item: null,
    loading: false,
  },
  personalVisible: false,
  vacancieBish: {
    loading: false,
    amount: 0,
    vacancies: [],
  },
  vacancieJal: {
    loading: false,
    amount: 0,
    vacancies: [],
  },
  networks: {
    common: [],
    news: [],
  },
  services: {
    loading: false,
    items: [],
    hasService: false,
    error: undefined,
  },
  cafe: {
    loading: false,
    item: null,
    error: undefined,
  },
  cafe_j: {
    loading: false,
    item: null,
    error: undefined,
  },
  vacancieById: null,
  replyVacancy: {
    loading: false,
    status: undefined,
  },
  museum: {
    loading: false,
    item: null,
    error: undefined,
  },
  camp: {
    loading: false,
    item: null,
    error: undefined,
  },
  clinics: {
    loading: false,
    item: null,
    error: undefined,
  },
  championship: {
    loading: false,
    item: null,
    error: undefined,
  },
  application: {
    loading: false,
  },
  profile: {
    loading: false,
    user: {
      id: "",
      name: "",
      lastname: "",
      email: "",
      phone: "",
    },
  },
  reply: {
    items: [],
    amount: 0,
    unread: 0,
    loading: false,
  },
  contact: {
    items: [],
    loading: false,
  },
  infoPage: {
    items: [],
    loading: false,
  },
  firstRender: false,
};

export const getProfile = createAsyncThunk(
  SliceConstants.GetProfile,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(BASE_URL + AuthApi.Profile);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const signIn = createAsyncThunk(
  SliceConstants.SignIn,
  async (
    {
      values: props,
      setAlert,
    }: {
      values: SignInType;
      setAlert: (alertText: string, alertType: AlertColor) => void;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await $api.post(BASE_URL + AuthApi.Login, props);
      LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
      dispatch(getProfile());

      return data;
    } catch (error: any) {
      setAlert(error.response.data.message, "error");
      return rejectWithValue(error);
    }
  }
);

export const getReply = createAsyncThunk(
  SliceConstants.GetReply,
  async (params: { skip?: number; limit?: number }, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(BASE_URL + AuthApi.Reply, {
        params,
      });

      return { items: data.items, amount: data.amount, unread: data.unread };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const readAllReply = createAsyncThunk(
  SliceConstants.ReadAllReply,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await $api.patch(BASE_URL + AuthApi.ReadAllReply);

      return { items: data.items, amount: data.amount, unread: data.unread };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getMainPages = createAsyncThunk(
  SliceConstants.MainPages,
  async (params: IGetParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(BASE_URL + AuthApi.MainPages, {
        params,
      });

      return getMainPage(data.items[0]);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getVacancieById = createAsyncThunk(
  SliceConstants.GetVacancieById,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(`${BASE_URL + AuthApi.Vacancies}/${id}`);
      return data.vacancy;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getVacanciesBish = createAsyncThunk(
  SliceConstants.VacanvieBish,
  async (params: IGetParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(
        `${
          BASE_URL + AuthApi.Vacancies
        }?vacancy_type=barca-experience&vacancy_city=bishkek`,
        { params }
      );
      return { list: data.items, amount: data.amount };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getVacanciesJal = createAsyncThunk(
  SliceConstants.VacanvieJal,
  async (params: IGetParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(
        `${
          BASE_URL + AuthApi.Vacancies
        }?vacancy_type=barca-experience&vacancy_city=jalal-abad`,
        {
          params,
        }
      );
      return { list: data.items, amount: data.amount };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const editProfile = createAsyncThunk(
  SliceConstants.PutProfile,
  async (props: Partial<IProfile>, { rejectWithValue }) => {
    try {
      const { data } = await $api.put(BASE_URL + AuthApi.Profile, props);
      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getProject = createAsyncThunk(
  SliceConstants.GetProject,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(`${BASE_URL + AuthApi.Project}`);

      return data;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getTrainersBishkek = createAsyncThunk(
  SliceConstants.GetTrainersB,
  async (
    { region, ...params }: IGetParams & { region: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(
        `${BASE_URL + AuthApi.Trainers}?trainer_type=${region}`,
        { params }
      );
      return { list: data.items, amount: data.amount };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getTrainersJalalAbad = createAsyncThunk(
  SliceConstants.GetTrainersJ,
  async (
    { region, ...params }: IGetParams & { region: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(
        `${BASE_URL + AuthApi.Trainers}?trainer_type=${region}`,
        { params }
      );
      return { list: data.items, amount: data.amount };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getTrainer = createAsyncThunk(
  SliceConstants.GetTrainer,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(`${BASE_URL + AuthApi.Trainers}/${id}`);
      return data.trainer;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getSocialNetworks = createAsyncThunk(
  SliceConstants.GetSocial,
  async (
    params: IGetParams & { type?: CityTypes | "news" },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(BASE_URL + AuthApi.Social, {
        params,
      });
      return data.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getServices = createAsyncThunk(
  SliceConstants.GetServices,
  async (
    params: IGetParams & { service_type?: CityTypes },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(`${BASE_URL + AuthApi.Services}`, {
        params,
      });

      const keys = Object.keys(data);

      return {
        services: Object.values(data).map((service: any, idx) => ({
          ...service,
          to:
            keys[idx] === "clinic" || keys[idx] === "championship"
              ? `/${keys[idx]}`
              : `${Routes.SERVICES}/${params.service_type}/${keys[idx]}`,
        })),
        hasService: !!data,
      };
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getCafes = createAsyncThunk(
  SliceConstants.GetCafes,
  async (
    params: IGetParams & { cafe_type?: CityTypes },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(`${BASE_URL + AuthApi.Cafe}`, { params });
      return data.items[0];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getMuseum = createAsyncThunk(
  SliceConstants.GetMuseum,
  async (
    params: IGetParams & { museum_type?: CityTypes },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(`${BASE_URL + AuthApi.Museum}`, {
        params,
      });
      return data.items[0];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getCamps = createAsyncThunk(
  SliceConstants.GetCamp,
  async (
    params: IGetParams & { camp_type?: CityTypes },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(`${BASE_URL + AuthApi.Camp}`, { params });
      return data.items[0];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getClinics = createAsyncThunk(
  SliceConstants.GetClinics,
  async (params: IGetParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(`${BASE_URL + AuthApi.Clinics}`, {
        params,
      });
      return data.items[0];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getChampionships = createAsyncThunk(
  SliceConstants.GetChampionship,
  async (params: IGetParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(`${BASE_URL + AuthApi.Championships}`, {
        params,
      });
      return data.items[0];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  SliceConstants.Logout,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await $api.post(BASE_URL + AuthApi.Logout);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const sendCode = createAsyncThunk(
  SliceConstants.SendCode,
  async (props: SendCode, { rejectWithValue }) => {
    try {
      const { data } = await $api.post(BASE_URL + AuthApi.SendCode, props);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkCode = createAsyncThunk(
  SliceConstants.CheckCode,
  async (props: CheckCode, { rejectWithValue }) => {
    try {
      const { data } = await $api.post(BASE_URL + AuthApi.CheckCode, props);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const replyVacancy = createAsyncThunk(
  SliceConstants.ReplyVacancy,
  async (props: any, { rejectWithValue }) => {
    try {
      const { data } = await $api.post(BASE_URL + AuthApi.VacancyReply, props);
      return data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const signInGoogle = createAsyncThunk(
  SliceConstants.SignInGoogle,
  async (
    { values: props, navigate }: { values: any; navigate: any },
    { rejectWithValue }
  ) => {
    const queryString = Object.keys(props)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(props[key])}`
      )
      .join("&");
    try {
      const { data } = await $api.get(
        `${BASE_URL + AuthApi.LoginByGoogle}?${queryString}`
      );

      if (data.accessToken) {
        LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
        navigate(Routes.MAIN);
      }

      return data;
    } catch (error: any) {
      message.error(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const SignInFacebook = createAsyncThunk(
  SliceConstants.SignInFacebook,
  async (
    { values: props, navigate }: { values: any; navigate: any },
    { rejectWithValue }
  ) => {
    try {
      const queryString = Object.keys(props)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(props[key])}`
        )
        .join("&");
      const { data } = await $api.get(
        `${BASE_URL}${AuthApi.LoginByFacebook}?${queryString}`
      );

      if (data.accessToken) {
        LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
        navigate(Routes.MAIN);
      }

      return data;
    } catch (error: any) {
      message.error(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const SignInYandex = createAsyncThunk(
  SliceConstants.SignInYandex,
  async (
    { code, navigate }: { code: string; navigate: any },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(`${BASE_URL}${AuthApi.LoginByYandex}`, {
        params: { code },
      });

      if (data.accessToken) {
        LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
        navigate(Routes.MAIN);
      }

      return data;
    } catch (error: any) {
      message.error(error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const signUp = createAsyncThunk(
  SliceConstants.SignUp,
  async (
    {
      values: props,
      navigate,
      setAlert,
    }: {
      values: SignSendUpType;
      navigate: any;
      setAlert: any;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.post(BASE_URL + AuthApi.Register, props);

      if (data.accessToken) {
        LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
        navigate(Routes.MAIN);
      }

      return data;
    } catch (error: any) {
      setAlert(error.response.data.message, "error");
      return rejectWithValue(error);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  SliceConstants.ForgotPassword,
  async (
    {
      values: props,
      navigate,
      setAlert,
    }: {
      values: ForgotPasswordType;
      navigate: any;
      setAlert: (alertText: string, alertType: AlertColor) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.post(
        BASE_URL + AuthApi.ForgotPassword,
        props
      );
      navigate(Routes.LOGIN);
      return data;
    } catch (error: any) {
      setAlert(error.response.data.message, "error");
      return rejectWithValue(error);
    }
  }
);

export const confirmPassword = createAsyncThunk(
  SliceConstants.ConfirmPassword,
  async (
    {
      values: props,
      navigate,
      setAlert,
    }: {
      values: ConfirmSendPasswordType;
      navigate: any;
      setAlert: (alertText: string, alertType: AlertColor) => void;
    },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.post(
        BASE_URL + AuthApi.ConfirmPassword,
        props
      );
      navigate(Routes.LOGIN);
      LocalStorage.setItem(LocalStorageKey.AccessToken, data.accessToken);
      return data;
    } catch (error: any) {
      setAlert(error.response.data.message, "error");
      return rejectWithValue(error);
    }
  }
);

export const getContact = createAsyncThunk(
  SliceConstants.GetContact,
  async ({ type }: { type: ContactType }, { rejectWithValue }) => {
    try {
      const { data } = await $api.get<{ items: IContact[] }>(
        `${BASE_URL + AuthApi.Contact}/${type}`
      );
      return data.items.map((item) => ({
        ...item,
        workingHours: item.workingHours.map((contact, idx) => {
          const [key, ...value] = contact.split(":").map((part) => part.trim());
          return { id: idx, label: key, text: value.join(":") };
        }),
      }));
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getInfoPage = createAsyncThunk(
  SliceConstants.GetInfoPage,
  async (
    { type, view }: { type: InfoPageType; view: InfoPageViewType },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await $api.get(
        `${
          BASE_URL + AuthApi.InfoPage
        }?info_page_type=${type}&view_type=${view}`
      );
      return data.items;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const barcaSlice = createSlice({
  name: SliceName.Barca,
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        email: action.payload,
      };
    },
    setPassword: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        password: action.payload,
      };
    },
    setPersonal: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        personalVisible: action.payload,
      };
    },
    setStatus: (state) => {
      return {
        ...state,
        application: {
          ...state.application,
          status: undefined,
        },
      };
    },
    setUnreadReply: (state, action: PayloadAction<string>) => {
      const { items, unread } = state.reply;

      const item = items.find((comm) => comm.comment.id === action.payload);

      return {
        ...state,
        reply: {
          ...state.reply,
          items: items.map((comm) =>
            comm.id === item?.id
              ? {
                  ...comm,
                  read: true,
                }
              : comm
          ),
          unread: !item?.read ? unread - 1 : unread,
        },
      };
    },
    setFirstRender: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        firstRender: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(signIn.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(signIn.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(signUp.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(signUp.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(signUp.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(SignInYandex.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(SignInYandex.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(SignInYandex.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(forgotPassword.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(forgotPassword.fulfilled, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(forgotPassword.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(getProfile.pending, (state) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: true,
        },
      };
    });
    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      return {
        ...state,
        profile: {
          loading: false,
          user: payload,
        },
      };
    });
    builder.addCase(getProfile.rejected, (state) => {
      return {
        ...state,
        profile: {
          loading: false,
          user: {
            id: "",
            name: "",
            lastname: "",
            email: "",
            phone: "",
          },
        },
      };
    });
    builder.addCase(editProfile.pending, (state) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: true,
        },
      };
    });
    builder.addCase(editProfile.fulfilled, (state, { payload }) => {
      return {
        ...state,
        profile: {
          loading: false,
          user: payload,
        },
      };
    });
    builder.addCase(editProfile.rejected, (state) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: false,
        },
      };
    });
    builder.addCase(logout.fulfilled, (state) => {
      return {
        ...state,
        profile: {
          loading: false,
          user: {
            id: "",
            name: "",
            lastname: "",
            email: "",
            phone: "",
          },
        },
      };
    });
    builder.addCase(getReply.pending, (state) => {
      return {
        ...state,
        reply: {
          ...state.reply,
          loading: true,
        },
      };
    });
    builder.addCase(getReply.fulfilled, (state, { meta, payload }) => {
      const list =
        (meta.arg.skip || 0) === 0
          ? payload.items
          : state.reply.items.concat(payload.items);

      return {
        ...state,
        reply: {
          items: list,
          amount: payload.amount,
          unread: payload.unread,
          loading: false,
        },
      };
    });
    builder.addCase(getReply.rejected, (state) => {
      return {
        ...state,
        reply: {
          items: [],
          amount: 0,
          unread: 0,
          loading: false,
        },
      };
    });
    builder.addCase(readAllReply.pending, (state) => {
      return {
        ...state,
        reply: {
          ...state.reply,
          loading: true,
        },
      };
    });
    builder.addCase(readAllReply.fulfilled, (state, { payload }) => {
      return {
        ...state,
        reply: {
          items: payload.items,
          amount: payload.amount,
          unread: payload.unread,
          loading: false,
        },
      };
    });
    builder.addCase(readAllReply.rejected, (state) => {
      return {
        ...state,
        reply: {
          ...state.reply,
          loading: false,
        },
      };
    });
    builder.addCase(checkCode.fulfilled, (state, { payload }) => {
      return {
        ...state,
        emailConfirm: payload.success,
      };
    });
    builder.addCase(checkCode.rejected, (state) => {
      return {
        ...state,
        emailConfirm: false,
      };
    });
    builder.addCase(getMainPages.pending, (state) => {
      return {
        ...state,
        mainPages: {
          ...state.mainPages,
          loading: true,
        },
      };
    });
    builder.addCase(getMainPages.fulfilled, (state, { payload }) => {
      return {
        ...state,
        mainPages: {
          loading: false,
          item: payload,
        },
      };
    });
    builder.addCase(getMainPages.rejected, (state) => {
      return {
        ...state,
        mainPages: {
          loading: false,
          item: null,
        },
      };
    });
    builder.addCase(getVacancieById.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(getVacancieById.fulfilled, (state, { payload }) => {
      return {
        ...state,
        loading: false,
        vacancieById: payload,
      };
    });
    builder.addCase(getVacancieById.rejected, (state) => {
      return {
        ...state,
        loading: false,
        vacancieById: null,
      };
    });
    builder.addCase(replyVacancy.pending, (state) => {
      return {
        ...state,
        replyVacancy: {
          loading: true,
          status: undefined,
        },
      };
    });
    builder.addCase(replyVacancy.fulfilled, (state) => {
      return {
        ...state,
        replyVacancy: {
          loading: false,
          status: "success",
        },
      };
    });
    builder.addCase(replyVacancy.rejected, (state) => {
      return {
        ...state,
        replyVacancy: {
          loading: false,
          status: "error",
        },
      };
    });
    builder.addCase(getProject.pending, (state) => {
      return {
        ...state,
        project: {
          ...state.project,
          loading: true,
        },
      };
    });
    builder.addCase(getProject.fulfilled, (state, { payload }) => {
      return {
        ...state,
        project: {
          loading: false,
          item: payload,
        },
      };
    });
    builder.addCase(getProject.rejected, (state) => {
      return {
        ...state,
        project: {
          item: null,
          loading: false,
        },
      };
    });
    builder.addCase(
      getTrainersBishkek.fulfilled,
      (state, { payload, meta }) => {
        const { skip } = meta.arg;
        return {
          ...state,
          bishkek_trainers: {
            loading: false,
            trainers:
              (skip || 0) === 0
                ? payload.list
                : [...state.bishkek_trainers.trainers, ...payload.list],
            amount: payload.amount,
          },
        };
      }
    );
    builder.addCase(getTrainersBishkek.pending, (state) => {
      return {
        ...state,
        bishkek_trainers: {
          ...state.bishkek_trainers,
          loading: true,
        },
      };
    });
    builder.addCase(getTrainersBishkek.rejected, (state) => {
      return {
        ...state,
        bishkek_trainers: {
          trainers: [],
          loading: false,
          amount: 0,
        },
      };
    });
    builder.addCase(
      getTrainersJalalAbad.fulfilled,
      (state, { payload, meta }) => {
        const { skip } = meta.arg;
        return {
          ...state,
          jalal_abad_trainers: {
            loading: false,
            trainers:
              (skip || 0) === 0
                ? payload.list
                : [...state.jalal_abad_trainers.trainers, ...payload.list],
            amount: payload.amount,
          },
        };
      }
    );
    builder.addCase(getTrainersJalalAbad.pending, (state) => {
      return {
        ...state,
        jalal_abad_trainers: {
          ...state.jalal_abad_trainers,
          loading: true,
        },
      };
    });
    builder.addCase(getTrainersJalalAbad.rejected, (state) => {
      return {
        ...state,
        jalal_abad_trainers: {
          trainers: [],
          loading: false,
          amount: 0,
        },
      };
    });
    builder.addCase(getTrainer.pending, (state) => {
      return {
        ...state,
        trainer: {
          ...state.trainer,
          loading: true,
        },
      };
    });
    builder.addCase(getTrainer.fulfilled, (state, { payload }) => {
      return {
        ...state,
        trainer: {
          loading: false,
          item: payload,
        },
      };
    });
    builder.addCase(getTrainer.rejected, (state) => {
      return {
        ...state,
        trainer: {
          item: null,
          loading: false,
        },
      };
    });
    builder.addCase(getVacanciesBish.fulfilled, (state, { payload, meta }) => {
      const { skip } = meta.arg;
      return {
        ...state,
        vacancieBish: {
          loading: false,
          amount: payload.amount,
          vacancies:
            (skip || 0) === 0
              ? payload.list
              : [...state.vacancieBish.vacancies, ...payload.list],
        },
      };
    });
    builder.addCase(getVacanciesBish.pending, (state) => {
      return {
        ...state,
        vacancieBish: {
          ...state.vacancieBish,
          loading: true,
        },
      };
    });
    builder.addCase(getVacanciesBish.rejected, (state) => {
      return {
        ...state,
        vacancieBish: {
          ...state.vacancieBish,
          loading: false,
          vacancies: [],
        },
      };
    });
    builder.addCase(getVacanciesJal.fulfilled, (state, { payload, meta }) => {
      const { skip } = meta.arg;
      return {
        ...state,
        vacancieJal: {
          loading: false,
          amount: payload.amount,
          vacancies:
            (skip || 0) === 0
              ? payload.list
              : [...state.vacancieJal.vacancies, ...payload.list],
        },
      };
    });
    builder.addCase(getVacanciesJal.pending, (state) => {
      return {
        ...state,
        vacancieJal: {
          ...state.vacancieJal,
          loading: true,
        },
      };
    });
    builder.addCase(getVacanciesJal.rejected, (state) => {
      return {
        ...state,
        vacancieJal: {
          loading: false,
          amount: 0,
          vacancies: [],
        },
      };
    });
    builder.addCase(getSocialNetworks.fulfilled, (state, { payload, meta }) => {
      const { type } = meta.arg;
      const field = type === "news" ? "news" : "common";

      return {
        ...state,
        networks: {
          ...state.networks,
          [field]: payload,
        },
      };
    });
    builder.addCase(getSocialNetworks.rejected, (state) => {
      return {
        ...state,
        networks: {
          common: [],
          news: [],
        },
      };
    });
    builder.addCase(getServices.pending, (state) => {
      return {
        ...state,
        services: {
          ...state.services,
          loading: true,
        },
      };
    });
    builder.addCase(getServices.fulfilled, (state, { payload }) => {
      return {
        ...state,
        services: {
          loading: false,
          items: payload.services,
          hasService: payload.hasService,
        },
      };
    });
    builder.addCase(getServices.rejected, (state, { error }) => {
      return {
        ...state,
        services: {
          loading: false,
          items: [],
          amount: 0,
          hasService: false,
          error: error.message,
        },
      };
    });
    builder.addCase(getCafes.pending, (state, { meta }) => {
      const { cafe_type } = meta.arg;
      const propertyName =
        cafe_type === LocalStorageValues.JalalAbad ? "cafe_j" : "cafe";

      return {
        ...state,
        [propertyName]: {
          ...state[propertyName],
          loading: true,
        },
      };
    });
    builder.addCase(getCafes.fulfilled, (state, { meta, payload }) => {
      const { cafe_type } = meta.arg;
      const propertyName =
        cafe_type === LocalStorageValues.JalalAbad ? "cafe_j" : "cafe";

      return {
        ...state,
        [propertyName]: {
          loading: false,
          item: payload,
        },
      };
    });
    builder.addCase(getCafes.rejected, (state, { meta, error }) => {
      const { cafe_type } = meta.arg;
      const propertyName =
        cafe_type === LocalStorageValues.JalalAbad ? "cafe_j" : "cafe";

      return {
        ...state,
        [propertyName]: {
          loading: false,
          item: null,
          error: error.message,
        },
      };
    });
    builder.addCase(getMuseum.pending, (state) => {
      return {
        ...state,
        museum: {
          ...state.museum,
          loading: true,
        },
      };
    });
    builder.addCase(getMuseum.fulfilled, (state, { payload }) => {
      return {
        ...state,
        museum: {
          loading: false,
          item: payload,
        },
      };
    });
    builder.addCase(getMuseum.rejected, (state, action) => {
      return {
        ...state,
        museum: {
          loading: false,
          item: null,
          error: action.error.message,
        },
      };
    });
    builder.addCase(getCamps.pending, (state) => {
      return {
        ...state,
        camp: {
          ...state.camp,
          loading: true,
        },
      };
    });
    builder.addCase(getCamps.fulfilled, (state, { payload }) => {
      return {
        ...state,
        camp: {
          loading: false,
          item: payload,
        },
      };
    });
    builder.addCase(getCamps.rejected, (state, action) => {
      return {
        ...state,
        camp: {
          loading: false,
          item: null,
          error: action.error.message,
        },
      };
    });
    builder.addCase(getClinics.pending, (state) => {
      return {
        ...state,
        clinics: {
          ...state.clinics,
          loading: true,
        },
      };
    });
    builder.addCase(getClinics.fulfilled, (state, { payload }) => {
      return {
        ...state,
        clinics: {
          loading: false,
          item: payload,
        },
      };
    });
    builder.addCase(getClinics.rejected, (state, action) => {
      return {
        ...state,
        clinics: {
          loading: false,
          item: null,
          error: action.error.message,
        },
      };
    });
    builder.addCase(getChampionships.pending, (state) => {
      return {
        ...state,
        championship: {
          ...state.championship,
          loading: true,
        },
      };
    });
    builder.addCase(getChampionships.fulfilled, (state, { payload }) => {
      return {
        ...state,
        championship: {
          loading: false,
          item: payload,
        },
      };
    });
    builder.addCase(getChampionships.rejected, (state, action) => {
      return {
        ...state,
        championship: {
          loading: false,
          item: null,
          error: action.error.message,
        },
      };
    });
    builder.addCase(getContact.pending, (state) => {
      return {
        ...state,
        contact: {
          ...state.contact,
          loading: true,
        },
      };
    });
    builder.addCase(getContact.fulfilled, (state, { payload }) => {
      return {
        ...state,
        contact: {
          items: payload,
          loading: false,
        },
      };
    });
    builder.addCase(getContact.rejected, (state) => {
      return {
        ...state,
        contact: {
          loading: false,
          items: [],
        },
      };
    });
    builder.addCase(getInfoPage.pending, (state) => {
      return {
        ...state,
        infoPage: {
          ...state.infoPage,
          loading: true,
        },
      };
    });
    builder.addCase(getInfoPage.fulfilled, (state, { payload }) => {
      return {
        ...state,
        infoPage: {
          items: payload,
          loading: false,
        },
      };
    });
    builder.addCase(getInfoPage.rejected, (state) => {
      return {
        ...state,
        infoPage: {
          loading: false,
          items: [],
        },
      };
    });
  },
});

export const {
  setEmail,
  setPassword,
  setPersonal,
  setStatus,
  setUnreadReply,
  setFirstRender,
} = barcaSlice.actions;

export default barcaSlice.reducer;
