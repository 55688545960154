/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Modal, ModalProps } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import cn from "classnames";
import styles from "./CustomModal.module.scss";

type PropsType = ModalProps & { colorHeader?: string };

export const CustomModal: React.FC<PropsType> = (props) => {
  const { title, colorHeader, className, children, ...others } = props;

  return (
    <Modal
      className={cn(styles.modal, className)}
      closeIcon={<CloseOutlined style={{ color: colorHeader }} />}
      title={<span style={{ color: colorHeader }}>{title}</span>}
      {...others}
    >
      {children}
    </Modal>
  );
};
