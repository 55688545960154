import { TFunction } from "i18next";
import { RuleObject } from "rc-field-form/lib/interface";

export const codeValidates = /^[0-9]{3}-[0-9]{3}$/;
export const passwordValidates = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/;
const forbiddenChars = /[^A-Za-z0-9!@&()._]/;

export const validatePassword =
  (t: TFunction) =>
  (rule: RuleObject, value: string, callback: (is?: string) => void) => {
    const pattern = passwordValidates;
    if (value) {
      if (value.length < 8) {
        callback(t("validateSms.minLength"));
      } else if (forbiddenChars.test(value)) {
        callback(t("validateSms.forbiddenChars"));
      } else if (!pattern.test(value)) {
        callback(t("validateSms.invalidPassword"));
      } else {
        callback();
      }
    } else {
      callback();
    }
  };
