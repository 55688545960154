import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getVacancieById } from "@store/slices";
import { useAppSelector } from "@hooks/useAppSelector";
import { getLoadingSelector, getVacancieByIdSelector } from "@store/selectors";
import { ResponseModal } from "@features/layout/vacancies/components";
import {
  VacancyEmploymentTypes,
  VacancyExperienceTypes,
  VacancyWorkTypes,
} from "@enums/slices";
import { CloseCircleFilled } from "@ant-design/icons";
import { Loader } from "@ui-kit/loader";
import { Routes } from "@enums/routes";
import cn from "classnames";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey, LocalStorageValues } from "@enums/localStorage";
import { SEO } from "@utils/SEO";
import styles from "./VacancyDetails.module.scss";

export const VacancyDetailsLayout = () => {
  const { t } = useTranslation();
  const { id: vacancyId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const vacancies = useAppSelector(getVacancieByIdSelector);
  const loading = useAppSelector(getLoadingSelector);

  const [isModalResponse, setModalResponse] = useState(false);
  const [vacancyIds, setVacancyIds] = useState<Record<string, boolean> | any>(
    {}
  );

  useEffect(() => {
    if (vacancyId) {
      dispatch(getVacancieById({ id: vacancyId }));
    }

    let vacancyKey = {};
    const storedData = LocalStorage.getItem(LocalStorageKey.VacancyIds);

    if (storedData) {
      try {
        vacancyKey = JSON.parse(storedData);
        setVacancyIds(vacancyKey);
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, []);

  const convertDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit",
      month: "short",
      day: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(
      LocalStorage.getItem(LocalStorageKey.Lang) ?? "ru",
      options
    );
  };

  const experience: Record<VacancyExperienceTypes, string> = {
    none: t("vacancie.none"),
    "less-one": t("vacancie.lessOne"),
    one_three: t("vacancie.oneThree"),
    three_six: t("vacancie.three_six"),
  };

  const employment: Record<VacancyEmploymentTypes, string> = {
    "full-time": t("vacancie.fullTime"),
    "part-time": t("vacancie.partTile"),
  };

  const workType: Record<VacancyWorkTypes, string> = {
    remote: t("vacancie.remoteWork"),
    office: t("vacancie.officeWork"),
  };
  const onShow = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setModalResponse(true);
  };
  const onClose = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    localStorage.removeItem(LocalStorage.VacancyIds);
    setModalResponse(false);
  };

  const cityType = {
    bishkek: t("Bishkek"),
    "jalal-abad": t("JalalAbad"),
  };

  if (!vacancies) {
    return (
      <div className={styles.loading}>
        <Loader fixed />
        <CloseCircleFilled
          onClick={() =>
            navigate(
              pathname.includes(LocalStorageValues.JalalAbad)
                ? Routes.VACANCIE_JALAL_ABAD
                : Routes.VACANCIE
            )
          }
          className={styles.loadingIcon}
        />
      </div>
    );
  }

  return (
    <div className={styles.mainContainer}>
      <SEO
        titleNew={vacancies.title}
        description={vacancies.description}
        url={pathname}
      />

      {loading && (
        <div className={styles.firstBlock}>
          <Loader fixed />
        </div>
      )}
      <div className={styles.cardList}>
        <div key={vacancies.id} className={styles.card}>
          <div className={styles.cardTop}>
            <div className={styles.cardHeader}>
              <div className={styles.cardTitle}>{vacancies.title}</div>
              <div className={styles.salary}>{`${t("vacancie.to")} ${
                vacancies.max_salary
              } ${t("vacancie.curr")}`}</div>
            </div>
            <div>
              <div className={styles.date}>
                {convertDate(vacancies.createdAt)}
              </div>
            </div>
          </div>
          <div className={styles.city}>
            {t("vacancie.city")} {cityType[vacancies.vacancy_city]}
          </div>
          <div className={styles.dateMobile}>
            {convertDate(vacancies.createdAt)}
          </div>
          <div className={styles.experience}>
            {t("vacancie.requiredExp")} {experience[vacancies.experience]}
          </div>
          <div className={styles.desc}>
            {`${employment[vacancies.employment]}, ${
              workType[vacancies.work_type]
            }`}
          </div>
          {!vacancyIds[vacancies.id] ? (
            <Button type="primary" onClick={onShow} className={styles.btn}>
              {t("vacancie.btnFollow")}
            </Button>
          ) : (
            <Button
              type="primary"
              className={cn(styles.btn, {
                [styles.clicked]: vacancyIds[vacancies.id],
              })}
              disabled
            >
              {t("vacancie.btnFollowYet")}
            </Button>
          )}
        </div>
        <div className={styles.aboutWork}>
          <div className={styles.title}>{t("vacancie.aboutUs")}</div>
          <div className={styles.desc2}>{vacancies.description}</div>
          <div className={styles.title}>{t("vacancie.condition")}</div>
          <div className={styles.desc2}>{vacancies.work_conditions}</div>
          <div className={styles.title}>{t("vacancie.skill")}</div>
          <div className={styles.desc2}>{vacancies.skills}</div>
          <div className={styles.title}>{t("vacancie.work")}</div>
          <div className={styles.desc2}>{vacancies.description}</div>
        </div>
      </div>
      <ResponseModal
        open={isModalResponse}
        onClose={onClose}
        currentVacancy={vacancies && vacancies}
        vacancyIds={vacancyIds}
        setVacancyIds={setVacancyIds}
      />
    </div>
  );
};
