export enum AppRoutes {
  MAIN = "main",
  LOGIN = "login",
  REGISTER = "register",
  ABOUT_US = "about-us",
  ABOUT_US_PROJECT = "about-us/project",
  VACANCIE = "vacancies/bishkek",
  VACANCIE_JALAL_ABAD = "vacancies/jalal-abad",
  VACANCY_BISH_DETAILS = "vacancies/bishkek/:id",
  VACANCY_JAL_DETAILS = "vacancies/jalal-abad/:id",
  ABOUT_US_COACHES = "about-us/coaches/barca-academy",
  COACH_ID = "coach/:id",
  ARTICLES = "news",
  ARTICLES_COMMON = "news/common",
  ARTICLES_BISH = "news/bishkek",
  ARTICLES_JA = "news/jalal-abad",
  ARTICLE_COMMON = "news/common/:id",
  ARTICLE_BISH = "news/bishkek/:id",
  ARTICLE_JA = "news/jalal-abad/:id",
  SERVICES = "services",
  JALAL_ABAD = "jalal-abad",
  SERVICES_CAFE = "services/bishkek/cafe",
  SERVICES_CAFE_JA = "services/jalal-abad/cafe",
  ACADEMY_CAMP = "services/bishkek/academy-camp",
  ACADEMY_CAMP_J = "services/jalal-abad/camp",
  MUSEUM = "services/bishkek/museum",
  CLINIC = "clinic",
  BISHKEK = "bishkek",
  CHAMPIONSHIP = "championship",
  FAQ = "asked-questions",
  CONTACT = "contact",
  PRIVACY = "privacy-policy",
  FORGOT_PASSWORD = "forgot-password",
  CONFIRM_PASSWORD = "forget-password",
  PROFILE = "profile",
  NOT_FOUND = "*",
}

export enum Routes {
  MAIN = "/",
  LOGIN = "/login",
  REGISTER = "/register",
  SHOP = "/shop",
  ABOUT_US = "/about-us",
  VACANCIE = "/vacancies/bishkek",
  VACANCIE_JALAL_ABAD = "/vacancies/jalal-abad",
  VACANCY_BISH_DETAILS = "/vacancies/bishkek/:id",
  VACANCY_JAL_DETAILS = "/vacancies/jalal-abad/:id",
  ACADEMY_CAMP = "/services/bishkek/camp",
  ACADEMY_CAMP_J = "/services/jalal-abad/camp",
  MUSEUM = "/services/bishkek/museum",
  CLINIC = "/clinic",
  ABOUT_US_PROJECT = "/about-us/project",
  ABOUT_US_COACHES = "/about-us/coaches/barca-academy",
  ABOUT_US_PRICE_CONTRACT = "/about-us/price-contract",
  ABOUT_US_ADDITIONAL_SERVICES = "/about-us/additional-services",
  COACH_ID = "/coach/:id",
  ARTICLES = "/news",
  ARTICLES_COMMON = "/news/common",
  ARTICLES_BISH = "/news/bishkek",
  ARTICLES_JA = "/news/jalal-abad",
  ARTICLE_COMMON = "/news/common/:id",
  ARTICLE_BISH = "/news/bishkek/:id",
  ARTICLE_JA = "/news/jalal-abad/:id",
  SERVICES = "/services",
  SERVICES_CAFE = "/services/bishkek/cafe",
  SERVICES_CAFE_JA = "/services/jalal-abad/cafe",
  JALAL_ABAD = "/jalal-abad",
  BISHKEK = "/bishkek",
  CHAMPIONSHIP = "/championship",
  FAQ = "/asked-questions",
  CONTACT = "/contact",
  PRIVACY = "/privacy-policy",
  COMPETITIONS = "/competitions",
  FORGOT_PASSWORD = "/forgot-password",
  CONFIRM_PASSWORD = "/forget-password",
  PROFILE = "/profile",
  NOT_FOUND = "*",
}

export enum Regions {
  BISHKEK = "bishkek",
  JALAL_ABAD = "jalal-abad",
}
