import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import cn from "classnames";
import { VacancyDetailsLayout } from "@features/vacancyDetails";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { Links } from "@enums/links";
import { useAppSelector } from "@hooks/useAppSelector";
import {
  getVacancieBishSelector,
  getVacancieJalSelector,
} from "@store/selectors";
import { getVacanciesBish, getVacanciesJal } from "@store/slices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RoutePath } from "@router/routePath";
import { EmptyPage } from "@features/emptyPage";
import { Loader } from "@ui-kit/loader";
import { PlusOutlined } from "@ant-design/icons";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey } from "@enums/localStorage";
import { SEO } from "@utils/SEO";
import styles from "./Vacancies.module.scss";

const LIMIT = 10;

export const Vacancies = () => {
  const { t } = useTranslation();
  const { id: vacancyId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [skip, setSkip] = useState<number>(0);

  const isBishkek = location.pathname.split("/")[2] === "bishkek";

  const { vacancies, loading, amount } = useAppSelector(
    isBishkek ? getVacancieBishSelector : getVacancieJalSelector
  );

  useEffect(() => {
    if (isBishkek) {
      dispatch(getVacanciesBish({ skip, limit: LIMIT }));
    } else {
      dispatch(getVacanciesJal({ skip, limit: LIMIT }));
    }
  }, [skip, isBishkek]);

  useEffect(() => {
    setSkip(0);
  }, [isBishkek]);

  const loadVacancies = () => {
    setSkip(skip + LIMIT);
  };

  const convertDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit",
      month: "short",
      day: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(
      LocalStorage.getItem(LocalStorageKey.Lang) ?? "ru",
      options
    );
  };

  const cityType = {
    bishkek: t("Bishkek"),
    "jalal-abad": t("JalalAbad"),
  };

  return !vacancyId ? (
    <div className={styles.main}>
      <SEO
        title={
          isBishkek ? t("services.vacancieBish") : t("services.vacancieJal")
        }
        url={location.pathname}
      />

      <img
        src={Links.logo}
        alt="logo"
        className={styles.logo}
        width={240}
        loading="lazy"
      />
      {!loading && !vacancies.length ? (
        <div className={styles.empty}>
          <EmptyPage
            title={
              isBishkek ? t("services.vacancieBish") : t("services.vacancieJal")
            }
            text={t("empty")}
            layout={false}
          />
        </div>
      ) : (
        <>
          <div className={cn(styles.title)}>
            {isBishkek ? t("services.vacancieBish") : t("services.vacancieJal")}
          </div>

          <div className={styles.cardList}>
            {vacancies.map((vacancy) => (
              <div key={vacancy.id} className={styles.card}>
                <div className={styles.cardTop}>
                  <div className={styles.cardHeader}>
                    <div className={styles.cardTitle}>{vacancy.title}</div>
                    <div className={styles.salary}>{`${t("vacancie.to")} ${
                      vacancy.max_salary
                    } ${t("vacancie.curr")}`}</div>
                  </div>
                  <div className={styles.date}>
                    {convertDate(vacancy.createdAt)}
                  </div>
                </div>
                <div className={styles.city}>
                  {t("vacancie.city")} {cityType[vacancy.vacancy_city]}
                </div>
                <div className={styles.dateMobile}>
                  {convertDate(vacancy.createdAt)}
                </div>
                <div className={styles.desc}>{vacancy.description}</div>
                <Button
                  type="primary"
                  onClick={() =>
                    navigate(
                      `${
                        RoutePath[
                          `vacancies/${isBishkek ? "bishkek" : "jalal-abad"}`
                        ]
                      }/${vacancy.id}`
                    )
                  }
                  className={styles.btn}
                >
                  {t("vacancie.btnDetail")}
                </Button>
              </div>
            ))}
            {loading ? (
              <>
                <Loader />
                <br />
                <br />
              </>
            ) : (
              amount > vacancies.length && (
                <button
                  type="button"
                  className={styles.loadBtn}
                  onClick={loadVacancies}
                >
                  {t("news.btn")} <PlusOutlined className={styles.loadIcon} />
                </button>
              )
            )}
          </div>
        </>
      )}
    </div>
  ) : (
    <VacancyDetailsLayout />
  );
};
