import React, { useState } from "react";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Image } from "antd";
import { Links } from "@enums/links";
import styles from "./SwiperUI.module.scss";

type PropsTypes = SwiperProps & {
  images: string[];
  color?: "dark" | "light";
};

type FitStyles = {
  [index: number]: "cover" | "contain";
};

export const SwiperUI: React.FC<PropsTypes> = (props) => {
  const [fitStyles, setFitStyles] = useState<FitStyles>({});

  const { images, color, className, ...others } = props;
  const { t } = useTranslation();

  const isLaptop = useMediaQuery("(max-width:1024px)");

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
    index: number
  ) => {
    const img = event.target as EventTarget & HTMLImageElement;
    const objectFitStyle: "cover" | "contain" =
      img.naturalWidth > img.naturalHeight ? "cover" : "contain";

    setFitStyles((prevStyles) => ({
      ...prevStyles,
      [index]: objectFitStyle,
    }));
  };

  return (
    <Swiper
      loop={images.length > 3}
      autoplay={{
        delay: 3000,
      }}
      allowTouchMove={isLaptop}
      navigation
      centeredSlides
      mousewheel={{ forceToAxis: true }}
      spaceBetween={isLaptop ? 0 : 30}
      slidesPerView={isLaptop || images?.length === 1 ? 1 : 2}
      pagination={{
        clickable: true,
        type: "custom",
        renderCustom: (_swiper, current, total) =>
          `<span><span>${current}</span> ${t("news.of")} ${total}</span>`,
      }}
      modules={[Autoplay, Navigation, Pagination, Mousewheel]}
      className={cn(
        styles.swiper,
        {
          [styles.dark]: color === "dark",
          [styles.pb]: images.length > 1,
        },
        className
      )}
      {...others}
    >
      {images.map((img, index) => (
        <SwiperSlide key={img}>
          <Image
            rootClassName={styles.image}
            preview={false}
            src={img}
            onLoad={(e) => handleImageLoad(e, index)}
            style={{ objectFit: fitStyles[index] || "cover" }}
            fallback={Links.preview}
            loading="lazy"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
