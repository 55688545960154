import cn from "classnames";
import { CloseOutlined } from "@ant-design/icons";
import { IServiceItem } from "@enums/slices";
import styles from "./ModalArticle.module.scss";

type PropsTypes = {
  open: boolean;
  onClose: () => void;
  article: IServiceItem;
};

export const ModalArticle = (props: PropsTypes) => {
  const { open, onClose, article } = props;

  return (
    <div className={cn(styles.modal, { [styles.open]: open })}>
      <div className={cn(styles.closeContainer, { [styles.open]: open })}>
        <button type="button" onClick={onClose}>
          <CloseOutlined className={styles.closeIcon} />
        </button>
      </div>
      <div className={cn(styles.contentContainer, { [styles.open]: open })}>
        <div className={styles.photo}>
          <img src={article.banners.banner} alt="" loading="lazy" />
        </div>
        <div className={cn(styles.content, { [styles.open]: open })}>
          <h2 className={styles.title}>{article.title}</h2>
          <div
            className={styles.desc}
            dangerouslySetInnerHTML={{ __html: article.text || "" }}
          />
        </div>
      </div>
    </div>
  );
};
