import { FC } from "react";
import { Autoplay, Mousewheel, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import cn from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "antd";
import useMediaQuery from "@hooks/useMediaQuery";
import { Links } from "@enums/links";
import styles from "./Slider.module.scss";

type PropsType = {
  images: string[];
};

export const SliderService: FC<PropsType> = (props) => {
  const { images } = props;
  const isLaptop = useMediaQuery("lg");
  const shouldLoop = images.length > 3;

  return (
    <Swiper
      loop={shouldLoop}
      autoplay={{
        delay: 3000,
      }}
      grabCursor
      navigation
      speed={800}
      centeredSlides
      mousewheel={{ forceToAxis: true }}
      slidesPerView={isLaptop || images?.length === 1 ? 1 : 2}
      pagination={{
        clickable: true,
      }}
      modules={[Autoplay, Pagination, Navigation, Mousewheel]}
      className={cn(styles.swiper, {
        [styles.one]: images.length === 1,
        [styles.pb]: images.length > 1,
      })}
    >
      {images.map((img) => (
        <SwiperSlide key={img} className={styles.swiperSlide}>
          <Image
            rootClassName={cn(styles.image, {
              [styles.one]: images.length === 1,
            })}
            preview={false}
            src={img}
            fallback={Links.preview}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
