import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { getProfileSelector } from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import styles from "./DropDownMenu.module.scss";

export const DropDownMenu = ({ items }: { items: MenuProps["items"] }) => {
  const profile = useAppSelector(getProfileSelector);

  if (!profile.user.email) {
    return null;
  }

  return (
    <Dropdown
      menu={{ items }}
      placement="bottomRight"
      trigger={["click"]}
      overlayClassName={styles.dropDown}
    >
      <EllipsisOutlined style={{ color: "white" }} />
    </Dropdown>
  );
};
