import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RoutePath } from "@router/routePath";
import { Links } from "@enums/links";
import styles from "./Slider.module.scss";

export const Slider = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.slider}>
      <Link to={RoutePath.bishkek} className={styles.link}>
        <picture>
          <source srcSet={Links.bannerBishkekMob} media="(max-width: 540px)" />
          <img src={Links.bannerBishkek} alt="bannerBishkek" />
        </picture>
        <div className={styles.content}>
          <h4 className={styles.title}>{t("offices.menu.bishkek")}</h4>
        </div>
        <div className={styles.gradient} />
      </Link>
      <Link to={RoutePath["jalal-abad"]} className={styles.link}>
        <picture>
          <source
            srcSet={Links.bannerJalalAbadMob}
            media="(max-width: 540px)"
          />
          <img src={Links.bannerJalalAbad} alt="bannerJalalAbad" />
        </picture>
        <div className={styles.content}>
          <h4 className={styles.title}>{t("offices.menu.jalal_abad")}</h4>
        </div>
        <div className={styles.gradient} />
      </Link>
    </div>
  );
};
