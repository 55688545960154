/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import { Image } from "antd";
import { PlayCircleTwoTone } from "@ant-design/icons";
import { IService } from "@enums/slices";
import { EmptyPage } from "@features/emptyPage";
import { Loader } from "@ui-kit/loader";
import { Links } from "@enums/links";
import { SEO } from "@utils/SEO";
import { Article, ModalVideo, SliderService } from "./components";
import styles from "./Service.module.scss";

type PropsTypes = {
  data: {
    loading: boolean;
    item: IService | null;
  };
  title: string;
  videoTitle: string;
};

export const ServiceLayout = (props: PropsTypes) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { title, videoTitle, data } = props;
  const { item, loading } = data;
  const [videoShow, setVideoShow] = useState<boolean>(false);

  const onToggleVideo = () => {
    setVideoShow(!videoShow);
  };

  if (loading && !item) {
    return (
      <div className={styles.loading}>
        <Loader />
      </div>
    );
  }

  if (!item) {
    return (
      <>
        <SEO titleNew={t(`${title}.title`)} url={pathname} />
        <EmptyPage title={t(`${title}.title`)} text={t("soon")} />
      </>
    );
  }

  return (
    <div className={styles.container}>
      <SEO
        titleNew={item.title}
        description={t(`cafe.desc`)}
        image={item.banners?.banner}
        url={pathname}
      />

      <div className={styles.video} onClick={onToggleVideo}>
        <Image
          wrapperClassName={styles.videoImage}
          src={item.banners?.banner}
          alt=""
          preview={false}
          fallback={Links.preview}
          loading="lazy"
        />
        <div className={styles.videoContent}>
          <PlayCircleTwoTone
            className={cn(styles.playIcon, {
              [styles.notVideo]: !item.videos?.video,
            })}
          />
          {/* <div className={styles.videoDuration}>
            <PlayCircleTwoTone className={styles.durationIcon} />
            04:26
          </div> */}
          {item.videos?.video ? (
            <h2 className={cn(styles.videoTitle)}>
              {t(`${title}.${videoTitle}`)}
            </h2>
          ) : (
            <>
              <br />
              <br />
              <br />
              <br />
            </>
          )}
        </div>
      </div>
      <div className={styles.information}>
        <div className={styles.header}>
          <h1 className={cn(styles.title)}>{item.title}</h1>
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: item.text || "" }}
        />
      </div>

      {item.images?.length ? (
        <div className={styles.services}>
          <div className={styles.header}>
            <h3 className={cn(styles.title)}>{t("services.gallery")}</h3>
          </div>
          <div className={styles.servicesList}>
            <SliderService images={item.images} />
          </div>
        </div>
      ) : null}
      <div className={cn(styles.news, styles.mt)}>
        {item.service_items.map((article) => (
          <Article key={article.id} data={article} />
        ))}
      </div>

      {item.videos?.video &&
        createPortal(
          <ModalVideo
            open={videoShow}
            onClose={onToggleVideo}
            videos={item.videos}
          />,
          document.body
        )}
    </div>
  );
};
