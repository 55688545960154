/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IServiceItem } from "@enums/slices";
import cn from "classnames";
import { useInView } from "framer-motion";
import styles from "./Article.module.scss";
import { ModalArticle } from "./ModalArticle";

type PropsTypes = {
  data: IServiceItem;
};

export const Article = (props: PropsTypes) => {
  const { t } = useTranslation();
  const { data } = props;

  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const onToggleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className={styles.article}>
        <div
          ref={ref}
          className={styles.imageBlock}
          style={{
            transform: isInView ? "none" : "translateX(-20px)",
            transition: "transform 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <img src={data.banners.banner} alt="" loading="lazy" />
        </div>
        <div
          ref={ref}
          className={styles.content}
          style={{
            transform: isInView ? "none" : "translateX(20px)",
            transition: "transform 0.7s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
        >
          <h2 className={styles.title}>{data.title}</h2>
          <div className={styles.desc}>{data?.description}</div>
          <div className={cn(styles.btnBlock)} onClick={onToggleOpen}>
            <span>+ {t("services.btn")}</span>
            <span className={styles.plusIcon}> + </span>
          </div>
        </div>
      </div>
      <ModalArticle open={open} onClose={onToggleOpen} article={data} />
    </>
  );
};
