import { useAppSelector } from "@hooks/useAppSelector";
import { getCampSelector } from "@store/selectors";
import { useEffect } from "react";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getCamps } from "@store/slices";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";
import { LocalStorageValues } from "@enums/localStorage";
import { ServiceLayout } from "../serviceLayout";
import { BitrixForm } from "./BitrixForm";

export const AcademyCampLayout = () => {
  const camp = useAppSelector(getCampSelector);
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getCamps({
        camp_type: pathname.includes(LocalStorageValues.JalalAbad)
          ? "jalal-abad"
          : "bishkek",
      })
    );
  }, []);

  return (
    <>
      <ServiceLayout data={camp} title="camp" videoTitle="videoTitle" />
      {camp.item && <BitrixForm fileName="campBitrixForm" />}
    </>
  );
};
