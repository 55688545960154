import React from "react";
import { Form } from "antd";
import { ForgotPasswordType } from "@models/login";
import { Trans } from "react-i18next";
import { useAppSelector } from "@hooks/useAppSelector";
import { getLoadingSelector } from "@store/selectors";
import { FormLayout } from "../layout";
import { SubmitButton } from "../components";
import { Forms } from "./forms";
import { useForgotPassword } from "../hooks";
import styles from "./ForgotPassword.module.scss";

export const ForgotPassword: React.FC = () => {
  const { form, onFinish, onFinishFailed } = useForgotPassword();
  const loading = useAppSelector(getLoadingSelector);

  return (
    <FormLayout>
      <Form<ForgotPasswordType>
        onFinish={onFinish}
        autoComplete="off"
        onFinishFailed={onFinishFailed}
        className={styles.form}
        layout="vertical"
        form={form}
      >
        <Forms />
        <SubmitButton loading={loading} form={form}>
          <Trans i18nKey="forgotPassword.send" />
        </SubmitButton>
      </Form>
    </FormLayout>
  );
};
