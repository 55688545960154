/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, MenuProps, Space, Typography } from "antd";
import {
  CloseCircleTwoTone,
  LoadingOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  createComment,
  deleteComment,
  getCommentsById,
  getCommentsChildrenById,
  getCommentsDownChildrenById,
  getCommentsUpChildrenById,
  updateComment,
} from "@store/slices/news";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { IComment } from "@enums/slices";
import { getNewsCommentsSelector, getProfileSelector } from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import TextArea from "antd/es/input/TextArea";
import { Box } from "@mui/material";
import styles from "./style.module.scss";
import { NewsAnswer } from "./Answer";
import { DropDownMenu } from "./components";

type PropsTypes = {
  comment: IComment;
  index: number;
  newsId?: string;
};

const LIMIT = 10;

export const NewsComment: React.FC<PropsTypes> = (props) => {
  const { t } = useTranslation();
  const { comment, index, newsId } = props;
  const { id, author, text, childrenCount, answers, createdAt, isEdited } =
    comment;

  const {
    loadingUpAnswer,
    loadingDownAnswer,
    isHaveLoading,
    currentId,
    comments,
    replyCount,
    amountComments,
  } = useAppSelector(getNewsCommentsSelector);
  const {
    user: { id: userID },
  } = useAppSelector(getProfileSelector);

  const [show, setShow] = useState(!!answers?.length);
  const [showDown, setShowDown] = useState(true);
  const [skip, setSkip] = useState(-LIMIT);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);
  const [changeText, setText] = useState(text);
  const [answerText, setAnswer] = useState("");
  const [skipUpAnswer, setSkipUpAnswer] = useState(0);
  const [skipDownAnswer, setSkipDownAnswer] = useState(0);
  const [limitUpAnswer, setLimitUpAnswer] = useState(LIMIT);
  const [limitDownAnswer, setLimitDownAnswer] = useState(LIMIT);

  const observer = useRef<IntersectionObserver | null>(null);

  const dispatch = useAppDispatch();

  const lastCommentRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && comments.length < amountComments) {
          if (newsId && isHaveLoading) {
            dispatch(
              getCommentsById({
                id: newsId,
                skip: comments.length,
              })
            );
          }
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, comments.length, amountComments, id, dispatch]
  );

  const isIdMatch = currentId === id;

  const countAnswers = useMemo(() => {
    setShow(!!answers?.length);
    return childrenCount - (answers?.length || 0);
  }, [answers?.length, childrenCount]);

  const onChangeShow = () => {
    if (countAnswers <= 0) {
      setShow(!show);
    } else {
      setShow(true);
      setSkip(skip + LIMIT);
    }
  };

  const onCancelEdit = () => {
    setEdit(false);
    setText(text);
  };

  useEffect(() => {
    if (show && countAnswers > 0 && skip >= 0) {
      setLoading(true);
      dispatch(getCommentsChildrenById({ id, skip, limit: LIMIT })).finally(
        () => {
          setLoading(false);
        }
      );
    }
  }, [show, skip]);

  const onCreateComment = async () => {
    if (newsId) {
      await dispatch(
        createComment({
          newsId,
          text: answerText.trim(),
          commentId: id,
          parentCommentId: id,
        })
      );
      setIsAnswer(false);
      setAnswer("");
    }
  };

  const onReplyComment = () => {
    setIsAnswer(!isAnswer);
  };

  const onEditComment = () => {
    setEdit(!edit);
  };

  const onDeleteComment = () => {
    dispatch(deleteComment(id));
  };

  const onUpdateComment = async () => {
    if (changeText.trim() !== text.trim()) {
      await dispatch(updateComment({ commentId: id, text: changeText }));
    }
    setEdit(false);
  };

  const menu: MenuProps["items"] =
    Number(userID) === author.id
      ? [
          {
            label: (
              <span onClick={onReplyComment}>
                {t("news.commentAction.reply")}
              </span>
            ),
            key: "0",
          },
          {
            label: (
              <span onClick={onEditComment}>
                {t("news.commentAction.edit")}
              </span>
            ),
            key: "1",
          },
          {
            label: (
              <span onClick={onDeleteComment}>
                {t("news.commentAction.delete")}
              </span>
            ),
            key: "2",
          },
        ]
      : [
          {
            label: (
              <span onClick={onReplyComment}>
                {t("news.commentAction.reply")}
              </span>
            ),
            key: "0",
          },
        ];

  const getUpAnswers = () => {
    if (!showDown) setShowDown(true);

    if (id) {
      dispatch(
        getCommentsUpChildrenById({
          id,
          skip: limitUpAnswer <= LIMIT ? 0 : skipUpAnswer,
          limit: limitUpAnswer <= LIMIT ? limitUpAnswer : LIMIT,
        })
      ).then(() => {
        setLimitUpAnswer(limitUpAnswer - LIMIT);
        setSkipUpAnswer(skipUpAnswer - LIMIT);
      });
    }
  };

  const getDownAnswers = () => {
    if (limitDownAnswer <= 0) {
      setShowDown(!showDown);
      return;
    }

    if (id) {
      dispatch(
        getCommentsDownChildrenById({
          id,
          skip: skipDownAnswer,
          limit: limitDownAnswer <= LIMIT ? limitDownAnswer : LIMIT,
        })
      ).then(() => {
        setLimitDownAnswer(limitDownAnswer - LIMIT);
        setSkipDownAnswer(skipDownAnswer + LIMIT);
      });
    }
  };

  useEffect(() => {
    setSkipUpAnswer(replyCount.up - LIMIT);
    setSkipDownAnswer(replyCount.up + 1);
    setLimitUpAnswer(replyCount.up);
    setLimitDownAnswer(replyCount.down);
  }, [replyCount]);

  return (
    <Space
      className={styles.comment}
      wrap
      ref={index === comments.length - 1 ? lastCommentRef : null}
      id={id}
    >
      <Avatar
        className={styles.avatar}
        size="large"
        src={author.avatar}
        icon={<UserOutlined />}
      />
      <Space direction="vertical" className={styles.info}>
        <div className={styles.block}>
          <Typography.Title className={styles.name}>
            {author.name || "Anonim"}
          </Typography.Title>
          <DropDownMenu items={menu} />
        </div>
        <Typography.Text className={styles.email}>
          {author.email}
        </Typography.Text>
        <Typography.Paragraph className={styles.text}>
          {edit ? (
            <Box display="flex" gap={1}>
              <TextArea
                value={changeText}
                autoSize={{ maxRows: 5 }}
                className={styles.input}
                onChange={(e) => setText(e.target.value)}
                onPressEnter={onUpdateComment}
                bordered
              />
              <CloseCircleTwoTone onClick={onCancelEdit} />
              <SendOutlined onClick={onUpdateComment} />
            </Box>
          ) : (
            <div className={styles.input}>{changeText}</div>
          )}
          <div className={styles.createdAt}>{`${createdAt} ${
            isEdited ? t("news.edit") : ""
          }`}</div>
          {isAnswer && (
            <Box display="flex" alignItems="center" gap={1}>
              <TextArea
                value={answerText}
                autoSize={{ maxRows: 5 }}
                className={styles.input}
                style={{ margin: "10px 0px" }}
                placeholder={t("news.writeAnswer")}
                onChange={(e) => setAnswer(e.target.value)}
                onPressEnter={onCreateComment}
                bordered
              />
              <CloseCircleTwoTone onClick={() => setIsAnswer(false)} />
              <SendOutlined onClick={onCreateComment} />
            </Box>
          )}
        </Typography.Paragraph>

        {show && !!answers?.length && (
          <Space direction="vertical" className={styles.answers}>
            {!loadingUpAnswer && limitUpAnswer > 0 && isIdMatch && (
              <div className={styles.commentShow} onClick={getUpAnswers}>
                {`${t("news.replyCommentsPrevAnswer")} (${limitUpAnswer})`}
              </div>
            )}
            {loadingUpAnswer && (
              <div style={{ textAlign: "center" }}>
                <LoadingOutlined />
              </div>
            )}
            {showDown &&
              answers.map((answer) => (
                <NewsAnswer
                  key={answer.id}
                  answer={answer}
                  commentId={id}
                  newsId={newsId}
                  setSkip={setSkip}
                  skip={skip}
                />
              ))}
            {!loadingDownAnswer && !childrenCount && isIdMatch && (
              <div className={styles.commentShow} onClick={getDownAnswers}>
                {showDown && limitDownAnswer <= 0
                  ? `${t("news.replyCommentsHide")}`
                  : `${t("news.replyCommentsShow")} (${
                      limitDownAnswer > 0 ? limitDownAnswer : answers.length
                    })`}
              </div>
            )}
            {loadingDownAnswer && (
              <div style={{ textAlign: "center" }}>
                <LoadingOutlined />
              </div>
            )}
          </Space>
        )}

        {!!childrenCount && (
          <Typography.Text
            className={styles.answersCount}
            onClick={onChangeShow}
          >
            {show && countAnswers <= 0
              ? t("news.replyCommentsHide")
              : `${t("news.replyCommentsShow")} (${
                  countAnswers || childrenCount
                })`}
            {loading && (
              <div style={{ textAlign: "center" }}>
                <LoadingOutlined />
              </div>
            )}
          </Typography.Text>
        )}
      </Space>
    </Space>
  );
};
