import { createSelector } from "@reduxjs/toolkit";
import { BarcaState } from "@store/slices";

type WithbarcaState = {
  barca: BarcaState;
};

export const barcaStateSelector = (state: WithbarcaState): BarcaState =>
  state.barca;

export const getEmailSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.email;
  }
);

export const getPasswordSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.password;
  }
);

export const getMainPagesSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.mainPages;
  }
);

export const getVacancieJalSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.vacancieJal;
  }
);

export const getVacancieByIdSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.vacancieById;
  }
);

export const geReplyVacancySelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.replyVacancy;
  }
);

export const getVacancieBishSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.vacancieBish;
  }
);

export const getProfileSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.profile;
  }
);

export const getReplySelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.reply;
  }
);

export const getPersonalSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.personalVisible;
  }
);

export const getNetworksSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.networks;
  }
);

export const getEmailConfirmSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.emailConfirm;
  }
);

export const getProjectSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.project;
  }
);

export const getJalalAbadTrainersSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.jalal_abad_trainers;
  }
);

export const getBishkekTrainersSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.bishkek_trainers;
  }
);

export const getTrainerSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.trainer;
  }
);

export const getLoadingSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.loading;
  }
);

export const getServicesSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.services;
  }
);

export const getCafesSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.cafe;
  }
);

export const getCampSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.camp;
  }
);

export const getClinicsSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.clinics;
  }
);

export const getChampionshipSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.championship;
  }
);

export const getMuseumSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.museum;
  }
);

export const getCafeJaSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.cafe_j;
  }
);

export const getContactSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.contact;
  }
);

export const getInfoPageSelector = createSelector(
  barcaStateSelector,
  (barcaSliceState) => {
    return barcaSliceState.infoPage;
  }
);
