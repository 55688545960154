export enum Links {
  // SVG
  line = "/icons/line.svg",
  logo = "/images/logo.svg",
  logoDark = "/images/logoDark.svg",
  logoHeader = "/icons/barca.svg",
  bigLogo = "/images/bigLogo.svg",
  rightDecorators = "/images/rightDecorators.svg",
  leftDecorators = "/images/leftDecorators.svg",
  preview = "/images/preview.svg",
  previewLogo = "/images/logoPreview.png",
  nextSlider = "/images/nextSlider.svg",
  prevSlider = "/images/prevSlider.svg",
  swiperLine = "/images/swiperLine.svg",
  barcaIcon = "/icons/smallIcon.svg",
  leftDec = "/images/videoLeftDec.svg",
  rightDec = "/images/videoRightDec.svg",
  coachesBack = "/images/logoCoaches.svg",
  facebook = "/icons/facebook.svg",
  facebookFooter = "/icons/facebookFooter.svg",
  instagram = "/icons/instagram.svg",
  twitter = "/icons/twitter.svg",
  youtube = "/icons/youtube.svg",
  doc = "/icons/doc.svg",
  menu = "/icons/menu.svg",
  googleAuth = "/icons/googleAuth.svg",
  yandexAuth = "/icons/yandexAuth.svg",
  logoutAuth = "/icons/logoutAuth.svg",
  ball = "/icons/logo.svg",
  // PNG and JPG
  backgroundImage = "/images/leftBackground.png",
  backgroundImage2 = "/images/rightBackground.png",
  bannerJalalAbad = "https://s3.barcelona.kg/dev/12441214-9410.webp",
  bannerJalalAbadMob = "https://s3.barcelona.kg/static/academyJalalMob.webp",
  bannerBishkek = "https://s3.barcelona.kg/dev/12441212-8058.webp",
  bannerBishkekMob = "https://s3.barcelona.kg/static/academyBishkekMob.webp",
  mapImage = "/images/map.png",
  blueBack = "/images/blueBack.png",
  loader = "/images/loader.svg",
  footer = "https://s3.barcelona.kg/dev/13074675-2318.webp",
  footerLogo = "/images/footerLogo.svg",
  google = "/icons/google.png",
  twitterPng = "/icons/twitter.png",
  facebookLogin = "/icons/facebook.png",
  facebookAuth = "/icons/facebookAuth.png",
  write = "/icons/write.png",
  call = "/icons/call.png",
  notFound = "https://s3.barce1lona.kg/dev/12441216-8430.webp",
  // WEBP
  academy3 = "https://s3.barcelona.kg/dev/24190678-4869.webp",
}
