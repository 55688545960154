import { getChampionshipSelector } from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getChampionships } from "@store/slices";
import { useEffect } from "react";
import { ServiceLayout } from "./serviceLayout";

export const ChampionshipLayout = () => {
  const championship = useAppSelector(getChampionshipSelector);
  const dispath = useAppDispatch();

  useEffect(() => {
    dispath(getChampionships({}));
  }, []);

  return <ServiceLayout data={championship} title="championship" />;
};
