/* eslint-disable camelcase */
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { Typography } from "antd";
import { Divider } from "@ui-kit/divider";
import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "@hooks/useAppSelector";
import cn from "classnames";
import { Loader } from "@ui-kit/loader";
import { CloseOutlined } from "@ant-design/icons";
import { Regions, Routes } from "@enums/routes";
import { getTrainer, setLoadingPreload } from "@store/slices";
import {
  getBishkekTrainersSelector,
  getJalalAbadTrainersSelector,
  getNewsViewSelector,
  getTrainerSelector,
} from "@store/selectors";
import { SEO } from "@utils/SEO";
import styles from "./CoachView.module.scss";
import { LoaderTrainers, TrainersRecommended } from "./components";

const { Title } = Typography;

export const CoachViewLayout = () => {
  const { id: trainerId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { item, loading } = useAppSelector(getTrainerSelector);
  const { loadingPreload } = useAppSelector(getNewsViewSelector);
  const { id, firstname, lastname, description, photo, trainer_type } =
    item || {};

  const isBishkek = trainer_type === Regions.BISHKEK;

  const {
    trainers,
    loading: trainerLoading,
    amount,
  } = useAppSelector(
    isBishkek ? getBishkekTrainersSelector : getJalalAbadTrainersSelector
  );

  const onClose = () => {
    document.documentElement.style.overflowY = "";
    navigate(Routes.ABOUT_US_COACHES);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = document.getElementById("root")!;

    if (trainerId) {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      dispatch(getTrainer(trainerId)).then(() => {
        dispatch(setLoadingPreload(false));
      });

      root.style.display = "none";
    } else {
      root.style.display = "";
    }

    return () => {
      root.style.display = "";
      document.documentElement.style.overflowY = "";
    };
  }, [trainerId]);

  useEffect(() => {
    if (!window.location.hash && !loadingPreload) {
      window.scrollTo(0, 0);
    }
  }, [loadingPreload]);

  if (!loading && !item) {
    navigate(Routes.ABOUT_US_COACHES);
  }

  const isMac = /Macintosh/.test(navigator.userAgent);

  return (
    <div
      className={styles.block}
      style={isMac ? { position: "fixed", top: 0 } : {}}
    >
      {isMac && <div ref={scrollRef} />}
      {!loading && (
        <SEO
          titleNew={`${firstname} ${lastname || ""}`}
          url={`/coach/${id}`}
          image={photo}
        />
      )}

      <LoaderTrainers loading={loading} length={!!item} />
      {(loading || loadingPreload) && (
        <div style={{ position: "relative", zIndex: 200 }}>
          <Loader fixed />
        </div>
      )}
      <CloseOutlined
        onClick={onClose}
        className={cn(styles.cancelIcon, styles.light)}
      />
      <div className={styles.banner}>
        <div className={styles.imageContainer}>
          <img
            src={photo}
            className={styles.imageBlock}
            alt=""
            loading="lazy"
          />
          <img src={photo} className={styles.imageBack} alt="" loading="lazy" />
        </div>
      </div>
      <div className={cn(styles.wrapper, styles.light)}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Title className={cn(styles.title)}>
              {`${firstname} ${lastname}`}
            </Title>
            <div className={styles.container}>
              <div
                className={styles.desc}
                dangerouslySetInnerHTML={{ __html: description || "" }}
              />
            </div>
          </div>
        </div>
        <div className={styles.container}>
          <Divider />
        </div>
        {amount > 1 ? (
          <div className={styles.container_trainers}>
            <h3 className={cn(styles.heading)}>
              {t("news.recommended").split(" ")[0]}
              <span>
                {" "}
                {t("news.recommended").split(" ").slice(1).join(" ")}
              </span>
            </h3>
            {trainerLoading ? (
              <Loader />
            ) : (
              <div className={styles.trainers}>
                {trainers
                  .filter((trainer) => trainer.id !== id)
                  .slice(0, 8)
                  .map((trainer) => (
                    <TrainersRecommended key={trainer.id} {...trainer} />
                  ))}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
