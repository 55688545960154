import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { AuthFormLayout } from "@ui-kit/layouts";
import { Login } from "@features/auth/login";
import { SEO } from "@utils/SEO";
import styles from "./LoginLayout.module.scss";

export const LoginLayout = () => {
  const { t } = useTranslation();

  return (
    <AuthFormLayout>
      <SEO title={t("login.title")} url="/login" />

      <Typography.Title className={styles.loginTitle}>
        {t("login.title")}
      </Typography.Title>
      <Login />
    </AuthFormLayout>
  );
};
