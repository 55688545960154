import { AlertColor } from "@mui/material";
import { createUseStyles } from "react-jss";

const typeAlert = {
  error: "#d32f2f",
  info: "#0288d1",
  success: "#2e7d32",
  warning: "#ed6c02",
};

export const boxStyles = (type: AlertColor) => ({
  borderRadius: "50%",
  height: 42,
  width: 42,
  background: typeAlert[type],
  display: "flex",
  justifyContent: "center",
  alignSelf: "center",
});

type RuleNames = "alert";

const PREFIX = "AlertPopup";
export const useStyles = createUseStyles<RuleNames, unknown, any>(
  (theme: any) => ({
    alert: {
      fontSize: 14,
      "& .MuiAlert-icon": {
        color: "white !important",
        fontSize: 20,
      },
      "& .MuiSvgIcon-root": {
        marginTop: 7,
      },
      "& .MuiAlert-message": {
        color: "black",
        fontSize: 14,
        fontFamily: theme.sfPro,
      },
      "& .MuiAlertTitle-root": {
        fontWeight: 600,
        fontSize: 16,
        marginBottom: 0,
        color: "black",
        fontFamily: theme.sfPro,
      },
      "& .MuiAlert-root": {
        position: "fixed",
        top: 20,
        right: 20,
        display: "flex",
        alignItems: "center",
        zIndex: 1000000000,
        width: 420,
        borderRadius: "10px",
        backgroundColor: "white",
        color: theme.black,
        border: "1px solid #bbbbbb",

        [theme.breakpoints.down("sm")]: {
          left: 20,
          maxWidth: "100%", // Change the width for mobile devices
          width: "auto",
        },
      },
    },
  }),
  { name: PREFIX }
);
