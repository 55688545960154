import React from "react";
import { Form } from "antd";
import { CustomModal } from "@ui-kit/customModal";
import { Trans, useTranslation } from "react-i18next";
import { SubmitButton } from "@features/auth/components";
import { CodeForm } from "./forms";
import { useConfirmEmail } from "./hooks";
import { useShowCodeModal } from "../hooks";
import { TimerComponent } from "./timer";

type PropsType = {
  onClose: () => void;
};

export const ConfirmEmailModal: React.FC<PropsType> = ({ onClose }) => {
  const { t } = useTranslation();
  const { isModalShown } = useShowCodeModal();
  const { onFinish, form } = useConfirmEmail();

  return (
    <CustomModal
      centered
      open={isModalShown}
      onCancel={onClose}
      title={t("register.confirmEmailModal")}
      width={560}
      footer={false}
    >
      <Form name="codeForm" onFinish={onFinish} form={form}>
        <CodeForm />
        <SubmitButton form={form}>
          <Trans i18nKey="register.confirmCode" />
        </SubmitButton>
        <TimerComponent />
      </Form>
    </CustomModal>
  );
};
