/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Badge, Button, Layout } from "antd";
import { AnimatePresence } from "framer-motion";
import cn from "classnames";
import { AppRoutes, Routes } from "@enums/routes";
import { Links } from "@enums/links";
import {
  ABOUT_US_ITEMS,
  SERVICES_ITEMS,
  ARTICLES_ITEMS,
  BARCA_EXP_ITEMS,
  SERVICES_ITEMS_JA,
  SERVICES_ITEMS_BISH,
  VACANCY_ITEMS,
} from "@constants/header";
import { IMenuItem, getLink } from "@utils/getLink";
import { getProfile } from "@store/slices";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { LocalStorage } from "@localStorage/localStorage";
import useMediaQuery from "@hooks/useMediaQuery";
import { LocalStorageKey, LocalStorageValues } from "@enums/localStorage";
import { CircleNotifications } from "@mui/icons-material";
import { getProfileSelector, getReplySelector } from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { LoginHeader } from "./components/loginHeader";
import { LoginHeaderMobile } from "./components/loginHeaderMobile";
import { NotificationModalMobile } from "./components/loginHeaderMobile/NotificationModal";
import { HeaderMenu } from "./components/Menu";
import { BurgerMenu } from "./BurgerHeader";
import styles from "./Header.module.scss";

export const Header = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const profile = useAppSelector(getProfileSelector);
  const { unread } = useAppSelector(getReplySelector);

  const [isHeaderHidden, setHeaderHidden] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [isMenuVisible, setMenuVisible] = useState<boolean>(false);
  const [culersVisible, setCulersVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const at = LocalStorage.getItem(LocalStorageKey.AccessToken);
  const isMedium = useMediaQuery("lg");

  useEffect(() => {
    if (at) {
      dispatch(getProfile());
    }
  }, [at]);

  const generateMenuItems = (items: IMenuItem[]) => {
    return items.map((item) =>
      getLink(
        { ...item, children: item.children?.map((child) => getLink(child, t)) },
        t
      )
    );
  };

  const ABOUT_US: IMenuItem[] = generateMenuItems(ABOUT_US_ITEMS);
  const ARTICLES: IMenuItem[] = generateMenuItems(ARTICLES_ITEMS);
  const SERVICES: IMenuItem[] = generateMenuItems(SERVICES_ITEMS);
  const BARCA_EXP: IMenuItem[] = generateMenuItems(BARCA_EXP_ITEMS);
  const VACANCIES: IMenuItem[] = generateMenuItems(VACANCY_ITEMS);

  const SERVICES_SUB_MENU: IMenuItem[] = generateMenuItems(
    pathname.split("/")[2] === LocalStorageValues.JalalAbad
      ? SERVICES_ITEMS_JA
      : SERVICES_ITEMS_BISH
  );

  const SUB_MENU: Record<string, IMenuItem[]> = {
    [AppRoutes.ABOUT_US]: ABOUT_US,
    [AppRoutes.ARTICLES]: ARTICLES,
    [AppRoutes.SERVICES]: SERVICES_SUB_MENU,
    [AppRoutes.BISHKEK]: BARCA_EXP,
    [AppRoutes.JALAL_ABAD]: BARCA_EXP,
    vacancies: VACANCIES,
  };

  const subMenu = useMemo(() => SUB_MENU[pathname.split("/")[1]], [pathname]);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY === 0) {
        setHeaderHidden(false);
      } else if (currentScrollY > prevScrollY && currentScrollY > 50) {
        setHeaderHidden(true);
      } else if (currentScrollY < prevScrollY) {
        setHeaderHidden(false);
      }
      setPrevScrollY(currentScrollY);
    };

    const debouncedHandleScroll = debounce(handleScroll, 10);

    window.addEventListener("scroll", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [prevScrollY]);

  const closeMenuAndNavigate = (route: string) => {
    setMenuVisible(false);
    navigate(route);
  };

  const onToggle = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const onToggleCulers = () => {
    setCulersVisible(!culersVisible);
  };

  const onNavigateMain = () => {
    navigate("/");
    window.scrollTo(0, 0);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        className={styles.headerWrapper}
        style={{ height: isMedium ? 115 : subMenu ? 159 : 120 }}
      >
        <Layout.Header
          className={cn(styles.header, {
            [styles.hide]:
              pathname !== Routes.MAIN && isHeaderHidden && !isMedium,
          })}
        >
          {isMedium === false && (
            <>
              <div className={styles.nav_top}>
                <LoginHeader />
              </div>
              <div className={styles.nav_middle}>
                <Link
                  to={Routes.MAIN}
                  className={styles.logo}
                  onClick={onNavigateMain}
                >
                  <img src={Links.logo} alt="logo" width={130} height={44} />
                </Link>
                <div className={styles.menu}>
                  <div className={styles.menu_left}>
                    <HeaderMenu
                      items={ABOUT_US}
                      path={Routes.ABOUT_US}
                      label={t("aboutUs.title")}
                    />
                    <HeaderMenu
                      items={ARTICLES}
                      path={Routes.ARTICLES}
                      label={t("articles.title")}
                    />
                  </div>
                  <div className={styles.menu_right}>
                    <HeaderMenu
                      items={SERVICES}
                      path={Routes.SERVICES}
                      label={t("services.title")}
                      visible="visible"
                    />
                    <HeaderMenu
                      items={BARCA_EXP}
                      path={
                        pathname.includes(Routes.BISHKEK)
                          ? Routes.BISHKEK
                          : Routes.JALAL_ABAD
                      }
                      label={t("offices.title")}
                    />
                    <HeaderMenu
                      path={Routes.CLINIC}
                      label={t("clinic.title")}
                    />
                    <HeaderMenu
                      path={Routes.CHAMPIONSHIP}
                      label={t("championship.title")}
                    />
                    <HeaderMenu
                      items={VACANCIES}
                      path={
                        pathname.includes(Routes.BISHKEK)
                          ? Routes.VACANCIE
                          : Routes.VACANCIE_JALAL_ABAD
                      }
                      label={t("services.vacancie")}
                    />
                    <HeaderMenu
                      path={process.env.REACT_APP_STORE_URL || ""}
                      label={t("shop")}
                    />
                  </div>
                </div>
              </div>
              <Breadcrumbs menu={subMenu} />
            </>
          )}
          {isMedium && (
            <div>
              <div className={styles.middleBlock}>
                <div style={{ maxHeight: "60px" }}>
                  <img
                    src={Links.logo}
                    alt="logo"
                    width={122}
                    height={40}
                    className={styles.logoSmall}
                    onClick={onNavigateMain}
                  />
                </div>
                <div className={styles.smallMenu}>
                  {profile.user.email && (
                    <div className={styles.badge} onClick={onToggle}>
                      <Badge
                        size="small"
                        offset={[-1, 4]}
                        count={unread}
                        overflowCount={99}
                      >
                        <CircleNotifications
                          style={{ color: "white" }}
                          onClick={onToggle}
                        />
                      </Badge>
                    </div>
                  )}
                  <Button onClick={onToggleCulers} className={styles.culers}>
                    {t("data.profile")}
                  </Button>
                  <button
                    id="btn"
                    type="button"
                    aria-label="burger"
                    onClick={toggleMenu}
                  >
                    <div className={styles.circle}>
                      <div className={styles.icon} />
                      <div className={styles.iconMiddle} />
                      <div className={styles.icon} />
                    </div>
                  </button>
                </div>
              </div>
              <AnimatePresence mode="wait">
                {culersVisible && (
                  <LoginHeaderMobile
                    culersVisible={culersVisible}
                    onCancel={onToggleCulers}
                  />
                )}
              </AnimatePresence>
              <nav className={styles.bottomNav}>
                <div>
                  <Link
                    to={Routes.JALAL_ABAD}
                    className={
                      pathname === Routes.JALAL_ABAD ? styles.active : ""
                    }
                  >
                    {t("JalalAbad")}
                  </Link>
                </div>
                <div>
                  <Link
                    to={Routes.BISHKEK}
                    className={pathname === Routes.BISHKEK ? styles.active : ""}
                  >
                    {t("Bishkek")}
                  </Link>
                </div>
              </nav>
            </div>
          )}

          <AnimatePresence>
            {isMenuVisible && (
              <BurgerMenu
                toggleMenu={toggleMenu}
                closeMenuAndNavigate={closeMenuAndNavigate}
              />
            )}
          </AnimatePresence>
        </Layout.Header>
      </div>
      {open && <NotificationModalMobile open={open} onClose={onClose} />}
    </>
  );
};

// Utility function to debounce the scroll event handler
function debounce(fn: any, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;
  return function (...args: any[]) {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), wait);
  };
}
