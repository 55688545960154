import { Routes } from "@enums/routes";

export const servicesMenu = [
  {
    title: "offices.menu.bishkek",
  },
  {
    title: "burger.cafe",
    to: Routes.SERVICES_CAFE,
  },
  {
    title: "burger.museum",
    to: Routes.MUSEUM,
  },
  {
    title: "offices.menu.jalal_abad",
  },
  {
    title: "burger.cafe",
    to: Routes.SERVICES_CAFE_JA,
  },
  {
    title: "burger.barcaCamp",
    to: Routes.ACADEMY_CAMP_J,
  },
];

export const filialMenu = [
  {
    title: "offices.menu.bishkek",
  },
  {
    title: "burger.filial.main",
    to: Routes.BISHKEK,
  },
  {
    title: "burger.vacancies",
    to: Routes.VACANCIE,
  },
  {
    title: "offices.menu.jalal_abad",
  },
  {
    title: "burger.filial.main",
    to: Routes.JALAL_ABAD,
  },
  {
    title: "burger.vacancies",
    to: Routes.VACANCIE_JALAL_ABAD,
  },
];

export const aboutUsMenu = [
  {
    title: "burger.aboutUs.project",
    to: Routes.ABOUT_US_PROJECT,
  },
  {
    title: "burger.aboutUs.trainers",
    to: Routes.ABOUT_US_COACHES,
  },
];

export const articlesMenu = [
  {
    title: "burger.news.newsCommon",
    to: Routes.ARTICLES_COMMON,
  },
  {
    title: "burger.news.newsB",
    to: Routes.ARTICLES_BISH,
  },
  {
    title: "burger.news.newsJ",
    to: Routes.ARTICLES_JA,
  },
];
