import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { generatePath } from "react-router-dom";
import cn from "classnames";
import { BishkekTrainersBlock } from "@features/bishkek";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey, LocalStorageValues } from "@enums/localStorage";
import { useAppDispatch } from "@hooks/useAppDispatch";
import {
  getBishkekTrainersSelector,
  getNewsSelector,
  getServicesSelector,
} from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import { getNews, getServices } from "@store/slices";
import { Routes } from "@enums/routes";
import { Cards } from "@ui-kit/cards";
import { SEO } from "@utils/SEO";
import { EmptyPage } from "@features/emptyPage";
import styles from "./BishkekLayout.module.scss";
import { BitrixForm } from "../academyCamp/BitrixForm";

export const BishkekLayout = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { trainers, loading } = useAppSelector(getBishkekTrainersSelector);
  const { items, loading: serviceLoading } =
    useAppSelector(getServicesSelector);
  const { news, loading: newsLoading } = useAppSelector(getNewsSelector);

  const isEmpty = !trainers.length && !items.length && !news.length;
  const isLoaded = !loading && !serviceLoading && !newsLoading;

  const newsWithTo = news.map((item) => ({
    ...item,
    to: generatePath(Routes.ARTICLE_BISH, {
      id: item.id,
    }),
  }));

  useEffect(() => {
    dispatch(
      getNews({
        news_type: "bishkek",
        limit: 4,
      })
    );
    dispatch(
      getServices({
        service_type: "bishkek",
        limit: 4,
      })
    );
  }, []);

  useEffect(() => {
    LocalStorage.setItem(LocalStorageKey.Filial, LocalStorageValues.Bishkek);
  }, []);

  return (
    <div className={styles.main}>
      <SEO title={t("Bishkek")} url={Routes.BISHKEK} />

      <div className={styles.container}>
        {isEmpty && isLoaded ? (
          <EmptyPage title={t("offices.menu.bishkek")} text={t("empty")} />
        ) : (
          <div
            className={cn(styles.content, {
              [styles.height]:
                !(serviceLoading && newsLoading) && !(items && newsWithTo),
            })}
          >
            <div className={styles.imageBlock}>
              <img
                src="https://s3.barcelona.kg/dev/02746034-2013.webp"
                alt="barca bishkek soon"
              />
              <div className={styles.textOverlay}>{t("soon")}</div>
            </div>
            <BishkekTrainersBlock />
            <Cards
              heading="services.title"
              loading={serviceLoading}
              items={items}
            />
            <Cards
              heading="articles.menu.newsB"
              loading={newsLoading}
              items={newsWithTo}
              showDesc
              showDate
            />
          </div>
        )}
        <BitrixForm fileName="bishkekBitrixForm" loaded={isLoaded} />
      </div>
    </div>
  );
};
