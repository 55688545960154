/* eslint-disable camelcase */
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { Typography } from "antd";
import { Divider } from "@ui-kit/divider";
import { useEffect } from "react";
import {
  getCommentsById,
  getNewsView,
  resetComments,
  setLoadingPreload,
} from "@store/slices/news";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "@hooks/useAppSelector";
import {
  getNewsCommentsSelector,
  getNewsSelector,
  getNewsViewSelector,
} from "@store/selectors/news";
import cn from "classnames";
import { NewsType } from "@enums/slices";
import { Loader } from "@ui-kit/loader";
import { ClockCircleOutlined, CloseOutlined } from "@ant-design/icons";
import { Routes } from "@enums/routes";
import { getYouTubeVideoId, isYouTubeUrl } from "@utils/youtubeUrl";
import { SwiperUI } from "@ui-kit/swiperUI";
import { SEO } from "@utils/SEO";
import { NewsRecommended } from "./components/newsRecommended";
import { LoaderNews, NewsComments, SocialNetworks } from "./components";
import styles from "./NewsView.module.scss";

const { Title } = Typography;

enum Types {
  bishkek = "bishkek",
  "jalal-abad" = "jalal-abad",
  common = "common",
}

export const NewsViewLayout = () => {
  const { id: newsId } = useParams();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // const scrollRef = useRef<HTMLDivElement | null>(null);

  const { newsView, loading, loadingPreload } =
    useAppSelector(getNewsViewSelector);
  const {
    news,
    loading: newsLoading,
    totalCount,
  } = useAppSelector(getNewsSelector);
  const { comments } = useAppSelector(getNewsCommentsSelector);
  const {
    id,
    color,
    banners,
    title,
    description,
    text,
    images,
    videos,
    publishedAt,
    updatedAt,
  } = newsView || {};

  const type = pathname.split("/")[2] as NewsType;
  const isHash = window.location.hash;

  const onClose = () => {
    document.documentElement.style.overflowY = "";
    navigate(`${Routes.ARTICLES}/${Types[type] ?? ""}`);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = document.getElementById("root")!;

    if (newsId) {
      root.style.display = "none";
      // scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      dispatch(getNewsView(newsId)).then(() => {
        dispatch(setLoadingPreload(false));
      });

      if (!isHash) {
        dispatch(resetComments());
        dispatch(
          getCommentsById({
            id: newsId,
          })
        );
      }
    }

    return () => {
      root.style.display = "";
      document.documentElement.style.overflowY = "";
    };
  }, [newsId]);

  // const isMac = /Macintosh/.test(navigator.userAgent);

  if (!loading && !newsView) {
    navigate(`${Routes.ARTICLES}/${Types[type] ?? ""}`);
  }

  return (
    <div
      className={styles.block}
      // style={isMac && isHaveLoading ? { position: "fixed" } : {}}
    >
      {/* {isMac && <div ref={scrollRef} />} */}
      {!loading && (
        <SEO
          titleNew={title}
          description={description}
          image={banners?.banner}
          url={`${Routes.ARTICLES}/${Types[type]}/${id}`}
        />
      )}

      <LoaderNews loading={loading} length={!!newsView} isHash={!!isHash} />
      {(loading || loadingPreload) && (
        <div style={{ position: "relative", zIndex: 200 }}>
          <Loader fixed />
        </div>
      )}
      <CloseOutlined onClick={onClose} className={styles.cancelIcon} />
      {banners?.banner && (
        <div className={cn(styles.banner)}>
          <div className={styles.imageContainer}>
            <img
              src={banners.banner}
              className={styles.imageBlock}
              alt=""
              loading="lazy"
            />
            <img
              src={banners.banner}
              className={styles.imageBack}
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      )}
      <div
        className={cn(styles.wrapper, {
          [styles.notBanner]: !banners?.banner,
          [styles.dark]: color === "dark",
        })}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <Title className={cn(styles.title)}>{title}</Title>
            <div className={styles.summary}>{description}</div>
            <div className={styles.meta}>
              <div className={styles.published}>
                <div className={styles.author}>experience.barcelona.kg</div>
                <div className={styles.publishedDate}>{publishedAt}</div>
              </div>
              <div className={styles.data}>
                <time className={styles.time}>
                  <ClockCircleOutlined className={styles.clock} />
                  <span className={styles.timeDate}>{updatedAt}</span>
                </time>
              </div>
            </div>
          </div>
          <div className={styles.container}>
            {!loading && (
              <SocialNetworks color={color} mobile={false} title={title} />
            )}
            <div
              className={cn(styles.articleContent, {
                [styles.ru]: i18n.language !== "en",
              })}
              dangerouslySetInnerHTML={{ __html: text || "" }}
            />
          </div>
        </div>
        {!!images?.length && (
          <SwiperUI key={id} images={images} color={color} />
        )}
        {videos?.video && (
          <div>
            <p>&nbsp;</p>
            <p className={styles.mobile}>&nbsp;</p>
            <div className={styles.videoContent}>
              <div className={styles.videoBlock}>
                <div className={styles.videoPlayer}>
                  {isYouTubeUrl(videos.video) ? (
                    <iframe
                      className={cn(styles.video, styles.frame)}
                      src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                        videos.video
                      )}`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    />
                  ) : (
                    <video
                      key={id}
                      className={styles.video}
                      poster={videos.preview_path || ""}
                      controls
                    >
                      <source src={videos.video} type="video/mp4" />
                      Your browser does not support the video tag.
                      <track kind="captions" srcLang="en" label="English" />
                    </video>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={styles.container}>
          {!loading && (
            <>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <SocialNetworks color={color} title={title} />
            </>
          )}
          <Divider className={styles.dividerLight} />
        </div>
        {totalCount > 1 ? (
          <div className={styles.container_news}>
            <h3 className={cn(styles.heading)}>
              {t("news.recommended").split(" ")[0]}
              <span>
                {" "}
                {t("news.recommended").split(" ").slice(1).join(" ")}
              </span>
            </h3>
            {newsLoading ? (
              <Loader />
            ) : (
              <div className={styles.news}>
                {news
                  .filter((art) => art.id !== id)
                  .slice(0, 8)
                  .map((item) => (
                    <NewsRecommended key={item.id} {...item} />
                  ))}
              </div>
            )}
          </div>
        ) : null}
        {!newsLoading && <NewsComments id={newsId} comments={comments} />}
      </div>
    </div>
  );
};
