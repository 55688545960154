import { Divider as Divide, DividerProps } from "antd";
import cn from "classnames";
import { Links } from "@enums/links";
import styles from "./Divider.module.scss";

type DividerType = Omit<DividerProps, "children">;

export const Divider: React.FC<DividerType> = ({ rootClassName, ...props }) => {
  return (
    <Divide rootClassName={cn(styles.divider, rootClassName)} {...props}>
      <img src={Links.barcaIcon} alt="iconBarca" />
    </Divide>
  );
};
