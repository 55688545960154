/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mui/material";
import cn from "classnames";
import { IChampionshipService, IServiceItem } from "@enums/slices";
import { getYouTubeVideoId, isYouTubeUrl } from "@utils/youtubeUrl";
import { EmptyPage } from "@features/emptyPage";
import { Loader } from "@ui-kit/loader";
import { CardBlock } from "@ui-kit/card";
import { SEO } from "@utils/SEO";
import { Routes } from "@enums/routes";
import { BannerView } from "./components/bannerView";
import styles from "./Service.module.scss";

type PropsTypes = {
  data: {
    loading: boolean;
    item: IChampionshipService | null;
  };
  title: string;
};

export const ServiceLayout = (props: PropsTypes) => {
  const { t } = useTranslation();
  const { title, data } = props;
  const { item, loading } = data;

  const [serviceItem, setService] = useState<
    (IServiceItem & { images?: string[]; table?: string }) | null
  >(null);

  const ipad = useMediaQuery("(max-width:1281px)");

  useEffect(() => {
    if (ipad) {
      window.scrollTo(0, 0);
    }
  }, [serviceItem]);

  if (loading && !item) {
    return (
      <div className={styles.loading}>
        <Loader />
      </div>
    );
  }

  if (!item) {
    return (
      <>
        <SEO titleNew={`${title}.title`} url={Routes.CHAMPIONSHIP} />
        <EmptyPage title={t(`${title}.title`)} text={t("soon")} />
      </>
    );
  }

  return (
    <div className={styles.container}>
      <SEO
        titleNew={item.title}
        description={t(`cafe.desc`)}
        url={Routes.CHAMPIONSHIP}
      />

      <div className={styles.information}>
        <div className={styles.header}>
          <h1 className={cn(styles.title)}>{item.title}</h1>
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: item.text ?? "" }}
        />
      </div>
      {item.videos?.video && (
        <div className={styles.videoContent}>
          <div className={styles.videoBlock}>
            <div className={styles.videoPlayer}>
              {isYouTubeUrl(item.videos.video) ? (
                <iframe
                  className={cn(styles.video, styles.frame)}
                  src={`https://www.youtube.com/embed/${getYouTubeVideoId(
                    item.videos.video
                  )}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                />
              ) : (
                <video
                  key={item.videos.id}
                  className={styles.video}
                  poster={item.videos?.preview_path || ""}
                  controls
                >
                  <source src={item.videos.video} type="video/mp4" />
                  Your browser does not support the video tag.
                  <track kind="captions" srcLang="en" label="English" />
                </video>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.services}>
        {item.service_items.map((service) => (
          <div key={service.banners.id} className={styles.service}>
            <CardBlock
              data={{
                onClick: () => setService(service),
                image: service.banners.banner,
                title: service.title,
              }}
            />
          </div>
        ))}
      </div>
      {serviceItem && (
        <BannerView serviceItem={serviceItem} setService={setService} />
      )}
    </div>
  );
};
