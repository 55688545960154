import { ReactNode } from "react";
import { Links } from "@enums/links";
import cn from "classnames";
import styles from "./ServiseLayout.module.scss";

type PropsType = {
  children: ReactNode;
  className?: string;
};

export const ServiseLayout: React.FC<PropsType> = ({ children, className }) => {
  return (
    <div className={styles.mainWrapper}>
      <img
        src={Links.backgroundImage}
        alt=""
        className={styles.leftDecorators}
      />
      <div className={cn(styles.wrapper, className)}>
        <div className={styles.banner}>{children}</div>
      </div>
      <img
        src={Links.backgroundImage2}
        alt=""
        className={styles.rightDecorators}
        loading="lazy"
      />
    </div>
  );
};
