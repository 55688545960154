import { IComment } from "@enums/slices";

export const removeCommentById = (
  comments: IComment[],
  commentId: string
): IComment[] => {
  const currentCommentIndex = comments.findIndex(
    (comment) => comment.id === commentId
  );

  if (currentCommentIndex !== -1) {
    const updatedComments = [...comments];
    updatedComments.splice(currentCommentIndex, 1);
    return updatedComments;
  }

  if (currentCommentIndex === -1) {
    return comments.map((comment) => {
      if (comment.answers) {
        const currentAnswerIndex = comment.answers.findIndex(
          (answer) => answer.id === commentId
        );
        const updatedAnswers = [...comment.answers];
        if (currentAnswerIndex !== -1) {
          updatedAnswers.splice(currentAnswerIndex, 1);
        }

        return {
          ...comment,
          answers: updatedAnswers,
          childrenCount:
            currentAnswerIndex !== 1
              ? comment.childrenCount - 1
              : comment.childrenCount,
        };
      }

      return comment;
    });
  }
  return comments;
};
