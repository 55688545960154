/* eslint-disable camelcase */
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { Divider } from "@ui-kit/divider";
import { useEffect, useRef } from "react";
import { useAppSelector } from "@hooks/useAppSelector";
import cn from "classnames";
import { CloseOutlined } from "@ant-design/icons";
import { getChampionshipSelector } from "@store/selectors";
import { createPortal } from "react-dom";
import { IServiceItem } from "@enums/slices";
import { SwiperUI } from "@ui-kit/swiperUI";
import styles from "./BannerView.module.scss";
import { BannerItem } from "../bannerItem";

const { Title } = Typography;

type PropsTypes = {
  serviceItem: IServiceItem & { images?: string[]; table?: string };
  setService: (
    data: (IServiceItem & { images?: string[]; table?: string }) | null
  ) => void;
};

export const BannerView = ({ serviceItem, setService }: PropsTypes) => {
  const { t } = useTranslation();
  const { banners, text, title, images, table } = serviceItem;

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { item } = useAppSelector(getChampionshipSelector);

  const onClose = () => {
    document.documentElement.style.overflowY = "";
    setService(null);
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const root = document.getElementById("root")!;
    root.style.display = "none";

    return () => {
      root.style.display = "";
      document.documentElement.style.overflowY = "";
    };
  }, []);

  const isMac = /Macintosh/.test(navigator.userAgent);

  return createPortal(
    <div
      className={styles.block}
      style={isMac ? { position: "fixed", top: 0 } : {}}
    >
      <div ref={scrollRef} />
      <CloseOutlined
        onClick={onClose}
        className={cn(styles.cancelIcon, styles.light)}
      />
      <div className={styles.banner}>
        <div className={styles.imageContainer}>
          <img src={banners.banner} className={styles.imageBlock} alt="" />
          <img src={banners.banner} className={styles.imageBack} alt="" />
        </div>
      </div>
      <div className={cn(styles.wrapper, styles.light)}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Title className={cn(styles.title)}>{title}</Title>
          </div>
          <div className={styles.container}>
            <div
              className={styles.articleContent}
              dangerouslySetInnerHTML={{ __html: text || "" }}
            />
            {table && (
              <>
                <br />
                <div
                  className={styles.tableContent}
                  dangerouslySetInnerHTML={{ __html: table || "" }}
                />
              </>
            )}
          </div>
        </div>
        {images?.length && (
          <div className={styles.swiperBlock}>
            <SwiperUI images={images} />
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        )}
        <div className={styles.container}>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <Divider />
        </div>
        <div className={styles.container_trainers}>
          <h3 className={cn(styles.heading)}>
            {t("news.recommended").split(" ")[0]}
            <span> {t("news.recommended").split(" ").slice(1).join(" ")}</span>
          </h3>

          <div className={styles.trainers}>
            {item?.service_items.map((service) => (
              <BannerItem
                key={service.banners.id}
                image={service.banners.banner}
                title={service.title}
                onClick={() => {
                  setService(service);
                  scrollRef.current?.scrollIntoView({ behavior: "smooth" });
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};
