/* eslint-disable camelcase */
import { FC } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import cn from "classnames";
import { Image } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Links } from "@enums/links";
import { Routes } from "@enums/routes";
import { INewsRes } from "@enums/slices";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { setLoadingPreload } from "@store/slices";
import styles from "./NewsRecommended.module.scss";

type PropsTypes = INewsRes;

enum Types {
  bishkek = Routes.ARTICLE_BISH,
  "jalal-abad" = Routes.ARTICLE_JA,
  common = Routes.ARTICLE_COMMON,
}

export const NewsRecommended: FC<PropsTypes> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id, banners, title, description, updatedAt, newsDate, news_type } =
    props;

  const onRedirect = () => {
    navigate(generatePath(Types[news_type].toString(), { id }));
    dispatch(setLoadingPreload(true));
  };

  return (
    <div
      onClick={onRedirect}
      className={styles.newsRecommended}
      role="link"
      tabIndex={0}
      onKeyPress={onRedirect}
    >
      <Image
        rootClassName={styles.imageBlock}
        src={banners?.banner}
        alt="banner"
        preview={false}
        fallback={Links.preview}
        loading="lazy"
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={cn(styles.title)}>{title}</h2>
          <div className={styles.desc}>{description}</div>
        </div>
        <div className={styles.date}>
          {/* <div className={styles.clubMobile}>
            <div className={styles.box} />
            club
          </div> */}
          <ClockCircleOutlined className={styles.icon} />
          <span>{newsDate ?? updatedAt}</span>
        </div>
      </div>
      {/* <div className={styles.club}>
        <div className={styles.box} />
        club
      </div> */}
      <div className={styles.date}>
        <ClockCircleOutlined className={styles.icon} />
        <span>{newsDate ?? updatedAt}</span>
      </div>
    </div>
  );
};
