/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable camelcase */
import { FC } from "react";
import { Image } from "antd";
import cn from "classnames";
import { Links } from "@enums/links";
import styles from "./BannerItem.module.scss";

type PropsTypes = {
  onClick: () => void;
  image: string;
  title: string;
};

export const BannerItem: FC<PropsTypes> = (props) => {
  const { image, title, onClick } = props;

  return (
    <div onClick={onClick} className={styles.newsRecommended}>
      <Image
        rootClassName={styles.imageBlock}
        src={image}
        preview={false}
        fallback={Links.preview}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={cn(styles.title)}>{title}</h2>
          <div className={styles.desc}>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};
