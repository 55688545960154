import { useCallback } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SignInType } from "@models/login";
import { AppDispatch } from "@store/index";
import { signIn } from "@store/slices/barca/barcaSlice";
import { Form, FormInstance } from "antd";
import { useAlert } from "@hooks/useAlert";

type ReturnType = {
  form: FormInstance;
  onFinish: (values: SignInType) => void;
  onFinishFailed: (errorInfo: ValidateErrorEntity<SignInType>) => void;
};

export const useLogin = (): ReturnType => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { setAlert } = useAlert();

  const onFinish = useCallback(
    (values: SignInType) => {
      dispatch(signIn({ values, setAlert })).then((data) => {
        if (
          data.type.includes("fulfilled") &&
          window.location.pathname.includes("login")
        ) {
          navigate("/");
        }
      });
    },
    [dispatch]
  );

  const onFinishFailed = useCallback(
    (errorInfo: ValidateErrorEntity<SignInType>) => {
      console.log("Failed:", errorInfo);
    },
    []
  );

  return {
    form,
    onFinish,
    onFinishFailed,
  };
};
