import { AuthApi } from "@constants/api";
import { SliceConstants, SliceName } from "@constants/slices";
import { IComment, ICommentAnswer, NewsType } from "@enums/slices";
import { mapNewsComments } from "@mappers/news";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "@utils/axios";
import { formatTimeDifference } from "@utils/formatTimeDifference";
import { NavigateFunction, generatePath } from "react-router-dom";
import { message } from "antd";
import { AxiosError } from "axios";
import { Routes } from "@enums/routes";
import { removeCommentById } from "./utils";
import { setUnreadReply } from "../barca";

enum Types {
  bishkek = "/bishkek",
  "jalal-abad" = "/jalal-abad",
  common = "/common",
}

interface INewsCommentsState {
  loading: boolean;
  loadingUp: boolean;
  loadingDown: boolean;
  loadingUpAnswer: boolean;
  loadingDownAnswer: boolean;
  isHaveLoading: boolean;
  loadingReply: boolean;
  currentId: string;
  error?: string;
  commentCount: {
    up: number;
    down: number;
  };
  replyCount: {
    up: number;
    down: number;
  };
  comments: IComment[];
  amountComments: number;
  amountAnswers: number;
}

interface IGetNewsCommentsParams {
  id: string;
  skip?: number;
  limit?: number;
}

interface INewsCommentBody {
  newsId: string;
  text: string;
  parentCommentId?: string;
  commentId?: string;
}

interface IResponseComments {
  items: IComment[];
  meta: {
    commentCount: { up: number; down: number };
    news: {
      id: string;
      type: NewsType;
    };
    replyCount: { up: number; down: number };
  };
  reply: ICommentAnswer[];
}

const initialState: INewsCommentsState = {
  loading: false,
  loadingUp: false,
  loadingDown: false,
  loadingUpAnswer: false,
  loadingDownAnswer: false,
  isHaveLoading: true,
  loadingReply: false,
  currentId: "0",
  comments: [],
  commentCount: {
    up: 0,
    down: 0,
  },
  replyCount: {
    up: 0,
    down: 0,
  },
  error: undefined,
  amountComments: 0,
  amountAnswers: 0,
};

export const getCommentsById = createAsyncThunk(
  SliceConstants.GetNewsCommentsById,
  async ({ id, ...params }: IGetNewsCommentsParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(
        generatePath(AuthApi.NewsComments, { newsId: id }),
        { params }
      );
      return { comments: mapNewsComments(data.items), amount: data.amount };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCommentsUpById = createAsyncThunk(
  SliceConstants.GetNewsCommentsUpById,
  async ({ id, ...params }: IGetNewsCommentsParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(
        generatePath(AuthApi.NewsComments, { newsId: id }),
        { params }
      );
      return { comments: mapNewsComments(data.items), amount: data.amount };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCommentsDownById = createAsyncThunk(
  SliceConstants.GetNewsCommentsDownById,
  async ({ id, ...params }: IGetNewsCommentsParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(
        generatePath(AuthApi.NewsComments, { newsId: id }),
        { params }
      );
      return { comments: mapNewsComments(data.items), amount: data.amount };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCommentsChildrenById = createAsyncThunk(
  SliceConstants.GetNewsCommentsChildrenById,
  async ({ id, ...params }: IGetNewsCommentsParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(
        generatePath(AuthApi.NewsCommentsChildren, { commentId: id }),
        { params }
      );
      return {
        answers: data.items.map((item: ICommentAnswer) => ({
          ...item,
          createdAt: formatTimeDifference(item.createdAt),
        })),
        amount: data.amount,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCommentsUpChildrenById = createAsyncThunk(
  SliceConstants.GetNewsCommentsUpChildrenById,
  async ({ id, ...params }: IGetNewsCommentsParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(
        generatePath(AuthApi.NewsCommentsChildren, { commentId: id }),
        { params }
      );
      return {
        answers: data.items.map((item: ICommentAnswer) => ({
          ...item,
          createdAt: formatTimeDifference(item.createdAt),
        })),
        amount: data.amount,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCommentsDownChildrenById = createAsyncThunk(
  SliceConstants.GetNewsCommentsDownChildrenById,
  async ({ id, ...params }: IGetNewsCommentsParams, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(
        generatePath(AuthApi.NewsCommentsChildren, { commentId: id }),
        { params }
      );
      return {
        answers: data.items.map((item: ICommentAnswer) => ({
          ...item,
          createdAt: formatTimeDifference(item.createdAt),
        })),
        amount: data.amount,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const getCommentsReplyById = createAsyncThunk(
  SliceConstants.GetNewsCommentsReplyById,
  async (
    { id, navigate }: { id: string; navigate?: NavigateFunction },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const { data } = await $api.get<IResponseComments>(
        generatePath(AuthApi.NewsCommentsReply, { commentId: id })
      );

      dispatch(setUnreadReply(id));

      // eslint-disable-next-line no-use-before-define
      dispatch(resetComments());
      if (navigate) {
        navigate(
          `${Routes.ARTICLES}${Types[data.meta.news.type]}/${
            data.meta.news.id
          }/#${id}`
        );
      }

      setTimeout(() => {
        window.history.replaceState(
          {},
          document.title,
          window.location.href.split("#")[0]
        );
      }, 1500);

      return {
        comment: { ...data.items[0], answers: data.reply },
        id: data.items[0].id,
        commentCount: {
          ...data.meta.commentCount,
        },
        replyCount: {
          ...data.meta.replyCount,
        },
        amount: data.meta.commentCount.up + data.meta.commentCount.down + 1,
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createComment = createAsyncThunk(
  SliceConstants.CreateNewsComment,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ commentId, ...body }: INewsCommentBody, { rejectWithValue }) => {
    try {
      const { data } = await $api.post(AuthApi.CreateNewsComment, body);
      return { ...data, createdAt: formatTimeDifference(data.createdAt) };
    } catch (error) {
      const axiosError = error as AxiosError<{
        message: string;
        statusCode: number;
      }>;

      if (axiosError.response?.data.statusCode === 429) {
        message.error(axiosError.response.data.message);
      }

      return rejectWithValue(error);
    }
  }
);

export const updateComment = createAsyncThunk(
  SliceConstants.UpdateNewsCommentById,
  async (
    { commentId, text }: { commentId: string; text: string },
    { rejectWithValue }
  ) => {
    try {
      await $api.patch(generatePath(AuthApi.UpdateNewsComment, { commentId }), {
        text,
      });
      return text;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteComment = createAsyncThunk(
  SliceConstants.DeleteNewsCommentById,
  async (commentId: string, { rejectWithValue }) => {
    try {
      await $api.delete(generatePath(AuthApi.DeleteNewsComment, { commentId }));
      return commentId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const newsCommentsSlice = createSlice({
  name: SliceName.NewsComments,
  initialState,
  reducers: {
    resetComments: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(createComment.pending, (state) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(createComment.fulfilled, (state, action) => {
      const { parentCommentId, commentId } = action.meta.arg;
      if (!parentCommentId) {
        return {
          ...state,
          loading: false,
          comments: [action.payload, ...state.comments],
          amountComments: state.amountComments + 1,
        };
      }
      return {
        ...state,
        loading: false,
        comments: state.comments.map((comment) =>
          comment.id === commentId
            ? {
                ...comment,
                childrenCount: (comment.childrenCount || 0) + 1,
                answers: comment.answers
                  ? [...comment.answers, action.payload]
                  : [action.payload],
              }
            : comment
        ),
        amountAnswers: state.amountAnswers + 1,
      };
    });
    builder.addCase(createComment.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message,
    }));
    builder.addCase(updateComment.fulfilled, (state, action) => {
      const { commentId } = action.meta.arg;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const updateCommentRecursive = (comments: Partial<IComment>[]): any => {
        return comments.map((comment) => {
          if (comment.id === commentId) {
            return { ...comment, text: action.payload, isEdited: true };
          }

          if (comment.answers && comment.answers.length > 0) {
            return {
              ...comment,
              answers: updateCommentRecursive(comment.answers),
            };
          }

          return comment;
        });
      };

      return {
        ...state,
        loading: false,
        comments: updateCommentRecursive(state.comments),
      };
    });
    builder.addCase(deleteComment.fulfilled, (state, action) => {
      const commentId = action.payload;

      return {
        ...state,
        loading: false,
        comments: removeCommentById(state.comments, commentId),
      };
    });
    builder.addCase(getCommentsById.pending, (state) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(getCommentsById.fulfilled, (state, { meta, payload }) => ({
      ...state,
      loading: false,
      comments:
        !meta.arg.skip || meta.arg.skip <= 0
          ? payload.comments
          : [...state.comments, ...payload.comments],
      amountComments: payload.amount,
    }));
    builder.addCase(getCommentsById.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message,
    }));
    builder.addCase(getCommentsUpById.pending, (state) => ({
      ...state,
      loadingUp: true,
    }));
    builder.addCase(getCommentsUpById.fulfilled, (state, { payload }) => ({
      ...state,
      loadingUp: false,
      comments: !state.comments.length
        ? payload.comments
        : [...payload.comments, ...state.comments],
      amountComments: payload.amount,
    }));
    builder.addCase(getCommentsUpById.rejected, (state, action) => ({
      ...state,
      loadingUp: false,
      error: action.error.message,
    }));
    builder.addCase(getCommentsDownById.pending, (state) => ({
      ...state,
      loadingDown: true,
    }));
    builder.addCase(getCommentsDownById.fulfilled, (state, { payload }) => ({
      ...state,
      loadingDown: false,
      comments: !state.comments.length
        ? payload.comments
        : [...state.comments, ...payload.comments],
      amountComments: payload.amount,
    }));
    builder.addCase(getCommentsDownById.rejected, (state, action) => ({
      ...state,
      loadingDown: false,
      error: action.error.message,
    }));
    builder.addCase(getCommentsChildrenById.fulfilled, (state, action) => {
      const { id } = action.meta.arg;

      return {
        ...state,
        loading: false,
        comments: state.comments.map((comment) =>
          comment.id === id
            ? {
                ...comment,
                answers: !comment.answers
                  ? action.payload.answers
                  : [...comment.answers, ...action.payload.answers].reduce(
                      (sum, i) => {
                        if (
                          !sum.find(
                            (answer: ICommentAnswer) => answer.id === i.id
                          )
                        ) {
                          sum.push(i);
                        }
                        return sum;
                      },
                      [] as ICommentAnswer[]
                    ),
              }
            : comment
        ),
        amountAnswers: action.payload.amount,
      };
    });
    builder.addCase(getCommentsUpChildrenById.pending, (state) => {
      return {
        ...state,
        loadingUpAnswer: true,
      };
    });
    builder.addCase(getCommentsUpChildrenById.fulfilled, (state, action) => {
      const { id } = action.meta.arg;

      return {
        ...state,
        loadingUpAnswer: false,
        comments: state.comments.map((comment) =>
          comment.id === id
            ? {
                ...comment,
                answers: !comment.answers
                  ? action.payload.answers
                  : [...action.payload.answers, ...comment.answers],
              }
            : comment
        ),
        amountAnswers: action.payload.amount,
      };
    });
    builder.addCase(getCommentsUpChildrenById.rejected, (state, action) => ({
      ...state,
      loadingUpAnswer: false,
      error: action.error.message,
    }));
    builder.addCase(getCommentsDownChildrenById.pending, (state) => ({
      ...state,
      loadingDownAnswer: true,
    }));
    builder.addCase(getCommentsDownChildrenById.fulfilled, (state, action) => {
      const { id } = action.meta.arg;

      return {
        ...state,
        loadingDownAnswer: false,
        comments: state.comments.map((comment) =>
          comment.id === id
            ? {
                ...comment,
                answers: !comment.answers
                  ? action.payload.answers
                  : [...comment.answers, ...action.payload.answers],
              }
            : comment
        ),
        amountAnswers: action.payload.amount,
      };
    });
    builder.addCase(getCommentsDownChildrenById.rejected, (state, action) => ({
      ...state,
      loadingDownAnswer: false,
      error: action.error.message,
    }));
    builder.addCase(getCommentsReplyById.pending, (state) => ({
      ...state,
      loadingReply: true,
    }));
    builder.addCase(getCommentsReplyById.fulfilled, (state, action) => {
      const existingCommentIndex = state.comments.findIndex(
        (comm) => comm.id === action.payload.comment.id
      );

      const updatedComments = [...state.comments];

      const formattedPayload = {
        ...action.payload.comment,
        createdAt: formatTimeDifference(action.payload.comment.createdAt),
      };

      if (formattedPayload.answers) {
        formattedPayload.answers = formattedPayload.answers.map((answer) => ({
          ...answer,
          createdAt: formatTimeDifference(answer.createdAt),
        }));
      }

      if (existingCommentIndex !== -1) {
        updatedComments.splice(existingCommentIndex, 1, formattedPayload);
      } else {
        updatedComments.unshift(formattedPayload);
      }

      return {
        ...state,
        isHaveLoading: false,
        loadingReply: false,
        currentId: action.payload.id,
        comments: updatedComments,
        commentCount: action.payload.commentCount,
        replyCount: action.payload.replyCount,
        amountAnswers: state.amountAnswers + 1,
        amountComments: action.payload.amount,
      };
    });
    builder.addCase(getCommentsReplyById.rejected, (state, action) => ({
      ...state,
      loadingReply: false,
      isHaveLoading: false,
      error: action.error.message,
    }));
    builder.addCase(getCommentsChildrenById.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message,
    }));
  },
});

export const { resetComments } = newsCommentsSlice.actions;
export default newsCommentsSlice.reducer;
