import { IExpPages, IExpPagesRes } from "@enums/slices";

export const getMainPage = (data: IExpPages): IExpPagesRes => {
  const { id, createdAt, leftBanner, centerBanner, rightBanner, videos } = data;

  const banners = [leftBanner, centerBanner, rightBanner].filter(
    (banner) => banner !== null
  ) as never;

  return {
    id,
    createdAt,
    videos,
    banners,
  };
};
