/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";
import {
  UserOutlined,
  ArrowRightOutlined,
  CloseCircleTwoTone,
  SendOutlined,
} from "@ant-design/icons";
import { ICommentAnswer } from "@enums/slices";
import { Avatar, MenuProps, Space, Typography } from "antd";
import { getProfileSelector } from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { createComment, deleteComment, updateComment } from "@store/slices";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import TextArea from "antd/es/input/TextArea";
import styles from "./style.module.scss";
import { DropDownMenu } from "./components";

type PropsTypes = {
  answer: ICommentAnswer;
  setSkip: (num: number) => void;
  skip: number;
  commentId: string;
  newsId?: string;
};

export const NewsAnswer: React.FC<PropsTypes> = (props) => {
  const { answer, newsId, commentId, setSkip, skip } = props;
  const { t } = useTranslation();
  const {
    user: { id: userID },
  } = useAppSelector(getProfileSelector);
  const [edit, setEdit] = useState(false);
  const [isAnswer, setIsAnswer] = useState(false);
  const [changeText, setText] = useState(answer.text);
  const [answerText, setAnswer] = useState("");

  const dispatch = useAppDispatch();

  const onCreateComment = async () => {
    if (newsId) {
      await dispatch(
        createComment({
          newsId,
          text: answerText.trim(),
          parentCommentId: answer.id,
          commentId,
        })
      );
      setIsAnswer(false);
      setAnswer("");
    }
  };

  const onUpdateComment = async () => {
    if (changeText.trim() !== answer.text.trim()) {
      await dispatch(updateComment({ commentId: answer.id, text: changeText }));
    }
    setEdit(false);
  };

  const onDeleteComment = async () => {
    await dispatch(deleteComment(answer.id));
    setSkip(skip - 1);
  };

  const menu: MenuProps["items"] =
    Number(userID) === answer.author.id
      ? [
          {
            label: (
              <span onClick={() => setIsAnswer(!isAnswer)}>
                {t("news.commentAction.reply")}
              </span>
            ),
            key: "0",
          },
          {
            label: (
              <span onClick={() => setEdit(!edit)}>
                {t("news.commentAction.edit")}
              </span>
            ),
            key: "1",
          },
          {
            label: (
              <span onClick={onDeleteComment}>
                {t("news.commentAction.delete")}
              </span>
            ),
            key: "2",
          },
        ]
      : [
          {
            label: (
              <span onClick={() => setIsAnswer(!isAnswer)}>
                {t("news.commentAction.reply")}
              </span>
            ),
            key: "0",
          },
        ];

  const onCancelEdit = () => {
    setEdit(false);
    setText(answer.text);
  };

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (window.location.hash) {
      timeoutId = setTimeout(() => {
        const hiddenElement = document.createElement("a");
        hiddenElement.href = window.location.hash;
        hiddenElement.click();
      }, 300);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [window.location.hash]);

  return (
    <Space className={styles.answer} id={answer.id} wrap>
      <Avatar
        className={styles.innerAvatar}
        size="large"
        src={answer.author.avatar}
        icon={<UserOutlined />}
      />
      <Space direction="vertical" className={styles.innerInfo}>
        <div className={styles.block}>
          <Typography.Title className={styles.name}>
            {answer.author.name || "Anonim"} <ArrowRightOutlined />
            <span>{answer.reply?.name || "Anonim"}</span>
          </Typography.Title>
          <DropDownMenu items={menu} />
        </div>
        <Typography.Text className={styles.email}>
          {answer.reply?.email}
        </Typography.Text>
        <Typography.Paragraph className={styles.text}>
          {edit ? (
            <Box display="flex" gap={1}>
              <TextArea
                value={changeText}
                className={styles.input}
                autoSize={{ maxRows: 5 }}
                onChange={(e) => setText(e.target.value)}
                onPressEnter={onUpdateComment}
                bordered
              />
              <CloseCircleTwoTone onClick={onCancelEdit} />
              <SendOutlined onClick={onUpdateComment} />
            </Box>
          ) : (
            <div className={styles.input}>{changeText}</div>
          )}
          <div className={styles.createdAt}>
            <div className={styles.createdAt}>{`${answer.createdAt} ${
              answer.isEdited ? t("news.edit") : ""
            }`}</div>
          </div>
          {isAnswer && (
            <Box display="flex" alignItems="center" gap={1}>
              <TextArea
                value={answerText}
                className={styles.input}
                autoSize={{ maxRows: 5 }}
                style={{ margin: "10px 0px" }}
                onPressEnter={onCreateComment}
                placeholder={t("news.writeAnswer")}
                onChange={(e) => setAnswer(e.target.value)}
                bordered
              />
              <CloseCircleTwoTone onClick={() => setIsAnswer(false)} />
              <SendOutlined onClick={onCreateComment} />
            </Box>
          )}
        </Typography.Paragraph>
      </Space>
    </Space>
  );
};
