import { FC, useEffect, useState } from "react";
import { Loader } from "@ui-kit/loader/Loader";
import styles from "./LoaderTrainers.module.scss";

const DELAY = 1500;

type LoaderType = {
  loading: boolean;
  length: number | boolean;
};

export const LoaderTrainers: FC<LoaderType> = (props) => {
  const { loading, length } = props;
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    document.documentElement.style.overflowY = "hidden";

    const hideLoader = () => {
      document.documentElement.style.overflowY = "";
      setVisible(false);
    };

    const timeoutId = setTimeout(hideLoader, DELAY);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (visible || (loading && !length)) {
    return (
      <div className={styles.loaderOverlay}>
        <div className={styles.loading}>
          <Loader fixed />
        </div>
      </div>
    );
  }

  return null;
};
