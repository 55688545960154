/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { useCallback, useEffect, useRef } from "react";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getCommentsReplyById, getReply, readAllReply } from "@store/slices";
import { getNewsCommentsSelector, getReplySelector } from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import { useNavigate } from "react-router-dom";
import { Loader } from "@ui-kit/loader";
import styles from "./NotificationModal.module.scss";

type PropsTypes = {
  open: boolean;
  onClose: () => void;
};

export const NotificationModal: React.FC<PropsTypes> = (props) => {
  const { open, onClose } = props;
  const { t } = useTranslation("translation");
  const ref = useRef<HTMLDivElement | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);

  const { items, amount, loading, unread } = useAppSelector(getReplySelector);
  const { loadingReply } = useAppSelector(getNewsCommentsSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClose, ref]);

  useEffect(() => {
    dispatch(getReply({ skip: 0 }));
  }, []);

  const lastCommentRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading || !open) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && items.length < amount) {
          dispatch(getReply({ skip: items.length }));
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading, items.length, amount, open, dispatch]
  );

  const navigateTo = async (id: string) => {
    await dispatch(getCommentsReplyById({ id, navigate }));
    onClose();
  };

  return (
    <div ref={ref} className={cn(styles.modal, { [styles.show]: open })}>
      <div className={styles.notifications}>
        <div className={styles.title}>{t("notification.title")}</div>
        <div className={styles.wrapper}>
          {items.map(({ comment, read }, index) => (
            <div
              key={comment.id}
              className={cn(styles.notifyOne, { [styles.read]: read })}
              onClick={() => navigateTo(comment.id)}
              ref={index === items.length - 1 ? lastCommentRef : null}
            >
              <Avatar
                src={comment.author.avatar}
                size={50}
                icon={<UserOutlined />}
                className={styles.avatar}
              />
              <div className={styles.content}>
                <span className={styles.name}>
                  {comment.author.name || "Noname"}
                </span>
                <span className={styles.email1}>{comment.author.email}</span>
                <div className={styles.text}>{comment.text}</div>
              </div>
            </div>
          ))}
        </div>
        {(loading || loadingReply) && <Loader fixed />}
        <div className={styles.btns}>
          <Button
            className={styles.btn}
            type="primary"
            size="large"
            onClick={() => dispatch(readAllReply())}
            disabled={!unread}
          >
            {t("notification.readAll")}
          </Button>
        </div>
      </div>
    </div>
  );
};
