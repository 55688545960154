import { useTranslation } from "react-i18next";
import { Links } from "@enums/links";
import styles from "./Greeting.module.scss";

export const Greeting = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.main}>
      <img src={Links.academy3} className={styles.image} alt="logoBarca" />
      <div className={styles.content}>
        <div className={styles.title}>Barça Academy</div>
        <div className={styles.subTitle}>{t("main.desc")}</div>
        <div className={styles.desc}>{t("main.subDesc")}</div>
        <div className={styles.gradient} />
      </div>
    </div>
  );
};
