import { ReactNode } from "react";
import { Links } from "@enums/links";
import { ImagesAlt } from "@constants/alt";
import styles from "./AuthFormLayout.module.scss";

type PropsType = {
  children: ReactNode;
};

export const AuthFormLayout: React.FC<PropsType> = ({ children }) => {
  return (
    <div className={styles.loginBlock}>
      <div className={styles.contentBlock}>
        <img
          src={Links.leftDecorators}
          alt={ImagesAlt}
          className={styles.leftDecorators}
          loading="lazy"
        />
        <div className={styles.formBlock}>
          <img
            src={Links.logo}
            alt={ImagesAlt}
            className={styles.logoBarcaIcon}
            loading="lazy"
          />
          {children}
        </div>
        <img
          src={Links.rightDecorators}
          alt={ImagesAlt}
          className={styles.rightDecorators}
          loading="lazy"
        />
      </div>
    </div>
  );
};
