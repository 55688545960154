import React from "react";
import { Input, InputProps } from "antd";
import cn from "classnames";
import styles from "./FormInput.module.scss";

type PropsTypes = InputProps;

export const FormInput: React.FC<PropsTypes> = ({
  className,
  inputMode,
  pattern,
  onKeyPress,
  ...rest
}) => {
  return (
    <Input
      {...rest}
      className={`${styles.input} ${className}`}
      inputMode={inputMode}
      pattern={pattern}
      onKeyPress={onKeyPress}
    />
  );
};

export const FormPassword: React.FC<PropsTypes> = (props) => {
  const { className, ...rest } = props;
  return (
    <Input.Password
      className={cn(styles.input, className)}
      {...rest}
      allowClear={false}
    />
  );
};
