/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, Form, Popconfirm, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { QuestionOutlined } from "@ant-design/icons";
import { useMediaQuery } from "@mui/material";
import { LocalStorageKey } from "@enums/localStorage";
import { LocalStorage } from "@localStorage/localStorage";
import { IVacancies } from "@enums/slices";
import { replyVacancy } from "@store/slices";
import { FormInput } from "@ui-kit/formInput";
import { CustomModal } from "@ui-kit/customModal";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { UploadFileUI } from "@ui-kit/uploadFileUI";
import { useAppSelector } from "@hooks/useAppSelector";
import { geReplyVacancySelector } from "@store/selectors";
import PhoneInput from "react-phone-input-2";
import styles from "./ResponseModal.module.scss";
import { useFilesUpload } from "./fileUpload";

const saveVacancyIdsToLocalStorage = (ids: Record<string, boolean>): void => {
  LocalStorage.setItem(LocalStorageKey.VacancyIds, JSON.stringify(ids));
};

type PropsType = {
  open: boolean;
  onClose: () => void;
  currentVacancy?: IVacancies | null;
  vacancyIds: Record<string, boolean> | any;
  setVacancyIds: (ids: Record<string, boolean>) => void;
};

const MAX_TEXTAREA_LENGTH = 750;

export const ResponseModal: FC<PropsType> = (props) => {
  const { open, onClose, currentVacancy, vacancyIds, setVacancyIds } = props;
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(geReplyVacancySelector);

  const {
    fileUpload: { uploadFile, loadingFile },
  } = useFilesUpload();

  const isPhone = useMediaQuery("(max-width: 637px)");

  const isLoading = loading || loadingFile;

  const addVacancyId = (vacancyId: number): void => {
    const currentIds = { ...vacancyIds };
    currentIds[vacancyId.toString()] = true;
    setVacancyIds(currentIds);
    saveVacancyIdsToLocalStorage(currentIds);
  };

  const onFinish = async (values: any) => {
    const cv = await uploadFile(values.cv);

    const updatedData = {
      ...values,
      vacancyId: currentVacancy?.id,
      cv,
      phone: values.phone ? `+${values.phone}` : null,
    };

    if (cv) {
      dispatch(replyVacancy(updatedData)).then((data) => {
        if (data.meta.requestStatus === "fulfilled") {
          notification.success({
            message: t("alert.messageVacancy"),
          });
          if (currentVacancy && !vacancyIds[currentVacancy.id]) {
            addVacancyId(currentVacancy.id);
          }
          form.resetFields();
          onClose();
        }

        if (data.meta.requestStatus === "rejected") {
          notification.error({
            message: t("alert.messageWrong"),
          });
        }
      });
    }
  };

  return (
    <CustomModal
      centered
      open={open}
      title={t("vacancie.responseModal")}
      className={styles.modal}
      onCancel={onClose}
      closable={false}
      footer={[
        <Button
          key={1}
          className={styles.btn}
          onClick={form.submit}
          loading={isLoading}
        >
          <Trans i18nKey="vacancie.btn" />
        </Button>,
        <Button
          key={2}
          danger
          className={styles.btnCancel}
          onClick={onClose}
          disabled={isLoading}
        >
          <Trans i18nKey="vacancie.btnCancel" />
        </Button>,
      ]}
    >
      <div>
        <Form
          name="codeForm"
          onFinish={onFinish}
          form={form}
          disabled={isLoading}
          className={styles.form}
        >
          <h2>{currentVacancy?.title}</h2>

          <Form.Item
            name="name"
            label={t("responseModal.fio")}
            rules={[
              {
                required: true,
                message: t("validateSms.fioReq"),
              },
            ]}
          >
            <FormInput placeholder={t("responseModal.fio")} />
          </Form.Item>
          <Form.Item
            name="phone"
            label={t("responseModal.phone")}
            rules={[
              {
                required: true,
                message: t("validateSms.phoneReq"),
              },
              {
                min: 11,
                message: t("validateSms.phoneValid"),
              },
            ]}
          >
            <PhoneInput
              country="kg"
              placeholder="+996 705 251 455"
              inputStyle={{ width: "100%", height: 44 }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={t("responseModal.email")}
            rules={[
              {
                required: true,
                message: t("validateSms.emailReq"),
              },
              {
                type: "email",
                message: t("validateSms.emailCorrect"),
              },
            ]}
          >
            <FormInput placeholder="Email" />
          </Form.Item>
          <Form.Item name="coverLetter" label={t("responseModal.letter")}>
            <div style={{ display: "flex" }}>
              <TextArea
                maxLength={MAX_TEXTAREA_LENGTH}
                rows={isPhone ? 6 : 4}
                showCount
                placeholder={t("responseModal.letterHolder")}
              />
              &nbsp;
              <Popconfirm
                title={t("responseModal.letter")}
                description={t("responseModal.help")}
                okText={t("responseModal.helpBtn")}
                okButtonProps={{
                  style: { backgroundColor: "#bb0a21", color: "white" },
                }}
                showCancel={false}
                overlayStyle={{ maxWidth: "300px" }}
              >
                <QuestionOutlined size={40} />
              </Popconfirm>
            </div>
          </Form.Item>
          <Form.Item
            name="cv"
            rules={[
              {
                required: true,
                message: t("responseModal.cvText"),
              },
            ]}
            style={{ marginBottom: 10 }}
          >
            <UploadFileUI form={form} name="cv" />
          </Form.Item>
        </Form>
      </div>
    </CustomModal>
  );
};
