import { useEffect } from "react";
import { generatePath } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey, LocalStorageValues } from "@enums/localStorage";
import {
  getJalalAbadTrainersSelector,
  getNewsSelector,
  getServicesSelector,
} from "@store/selectors";
import { JalalAbadTrainersBlock } from "@features/jalal-abad";
import { useAppSelector } from "@hooks/useAppSelector";
import { getNews, getServices } from "@store/slices";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { Routes } from "@enums/routes";
import { Cards } from "@ui-kit/cards";
import { SEO } from "@utils/SEO";
import { EmptyPage } from "@features/emptyPage";
import styles from "./JalalAbad.module.scss";
import { BitrixForm } from "../academyCamp/BitrixForm";

export const JalalAbadLayout = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { trainers, loading } = useAppSelector(getJalalAbadTrainersSelector);
  const { items, loading: serviceLoading } =
    useAppSelector(getServicesSelector);
  const { news, loading: newsLoading } = useAppSelector(getNewsSelector);

  const isEmpty = !trainers.length && !items.length && !news.length;
  const isLoaded = !loading && !serviceLoading && !newsLoading;

  const newsWithTo = news.map((item) => ({
    ...item,
    to: generatePath(Routes.ARTICLE_JA, {
      id: item.id,
    }),
  }));

  useEffect(() => {
    dispatch(
      getNews({
        news_type: "jalal-abad",
        limit: 4,
      })
    );
    dispatch(
      getServices({
        service_type: "jalal-abad",
        limit: 4,
      })
    );
  }, []);

  useEffect(() => {
    LocalStorage.setItem(LocalStorageKey.Filial, LocalStorageValues.JalalAbad);
  }, []);

  return (
    <div className={styles.main}>
      <SEO title={t("JalalAbad")} url={Routes.JALAL_ABAD} />

      <div className={styles.container}>
        {isEmpty && isLoaded ? (
          <EmptyPage title={t("offices.menu.jalal_abad")} text={t("empty")} />
        ) : (
          <div
            className={cn(styles.content, {
              [styles.height]:
                !(serviceLoading && newsLoading) && !(items && newsWithTo),
            })}
          >
            <JalalAbadTrainersBlock />
            <Cards
              heading="services.title"
              loading={serviceLoading}
              items={items}
            />
            <Cards
              heading="articles.menu.newsJ"
              loading={newsLoading}
              items={newsWithTo}
              showDesc
              showDate
            />
          </div>
        )}
        <BitrixForm fileName="jalalAbadBitrixForm" loaded={isLoaded} />
      </div>
    </div>
  );
};
