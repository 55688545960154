import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import cn from "classnames";
import { BishkekTrainersBlock } from "@features/bishkek";
import { JalalAbadTrainersBlock } from "@features/jalal-abad";
import { SEO } from "@utils/SEO";
import { Routes } from "@enums/routes";
import { useAppSelector } from "@hooks/useAppSelector";
import {
  getBishkekTrainersSelector,
  getJalalAbadTrainersSelector,
} from "@store/selectors";
import { EmptyPage } from "@features/emptyPage";
import { CoachViewLayout } from "../coachView";
import styles from "./Coaches.module.scss";

export const CoachesLayout = () => {
  const { t, i18n } = useTranslation();
  const { id: trainerId } = useParams();

  const { trainers: TrainersB, loading: LoadingB } = useAppSelector(
    getBishkekTrainersSelector
  );
  const { trainers: TrainersJ, loading: LoadingJ } = useAppSelector(
    getJalalAbadTrainersSelector
  );

  const isEmpty = !TrainersB.length && !TrainersJ.length;
  const isLoaded = !LoadingB && !LoadingJ;

  return (
    <>
      <div className={styles.main}>
        <SEO
          title={t("aboutUs.menu.coaches")}
          description={t("aboutUs.menu.coaching_staff")}
          image="https://s3.barcelona.kg/static/academyLogo2.webp"
          url={Routes.ABOUT_US_COACHES}
        />

        <div className={cn(styles.title)}>
          {t("aboutUs.menu.coaching_staff")}
        </div>

        {isEmpty && isLoaded && (
          <EmptyPage
            title={t("aboutUs.menu.coaches")}
            text={t("trainer.empty")}
          />
        )}

        <div className={styles.content}>
          <BishkekTrainersBlock />
          <JalalAbadTrainersBlock />
        </div>
      </div>
      {trainerId && createPortal(<CoachViewLayout />, document.body)}
    </>
  );
};
