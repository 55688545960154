import { ClockCircleOutlined } from "@ant-design/icons";
import { Image } from "antd";
import { Links } from "@enums/links";
import { Link } from "react-router-dom";
import { INewsRes } from "@enums/slices";
import cn from "classnames";
import { useInView } from "framer-motion";
import { useRef } from "react";
import styles from "./NewsCard.module.scss";

type PropsTypes = INewsRes;

export const NewsCard: React.FC<PropsTypes> = (props) => {
  const refPhoto = useRef(null);
  const isInView = useInView(refPhoto, { once: true });

  const {
    id,
    dayOfWeek,
    monthAndDay,
    updatedAt,
    newsDate,
    title,
    description,
    banners,
  } = props;

  return (
    <div className={styles.newsCard}>
      <div className={styles.branding__bar} />
      <div className={styles.container}>
        <div className={styles.date}>
          <div className={styles.day}>{dayOfWeek}</div>
          <div className={styles.month}>{monthAndDay}</div>
        </div>
        <div
          ref={refPhoto}
          className={cn(styles.info, { [styles.active]: isInView })}
        >
          <Link to={id} className={styles.link}>
            <Image
              src={banners?.banner}
              rootClassName={styles.imageBlock}
              preview={false}
              fallback={Links.preview}
            />
            <div className={styles.content}>
              <div className={cn(styles.title)}>{title}</div>
              <div className={styles.desc}>{description}</div>
              <div className={styles.tag}>
                {/* <div className={styles.club}>
                  <div className={styles.box} /> Club
                </div> */}
                <div className={styles.time}>
                  <ClockCircleOutlined className={styles.icon} />
                  <span>{newsDate ?? updatedAt}</span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};
