/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { ReactNode, useEffect, useState } from "react";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { getInfoPageSelector } from "@store/selectors";
import { getInfoPage } from "@store/slices";
import { Button, Collapse, Empty } from "antd";
import {
  ArrowLeftOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { IInfoPage } from "@enums/slices";
import { SEO } from "@utils/SEO";
import { Routes } from "@enums/routes";
import styles from "./FAQ.module.scss";

interface IInfoPageRes extends Omit<IInfoPage, "question_answers"> {
  question_answers: { key: number; label: string; children?: ReactNode }[];
}

export const FAQLayout = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { items } = useAppSelector(getInfoPageSelector);

  const [faqItem, setFaq] = useState<IInfoPageRes | null>(null);

  const onSetFaq = (faq: IInfoPage) => {
    const updateFaq = {
      ...faq,
      question_answers: faq.question_answers.map((item) => ({
        key: item.id,
        label: item.question,
        children: item.answer,
      })),
    };
    setFaq(updateFaq);
  };

  const onBack = () => {
    setFaq(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(getInfoPage({ type: "faq", view: "barca-experience" }));
  }, []);

  return (
    <div className={styles.faq}>
      <SEO titleNew={t("footer.help")} url={Routes.FAQ} />

      <div className={styles.header}>
        <h1 className={cn(styles.title)}>{t("footer.help")}</h1>
        <div className={styles.subTitle} />
      </div>
      <div className={styles.container}>
        {!faqItem ? (
          <div className={styles.content}>
            {items.length ? (
              items.map((faq) => (
                <div key={faq.id} className={styles.faqItem}>
                  <div onClick={() => onSetFaq(faq)} className={styles.faqLink}>
                    <div
                      style={{ backgroundImage: `url(${faq.images[0]})` }}
                      className={styles.faqImage}
                    />
                    <div className={styles.faqContent}>
                      <div className={styles.faqLabel}>{faq.title}</div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Empty description={t("empty")} style={{ margin: "0 auto" }} />
            )}
          </div>
        ) : (
          <div className={styles.collapseBlock}>
            <div className={styles.heading}>
              <Button
                className={styles.btnBack}
                type="default"
                icon={<ArrowLeftOutlined />}
                onClick={onBack}
              >
                {t("back")}
              </Button>
              <h2 className={styles.title}>{faqItem.title}</h2>
            </div>
            {faqItem.question_answers.length ? (
              <Collapse
                defaultActiveKey={1}
                bordered={false}
                expandIcon={({ isActive }) =>
                  isActive ? <MinusOutlined /> : <PlusOutlined />
                }
                expandIconPosition="right"
                accordion
                items={faqItem.question_answers}
              />
            ) : (
              <Empty description={t("empty")} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
