import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { FormInput, FormPassword } from "@ui-kit/formInput";

export const Forms: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: t("validateSms.emailReq"),
          },
          {
            type: "email",
            message: t("validateSms.emailCorrect"),
          },
        ]}
      >
        <FormInput allowClear placeholder={t("login.email")} />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t("validateSms.passwordReq"),
          },
        ]}
      >
        <FormPassword
          allowClear
          type="password"
          placeholder={t("login.password")}
        />
      </Form.Item>
    </>
  );
};
