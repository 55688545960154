import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout } from "antd";
import cn from "classnames";
import { Links } from "@enums/links";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { getNetworksSelector } from "@store/selectors";
import { getSocialNetworks } from "@store/slices";
import { Routes } from "@enums/routes";
import styles from "./Footer.module.scss";

const SocialType: Record<string, string> = {
  instagram: Links.instagram,
  twitter: Links.twitter,
  facebook: Links.facebookFooter,
  youtube: Links.youtube,
};

export const Footer = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { common } = useAppSelector(getNetworksSelector);

  useEffect(() => {
    dispatch(
      getSocialNetworks({
        type: pathname.includes("jalal-abad") ? "jalal-abad" : "bishkek",
      })
    );
  }, [pathname]);

  return (
    <>
      <Layout.Footer className={styles.footer}>
        <div className={styles.background}>
          <div className={styles.logo} />
        </div>
        <div className={styles.socialMedia}>
          {common.length ? (
            <>
              <div className={styles.text}>{t("footer.title")}</div>
              <div className={styles.socialNetworks}>
                {common.map((network) => (
                  <a
                    key={network.id}
                    href={network.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={styles[network.social_network_type]}>
                      <img
                        src={SocialType[network.social_network_type]}
                        alt=""
                        width={28}
                      />
                      <div className={styles.description}>
                        <div
                          className={cn(styles.name, {
                            [styles.ru]: i18n.language !== "en",
                          })}
                        >
                          Barça Academy
                        </div>
                        <div className={styles.url}>
                          @{network.title.split(" ").join("_").toLowerCase()}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </>
          ) : null}
          <div className={styles.footerCorporate}>
            <img src={Links.footerLogo} alt="" width={210} />
            <div className={styles.footerCorporateContainer}>
              <div>
                <span>{t("footer.copyright")}</span>
              </div>
              <div className={styles.footerCorporatelinks}>
                <Link to={Routes.FAQ}>{t("footer.help")}</Link>
                <Link to={Routes.CONTACT}>{t("footer.contact")}</Link>
                <Link to={Routes.PRIVACY}>{t("footer.policy")}</Link>
              </div>
            </div>
          </div>
        </div>
      </Layout.Footer>
      <div className={styles.breadcrumbs} />
    </>
  );
};
