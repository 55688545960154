import React from "react";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { FormPassword } from "@ui-kit/formInput";
import { validatePassword } from "@validates/codeValidates";
import { useConfirmPassword } from "@features/auth/hooks";

export const Forms: React.FC = () => {
  const { t } = useTranslation();
  const { password } = useConfirmPassword();

  const compareToFirstPassword = (_: any, value: string) => {
    if (value && value !== password) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(t("validateSms.passwordMatch"));
    }
    return Promise.resolve();
  };

  return (
    <>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: t("validateSms.passwordReq"),
          },
          {
            validator: validatePassword(t),
          },
        ]}
      >
        <FormPassword
          allowClear
          type="password"
          placeholder={t("login.password")}
        />
      </Form.Item>

      <Form.Item
        name="confirmPassword"
        rules={[
          {
            required: true,
            message: t("validateSms.passwordConfirm"),
          },
          {
            validator: compareToFirstPassword,
          },
        ]}
      >
        <FormPassword
          allowClear
          type="password"
          placeholder={t("register.confirmPassword")}
        />
      </Form.Item>
    </>
  );
};
