import { Links } from "@enums/links";
import { ServiseLayout } from "@ui-kit/serviseLayout";
import cn from "classnames";
import styles from "./EmptyPage.module.scss";

type PropsType = {
  title: string;
  text: string;
  layout?: boolean;
};

export const EmptyPage: React.FC<PropsType> = (props) => {
  const { title, text, layout = true } = props;

  if (layout) {
    return (
      <ServiseLayout className={styles.wrapper}>
        <div className={styles.emptyPage}>
          <h3 className={cn(styles.title)}>{title}</h3>
          <img src={Links.logoDark} alt="preview" />
          <span className={styles.text}>{text}</span>
        </div>
      </ServiseLayout>
    );
  }
  return (
    <div className={styles.emptyPage}>
      <h3 className={cn(styles.title)}>{title}</h3>
      <img src={Links.logoDark} alt="preview" />
      <span className={styles.text}>{text}</span>
    </div>
  );
};
