/* eslint-disable camelcase */
import { ClockCircleOutlined } from "@ant-design/icons";
import { Image } from "antd";
import { Links } from "@enums/links";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Loader } from "@ui-kit/loader";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { IBanner } from "@enums/slices";
import styles from "./Cards.module.scss";

interface ICardItem {
  id: string;
  to: string;
  banners?: IBanner;
  title: string;
  description?: string;
  updatedAt?: string;
}

type PropsTypes = {
  heading: string;
  loading: boolean;
  items: ICardItem[];
  className?: string;
  showDate?: boolean;
  showDesc?: boolean;
};

export const Cards: FC<PropsTypes> = (props) => {
  const { t } = useTranslation();
  const { loading, items, heading, className, showDate, showDesc } = props;

  if (!loading && !items.length) {
    return null;
  }

  return (
    <div className={cn(styles.container_cards, className)}>
      <h3 className={cn(styles.heading)}>{t(heading)}</h3>
      {loading ? (
        <Loader dark />
      ) : (
        <div className={styles.cards}>
          {items.map((item) => (
            <Link
              key={item.id}
              to={item.to}
              onClick={() => window.scrollTo(0, 0)}
              className={styles.card}
            >
              <Image
                rootClassName={styles.imageBlock}
                src={item?.banners?.banner}
                preview={false}
                fallback={Links.previewLogo}
              />
              <div className={styles.content}>
                <div className={styles.header}>
                  <h2 className={cn(styles.title)}>{item.title}</h2>
                  {!showDesc || !item.description ? (
                    <>
                      <br className={styles.mobile} />
                      <br className={styles.mobile} />
                    </>
                  ) : (
                    <div className={styles.desc}>{item.description}</div>
                  )}
                </div>
                {showDate && showDesc && item.updatedAt && (
                  <div className={styles.date}>
                    {/* <div className={styles.clubMobile}>
                      <div className={styles.box} />
                      club
                    </div> */}
                    <ClockCircleOutlined className={styles.icon} />
                    <span>{item.updatedAt}</span>
                  </div>
                )}
              </div>

              {showDate && showDesc && item.updatedAt && (
                <>
                  {/* <div className={styles.club}>
                    <div className={styles.box} />
                    club
                  </div> */}
                  <div className={styles.date}>
                    <ClockCircleOutlined className={styles.icon} />
                    <span>{item.updatedAt}</span>
                  </div>
                </>
              )}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};
