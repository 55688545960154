import { AuthApi } from "@constants/api";
import { SliceConstants, SliceName } from "@constants/slices";
import { INewsRes } from "@enums/slices";
import { mapNewsView } from "@mappers/news";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import $api from "@utils/axios";

interface INewsViewState {
  loading: boolean;
  loadingPreload: boolean;
  error?: string;
  newsView: INewsRes | null;
}

const initialState: INewsViewState = {
  loading: false,
  loadingPreload: false,
  newsView: null,
  error: undefined,
};

export const getNewsView = createAsyncThunk(
  SliceConstants.GetNewsView,
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(`${AuthApi.News}/${id}`);

      return {
        newsView: { ...mapNewsView(data.news) },
      };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const newsViewSlice = createSlice({
  name: SliceName.NewsView,
  initialState,
  reducers: {
    setLoadingPreload(state, action) {
      return {
        ...state,
        loadingPreload: action.payload,
      };
    },
    resetNewsView() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(getNewsView.pending, (state) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(getNewsView.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      newsView: payload.newsView,
    }));
    builder.addCase(getNewsView.rejected, (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message,
    }));
  },
});

export const { setLoadingPreload, resetNewsView } = newsViewSlice.actions;
export default newsViewSlice.reducer;
