import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import cn from "classnames";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getNews, getNewsJalalAbad } from "@store/slices";
import { useAppSelector } from "@hooks/useAppSelector";
import { getNewsSelector } from "@store/selectors";
import { Loader } from "@ui-kit/loader";
import { NewsRecommended } from "@features/layout/newsView/components/newsRecommended";
import styles from "./News.module.scss";

export const News = () => {
  const { t } = useTranslation();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });
  const dispatch = useAppDispatch();
  const { news, newsJalalAbad, loading } = useAppSelector(getNewsSelector);
  const newsAll = [...news, ...newsJalalAbad].slice(0, 4);
  const renderFirst = useAppSelector((state) => state.barca.firstRender);

  useEffect(() => {
    dispatch(
      getNews({
        news_type: "common",
        limit: 4,
      })
    );
    dispatch(getNewsJalalAbad());
  }, []);

  if (!loading && !newsAll.length) {
    return null;
  }

  return (
    <motion.div className={styles.container_news} ref={ref}>
      <h3 className={cn(styles.heading)}>{t("articles.menu.news")}</h3>
      {(renderFirst || inView) &&
        (loading ? (
          <Loader dark />
        ) : (
          <div
            className={cn(styles.news)}
            style={{ gridTemplateColumns: `repeat(${newsAll.length}, 1fr)` }}
          >
            {newsAll.map((item) => (
              <NewsRecommended key={item.id} {...item} />
            ))}
          </div>
        ))}
    </motion.div>
  );
};
