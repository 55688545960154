import { getMuseumSelector } from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getMuseum } from "@store/slices";
import { useEffect } from "react";
import { ServiceLayout } from "../serviceLayout";

export const MuseumLayout = () => {
  const museum = useAppSelector(getMuseumSelector);
  const dispath = useAppDispatch();

  useEffect(() => {
    dispath(getMuseum({}));
  }, []);

  return <ServiceLayout data={museum} title="museum" videoTitle="videoTitle" />;
};
