import { getClinicsSelector } from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getClinics } from "@store/slices";
import { useEffect } from "react";
import { ServiceLayout } from "../serviceLayout";

export const ClinicLayout = () => {
  const clinics = useAppSelector(getClinicsSelector);
  const dispath = useAppDispatch();

  useEffect(() => {
    dispath(getClinics({}));
  }, []);

  return (
    <ServiceLayout data={clinics} title="clinic" videoTitle="videoTitle" />
  );
};
