import { FC } from "react";
import cn from "classnames";
import { Links } from "@enums/links";
import styles from "./Loader.module.scss";

type LoaderType = { className?: string; fixed?: boolean; dark?: boolean };

export const Loader: FC<LoaderType> = ({ fixed, dark, className }) => {
  return (
    <div
      className={cn(styles.loaderWrapper, className, {
        [styles.loadingOverlay]: fixed,
      })}
    >
      <img
        src={Links.loader}
        alt="loader"
        className={cn(styles.loader, { [styles.dark]: dark })}
      />
    </div>
  );
};
