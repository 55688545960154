export const AuthApi = {
  Login: "/user/auth/login",
  Register: "/user/auth/register",
  MainPages: "/experience-mainpages",
  Project: "/the-project",
  Trainers: "/trainers",
  Products: "/product-categories",
  ProductsFav: "/product-categories/favorite",
  VacancyReply: "/vacancy-replies",
  Vacancies: "/vacancies",
  News: "/news",
  CreateNewsComment: "/comments",
  LoginBySocial: "/user/auth/social",
  NewsComments: "/comments/:newsId",
  NewsCommentsChildren: "/comments/getChildren/:commentId",
  NewsCommentsReply: "/comments/getReply/:commentId",
  UpdateNewsComment: "/comments/:commentId",
  DeleteNewsComment: "/comments/:commentId",
  Funders: "/funders",
  Social: "/social-networks",
  SendCode: "/user/auth/verification-code",
  CheckCode: "/user/auth/verify-email",
  Application: "/applications",
  ForgotPassword: "/user/auth/reset-password/link",
  ConfirmPassword: "/user/auth/reset-password",
  Logout: "/user/auth/logout",
  Profile: "/user/profile",
  Reply: "/user/reply",
  ReadAllReply: "/user/read-all-reply",
  Services: "/services",
  Cafe: "/cafes",
  Museum: "/museums",
  Camp: "/camps",
  Clinics: "/clinics",
  Championships: "/championships",
  Contact: "/contact/list",
  InfoPage: "/info-pages",
  LoginByGoogle: "/user/auth/google",
  LoginByFacebook: "/user/auth/facebook",
  LoginByVK: "/user/auth/vk",
  LoginByYandex: "/user/auth/yandex",
};
