import { Links } from "@enums/links";
import { Card, Image } from "antd";
import { useNavigate, generatePath } from "react-router-dom";
import cn from "classnames";
import styles from "./Card.module.scss";

const { Meta } = Card;

type PropsType = {
  data: {
    onClick?: () => void;
    to?: {
      href: string;
      id: string | number;
    };
    image: string;
    title: string;
    desc?: string;
  };
};

export const CardBlock: React.FC<PropsType> = ({ data }) => {
  const { onClick, to, title, image, desc } = data;
  const navigate = useNavigate();

  return (
    <Card
      className={cn(styles.card)}
      onClick={
        to
          ? () => navigate(generatePath(to.href, { id: to.id.toString() }))
          : onClick
      }
      cover={
        <Image
          className={styles.photo}
          preview={false}
          src={image}
          fallback={Links.preview}
        />
      }
    >
      <Meta title={title} description={desc ?? <br />} />
    </Card>
  );
};
