// Updated useButtonDisabled hook
import { Form, FormInstance } from "antd";
import { useMemo } from "react";

type ReturnType = {
  isButtonDisabled: boolean;
};

export function useButtonDisabled(form: FormInstance): ReturnType {
  const formValues = Form.useWatch([], form);

  const isButtonDisabled = useMemo(() => {
    if (formValues === undefined || formValues === null) {
      return true;
    }

    // Check if any required field (excluding coverLetter) is empty
    const isAnyFieldEmpty = Object.values(formValues).some((field) => !field);

    return isAnyFieldEmpty;
  }, [formValues]);

  return {
    isButtonDisabled,
  };
}
