import { FC, useEffect, useState } from "react";
import { Loader } from "@ui-kit/loader/Loader";
import styles from "./LoaderNews.module.scss";

const DELAY = 1500;

type LoaderType = {
  loading: boolean;
  length: number | boolean;
  isHash: boolean;
};

export const LoaderNews: FC<LoaderType> = (props) => {
  const { isHash, loading, length } = props;

  const [visible, setVisible] = useState(true);

  useEffect(() => {
    document.documentElement.style.overflowY = !isHash ? "hidden" : "";

    const hideLoader = () => {
      document.documentElement.style.overflowY = "";
      setVisible(false);
    };

    const timeoutId = setTimeout(hideLoader, DELAY);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  if (!isHash && (visible || (loading && !length))) {
    return (
      <div className={styles.loaderOverlay}>
        <div className={styles.loading}>
          <Loader fixed />
        </div>
      </div>
    );
  }

  return null;
};
