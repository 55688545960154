import { useEffect } from "react";
import { getCafeJaSelector } from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getCafes } from "@store/slices";
import { LocalStorageValues } from "@enums/localStorage";
import { ServiceLayout } from "../serviceLayout";

export const CafeJalalAbadLayout = () => {
  const cafe = useAppSelector(getCafeJaSelector);
  const dispath = useAppDispatch();

  useEffect(() => {
    dispath(getCafes({ cafe_type: LocalStorageValues.JalalAbad }));
  }, []);

  return <ServiceLayout data={cafe} title="cafe" videoTitle="videoTitle" />;
};
