import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useParams } from "react-router-dom";
import { ClockCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { NewsCard } from "@features/news";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { getNews } from "@store/slices/news";
import { useAppSelector } from "@hooks/useAppSelector";
import { getNewsSelector, getNewsViewSelector } from "@store/selectors/news";
import { EmptyPage } from "@features/emptyPage";
import { NewsType } from "@enums/slices";
import { Loader } from "@ui-kit/loader";
import cn from "classnames";
import { Links } from "@enums/links";
import { SEO } from "@utils/SEO";
import { Routes } from "@enums/routes";
import styles from "./NewsLayout.module.scss";
import { NewsViewLayout } from "../newsView";

enum Types {
  bishkek = "bishkek",
  "jalal-abad" = "jalal-abad",
  common = "common",
}

export const NewsLayout = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const { id: newsId } = useParams();
  const [skip, setSkip] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { news, loading, totalCount } = useAppSelector(getNewsSelector);
  const { loadingPreload } = useAppSelector(getNewsViewSelector);

  const type = pathname.split("/")[2] as NewsType;

  useEffect(() => {
    dispatch(
      getNews({
        news_type: Types[type] ?? "common",
        skip,
        limit: 10,
      })
    );
  }, [skip, type]);

  useEffect(() => {
    if (!window.location.hash && !loadingPreload) {
      window.scrollTo(0, 0);
    }
  }, [newsId, loadingPreload]);

  useEffect(() => {
    setSkip(0);
  }, [type]);

  const loadNews = () => {
    setSkip(skip + 10);
  };

  if (loading && !news?.length) {
    return (
      <div className={styles.loading}>
        <Loader />
      </div>
    );
  }

  if (!news?.length) {
    return <EmptyPage title={t("articles.menu.news")} text={t("news.empty")} />;
  }

  return (
    <>
      <SEO
        title={t("articles.menu.news")}
        url={`${Routes.ARTICLES}/${Types[type]}`}
      />

      <Link
        to={news[0].id}
        className={styles.banner}
        style={{
          backgroundImage: `url(${news[0].banners?.banner || Links.preview})`,
        }}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <h2 className={styles.title}>{news[0].title}</h2>
            <div className={styles.info}>
              {/* <div className={styles.club}>Club</div> */}
              <div className={styles.date}>
                <ClockCircleOutlined />
                <span> {news[0]?.newsDate ?? news[0].updatedAt}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div className={styles.container__news}>
        <div className={styles.news}>
          <h3 className={cn(styles.heading)}>{t("articles.menu.news")}</h3>
          {news.map((item) => (
            <NewsCard key={item.id} {...item} />
          ))}
          {loading ? (
            <Loader dark />
          ) : (
            totalCount > news.length && (
              <button
                type="button"
                className={styles.loadBtn}
                onClick={loadNews}
              >
                {t("news.btn")} <PlusOutlined className={styles.loadIcon} />
              </button>
            )
          )}
        </div>
      </div>
      {newsId && createPortal(<NewsViewLayout />, document.body)}
    </>
  );
};
