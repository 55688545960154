import { useState } from "react";
import { Form, Modal, Typography } from "antd";
import cn from "classnames";
import { FormLayout } from "@features/auth/layout";
import { SignInType } from "@models/login";
import { Forms } from "@features/auth/login/forms";
import { SubmitButton } from "@features/auth/components";
import { Trans, useTranslation } from "react-i18next";
import { useLogin } from "@features/auth/hooks";
import { Box } from "@mui/material";
import { RoutePath } from "@router/routePath";
import { useAppSelector } from "@hooks/useAppSelector";
import { getLoadingSelector } from "@store/selectors";
import styles from "./AuthModal.module.scss";

export const AuthModal: React.FC = () => {
  const { t } = useTranslation();
  const loading = useAppSelector(getLoadingSelector);

  const [openAuth, setOpenAuth] = useState(false);
  const { form, onFinish, onFinishFailed } = useLogin();

  const onClickModal = () => {
    setOpenAuth(!openAuth);
  };

  return (
    <div
      className={styles.wrapper}
      style={{
        color: "white",
      }}
    >
      <span
        role="button"
        tabIndex={0}
        onClick={onClickModal}
        onKeyPress={onClickModal}
        style={{ cursor: "pointer", textDecoration: "underline" }}
      >
        {t("news.commentAuth.auth")}
      </span>{" "}
      {t("news.commentAuth.text")}
      <Modal
        open={openAuth}
        onCancel={onClickModal}
        className={cn(styles.modal)}
        centered
        footer
      >
        <FormLayout>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography.Title className={styles.loginTitle}>
              {t("login.title")}
            </Typography.Title>
            <Form<SignInType>
              onFinish={onFinish}
              autoComplete="off"
              className={styles.form}
              onFinishFailed={onFinishFailed}
              layout="vertical"
              form={form}
            >
              <Forms />
              <SubmitButton loading={loading} form={form}>
                <Trans i18nKey="login.submit" />
              </SubmitButton>
              <div className={styles.btns}>
                <a href={RoutePath["forgot-password"]}>
                  <span className={styles.forgotPass}>
                    {t("login.forgotPass")}
                  </span>
                </a>
                {" | "}
                <a href={RoutePath.register}>
                  <span className={styles.register}>{t("login.register")}</span>
                </a>
              </div>
            </Form>
          </Box>
        </FormLayout>
      </Modal>
    </div>
  );
};
