import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { motion, useInView } from "framer-motion";
import { Links } from "@enums/links";
import { LinkOutlined } from "@ant-design/icons";
import { NewsColor } from "@enums/slices";
import styles from "./SocialNetworks.module.scss";

type PropsTypes = {
  title?: string;
  color?: NewsColor;
  mobile?: boolean;
};

const SocialType: Record<string, string> = {
  instagram: Links.instagram,
  twitter: Links.twitter,
  facebook: Links.facebook,
  youtube: Links.youtube,
};

export const SocialNetworks: FC<PropsTypes> = (props) => {
  const { title = "Default Title", color, mobile = true } = props;
  const { t } = useTranslation();
  const currentUrl = window.location.href;

  const social = useRef(null);
  const isInView = useInView(social, { once: true });
  const socialMob = useRef(null);
  const isInViewMob = useInView(socialMob, { once: true });

  const shareUrls = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      currentUrl
    )}&quote=${encodeURIComponent(title)}`,
    twitter: `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      currentUrl
    )}&text=${encodeURIComponent(title)}`,
    whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${title} ${currentUrl}`
    )}`,
  };

  return (
    <>
      <div className={styles.socialLinks}>
        <motion.span
          className={color === "dark" ? styles.dark : ""}
          initial={{ opacity: 0 }}
          animate={{ opacity: isInView ? 1 : 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          ref={social}
        >
          {t("news.share")}
        </motion.span>
        <div className={styles.links}>
          <motion.a
            href={shareUrls.twitter}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.twitter}
            initial={{ scale: 0 }}
            animate={{ scale: isInView ? 1 : 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            ref={social}
          >
            <img
              src={SocialType.twitter}
              alt="Twitter"
              width={16}
              loading="lazy"
            />
            Twitter
          </motion.a>
          <motion.a
            href={shareUrls.facebook}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.facebook}
            initial={{ scale: 0 }}
            animate={{ scale: isInView ? 1 : 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            ref={social}
          >
            <img
              src={SocialType.facebook}
              alt="Facebook"
              width={16}
              loading="lazy"
            />
            Facebook
          </motion.a>
          <motion.button
            type="button"
            onClick={() => navigator.clipboard.writeText(window.location.href)}
            className={styles.link}
            initial={{ scale: 0 }}
            animate={{ scale: isInView ? 1 : 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            ref={social}
          >
            <LinkOutlined style={{ fontSize: 18 }} /> Copy Link
          </motion.button>
        </div>
      </div>
      {mobile && (
        <div className={styles.socialMobile}>
          <motion.h4
            className={color === "dark" ? styles.dark : ""}
            initial={{ opacity: 0 }}
            animate={{ opacity: isInViewMob ? 1 : 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            ref={socialMob}
          >
            {t("news.share")}
          </motion.h4>
          <div className={styles.links}>
            <motion.a
              href={shareUrls.twitter}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.twitter}
              initial={{ scale: 0 }}
              animate={{ scale: isInViewMob ? 1 : 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              ref={socialMob}
            >
              <img
                src={SocialType.twitter}
                alt="Twitter"
                width={16}
                loading="lazy"
              />
              Twitter
            </motion.a>
            <motion.a
              href={shareUrls.facebook}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.facebook}
              initial={{ scale: 0 }}
              animate={{ scale: isInViewMob ? 1 : 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              ref={socialMob}
            >
              <img
                src={SocialType.facebook}
                alt="Facebook"
                width={16}
                loading="lazy"
              />
              Facebook
            </motion.a>
            <motion.button
              type="button"
              onClick={() =>
                navigator.clipboard.writeText(window.location.href)
              }
              className={styles.link}
              initial={{ scale: 0 }}
              animate={{ scale: isInViewMob ? 1 : 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
              ref={socialMob}
            >
              <LinkOutlined style={{ fontSize: 18 }} /> Copy Link
            </motion.button>
          </div>
        </div>
      )}
    </>
  );
};
