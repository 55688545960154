import { LocalStorageKey } from "@enums/localStorage";
import { IComment, INews, INewsRes } from "@enums/slices";
import { LocalStorage } from "@localStorage/localStorage";
import { formatTimeDifference } from "@utils/formatTimeDifference";

const months: Record<string, Record<number, string>> = {
  ru: {
    0: "Январь",
    1: "Февраль",
    2: "Март",
    3: "Апрель",
    4: "Май",
    5: "Июнь",
    6: "Июль",
    7: "Август",
    8: "Сентябрь",
    9: "Октябрь",
    10: "Ноябрь",
    11: "Декабрь",
  },
  en: {
    0: "January",
    1: "February",
    2: "March",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "August",
    8: "September",
    9: "October",
    10: "November",
    11: "December",
  },
  ky: {
    0: "Январь",
    1: "Февраль",
    2: "Март",
    3: "Апрель",
    4: "Май",
    5: "Июнь",
    6: "Июль",
    7: "Август",
    8: "Сентябрь",
    9: "Октябрь",
    10: "Ноябрь",
    11: "Декабрь",
  },
};

const monthsShort: Record<string, Record<number, string>> = {
  ru: {
    0: "Янв",
    1: "Фев",
    2: "Март",
    3: "Апр",
    4: "Май",
    5: "Июнь",
    6: "Июль",
    7: "Авг",
    8: "Сент",
    9: "Окт",
    10: "Нояб",
    11: "Дек",
  },
  en: {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "June",
    6: "July",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  },
  ky: {
    0: "Янв",
    1: "Фев",
    2: "Март",
    3: "Апр",
    4: "Май",
    5: "Июнь",
    6: "Июль",
    7: "Авг",
    8: "Сент",
    9: "Окт",
    10: "Нояб",
    11: "Дек",
  },
};

const days: Record<string, Record<number, string>> = {
  ru: {
    0: "Воскресенье",
    1: "Понедельник",
    2: "Вторник",
    3: "Среда",
    4: "Четверг",
    5: "Пятница",
    6: "Суббота",
  },
  en: {
    0: "Sunday",
    1: "Monday",
    2: "Tuesday",
    3: "Wednesday",
    4: "Thursday",
    5: "Friday",
    6: "Saturday",
  },
  ky: {
    0: "Жекшемби",
    1: "Дүйшөмбү",
    2: "Шейшемби",
    3: "Шаршемби",
    4: "Бейшемби",
    5: "Жума",
    6: "Ишемби",
  },
};

const time: Record<string, Record<string, string>> = {
  ru: {
    am: "",
    pm: "",
  },
  en: {
    am: "am",
    pm: "pm",
  },
  ky: {
    am: "",
    pm: "",
  },
};

const getDayOfWeek = (date: string) => {
  const lang = LocalStorage.getItem(LocalStorageKey.Lang) ?? "ru";
  const dayWeek = new Date(date).getDay();

  return days[lang][dayWeek];
};

const getMonthAndDay = (date: string) => {
  const lang = LocalStorage.getItem(LocalStorageKey.Lang) ?? "ru";
  const day = new Date(date).getDate();
  const month = new Date(date).getMonth();

  return `${months[lang][month]} ${day}`;
};

const formatTime = (date: string) => {
  const lang = LocalStorage.getItem(LocalStorageKey.Lang) ?? "ru";
  let hours = new Date(date).getHours();
  const minutes = new Date(date).getMinutes();

  const ampm = hours >= 12 ? "pm" : "am";

  if (lang === "en") {
    hours %= 12;
    hours = hours || 12; // the hour '0' should be '12'
  }

  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutesStr}${time[lang][ampm]}`;
};

const getFullDate = (date: string, clock?: boolean) => {
  const lang = LocalStorage.getItem(LocalStorageKey.Lang) ?? "ru";
  const day = new Date(date).getDate();
  const month = new Date(date).getMonth();
  const year = new Date(date).getFullYear() % 100;

  if (!clock) {
    return `${day} ${monthsShort[lang][month]} ${year}`;
  }

  return `${formatTime(date)} ${getDayOfWeek(date)} ${day} ${
    monthsShort[lang][month]
  }`;
};

export const mapNews = (news: INews[]): INewsRes[] => {
  return news.map(({ updatedAt: at, newsDate, ...items }) => {
    const dayOfWeek = getDayOfWeek(at);
    const monthAndDay = getMonthAndDay(at);
    const updatedAt = getFullDate(at);

    return {
      ...items,
      banners: items.banners
        ? {
            ...items.banners,
            createdAt: getFullDate(items.banners.createdAt),
          }
        : undefined,
      updatedAt,
      newsDate: newsDate ? getFullDate(newsDate) : undefined,
      dayOfWeek,
      monthAndDay,
      publishedAt: getFullDate(at, true),
    };
  });
};

export const mapNewsView = ({
  updatedAt: at,
  newsDate,
  ...data
}: INews): INewsRes => {
  const dayOfWeek = getDayOfWeek(at);
  const monthAndDay = getMonthAndDay(at);
  const updatedAt = getFullDate(at);

  return {
    ...data,
    banners: data.banners
      ? {
          ...data.banners,
          createdAt: getFullDate(data.banners.createdAt),
        }
      : undefined,
    newsDate: newsDate ? getFullDate(newsDate) : undefined,
    updatedAt,
    dayOfWeek,
    monthAndDay,
    publishedAt: getFullDate(at, true),
  };
};

export const mapNewsComments = (comments: IComment[]): IComment[] => {
  return comments.map((comment) => ({
    ...comment,
    createdAt: formatTimeDifference(comment.createdAt),
    updatedAt: getFullDate(comment.updatedAt),
  }));
};
