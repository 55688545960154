import { ChangeEvent, useEffect, useState } from "react";
import { Avatar, Button, Skeleton, Space } from "antd";
import {
  PlusCircleOutlined,
  SendOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { getNewsCommentsSelector, getProfileSelector } from "@store/selectors";
import { useAppSelector } from "@hooks/useAppSelector";
import { IComment } from "@enums/slices";
import {
  createComment,
  getCommentsDownById,
  getCommentsUpById,
} from "@store/slices/news";
import { useAppDispatch } from "@hooks/useAppDispatch";
import TextArea from "antd/es/input/TextArea";
import { Loader } from "@ui-kit/loader";
import styles from "./style.module.scss";
import { NewsComment } from "./Comment";
import { AuthModal } from "./components";

type PropsTypes = { id?: string; comments?: IComment[] };
const LIMIT = 10;

export const NewsComments: React.FC<PropsTypes> = ({ id, comments }) => {
  const { t } = useTranslation();
  const profile = useAppSelector(getProfileSelector);
  const {
    loading: commentsLoading,
    loadingUp,
    loadingDown,
    commentCount,
  } = useAppSelector(getNewsCommentsSelector);
  const dispatch = useAppDispatch();

  const [commentText, setCommentText] = useState("");
  const [skipUpComment, setSkipUpComment] = useState(0);
  const [skipDownComment, setSkipDownComment] = useState(0);
  const [limitUpComment, setLimitUpComment] = useState(LIMIT);
  const [limitDownComment, setLimitDownComment] = useState(LIMIT);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(e.target.value);
  };

  const onSend = async () => {
    if (id && commentText.trim()) {
      await dispatch(
        createComment({
          newsId: id,
          text: commentText.trim(),
        })
      );
      setCommentText("");
    }
  };

  const getUpComments = () => {
    if (id) {
      dispatch(
        getCommentsUpById({
          id,
          skip: limitUpComment <= LIMIT ? 0 : skipUpComment,
          limit: limitUpComment <= LIMIT ? limitUpComment : LIMIT,
        })
      ).then(() => {
        setLimitUpComment(limitUpComment - LIMIT);
        setSkipUpComment(skipUpComment - LIMIT);
      });
    }
  };

  const getDownComments = () => {
    if (id) {
      dispatch(
        getCommentsDownById({
          id,
          skip: skipDownComment,
          limit: limitDownComment <= LIMIT ? limitDownComment : LIMIT,
        })
      ).then(() => {
        setLimitDownComment(limitDownComment - LIMIT);
        setSkipDownComment(skipDownComment + LIMIT);
      });
    }
  };

  useEffect(() => {
    setSkipUpComment(commentCount.up - LIMIT);
    setSkipDownComment(commentCount.up + 1);
    setLimitUpComment(commentCount.up);
    setLimitDownComment(commentCount.down);
  }, [commentCount]);

  return (
    <div className={styles.bg}>
      <Space className={styles.wrapper} direction="vertical">
        {profile.user.email ? (
          <Space className={styles.comment} wrap size={16} align="center">
            <Avatar
              className={styles.avatar}
              size="large"
              icon={<UserOutlined />}
            />
            <TextArea
              showCount
              maxLength={500}
              autoSize={{ maxRows: LIMIT }}
              value={commentText}
              onChange={onChange}
              className={styles.input}
              placeholder={t("news.addComment")}
            />
            <Button
              className={styles.sendBtn}
              disabled={!commentText}
              icon={<SendOutlined />}
              loading={commentsLoading}
              onClick={onSend}
            />
          </Space>
        ) : (
          <AuthModal />
        )}

        <Space className={styles.comments} direction="vertical">
          {!loadingUp && limitUpComment > 0 && (
            <div style={{ textAlign: "center" }}>
              <PlusCircleOutlined
                onClick={getUpComments}
                style={{
                  fontSize: 35,
                  color: "#a6a6a6",
                }}
              />
            </div>
          )}
          {loadingUp && <Loader dark />}
          {comments?.map((comment, index) => (
            <NewsComment
              key={comment.id}
              newsId={id}
              index={index}
              comment={comment}
            />
          ))}
          {!loadingDown && limitDownComment > 0 && (
            <div style={{ textAlign: "center" }}>
              <PlusCircleOutlined
                onClick={getDownComments}
                style={{
                  fontSize: 35,
                  color: "#a6a6a6",
                }}
              />
            </div>
          )}
          {loadingDown && <Loader dark />}
          {commentsLoading && !comments?.length && (
            <div
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
                gap: 16,
              }}
            >
              {Array.from({ length: LIMIT }).map((_, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Skeleton key={i} avatar active className={styles.comment} />
              ))}
            </div>
          )}
        </Space>
      </Space>
    </div>
  );
};
