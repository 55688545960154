import { useCallback } from "react";
import { useAppSelector } from "@hooks/useAppSelector";
import { sendCode, setShowCodeModal } from "@store/slices";
import { getShowCodeModalSelector } from "@store/selectors";
import { getEmailSelector } from "@store/selectors/barca/barca";
import { useAppDispatch } from "@hooks/useAppDispatch";

type ReturnType = {
  email: string;
  onOpen: () => void;
  onClose: () => void;
  isModalShown: boolean;
  sendEmailCode: () => void;
};

export function useShowCodeModal(): ReturnType {
  const dispatch = useAppDispatch();
  const email = useAppSelector(getEmailSelector);
  const isModalShown = useAppSelector(getShowCodeModalSelector);

  const sendEmailCode = () => {
    dispatch(sendCode({ email }));
  };

  const onOpen = useCallback(() => {
    dispatch(setShowCodeModal(true));
    sendEmailCode();
  }, [email]);

  const onClose = useCallback(() => {
    dispatch(setShowCodeModal(false));
  }, []);

  return {
    email,
    onOpen,
    onClose,
    isModalShown,
    sendEmailCode,
  };
}
