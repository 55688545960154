import { FC, useEffect, useRef } from "react";
import cn from "classnames";
import { getYouTubeVideoId, isYouTubeUrl } from "@utils/youtubeUrl";
import { CloseOutlined } from "@ant-design/icons";
import { IVideo } from "@enums/slices";
import styles from "./ModalVideo.module.scss";

type PropsTypes = {
  open: boolean;
  onClose: () => void;
  videos: IVideo;
};

export const ModalVideo: FC<PropsTypes> = (props) => {
  const { open, onClose, videos } = props;

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const youtubeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    const root = document.getElementById("root");

    if (open && youtubeRef.current && videos) {
      youtubeRef.current.src = `https://www.youtube.com/embed/${getYouTubeVideoId(
        videos.video
      )}`;
    }

    if (!open) {
      if (videoRef.current) {
        videoRef.current.pause();
      } else if (youtubeRef.current) {
        youtubeRef.current.src = "";
      }
    }

    if (root) {
      root.style.display = open ? "none" : "block";
    }

    return () => {
      if (root) {
        root.style.display = "";
      }
    };
  }, [open, videoRef, youtubeRef]);

  return (
    <div className={cn(styles.modalVideo, { [styles.open]: open })}>
      {isYouTubeUrl(videos.video) ? (
        <iframe
          ref={youtubeRef}
          className={cn(styles.video, styles.frame)}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      ) : (
        <video
          ref={videoRef}
          className={styles.video}
          poster={videos.preview_path || ""}
          controls
        >
          <source src={videos.video} type="video/mp4" />
          Your browser does not support the video tag.
          <track kind="captions" srcLang="en" label="English" />
        </video>
      )}
      <CloseOutlined onClick={onClose} className={styles.cancelIcon} />
    </div>
  );
};
