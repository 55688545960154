import React from "react";
import { Form } from "antd";
import { ConfirmPasswordType } from "@models/login";
import { Trans } from "react-i18next";
import { FormLayout } from "../layout";
import { useConfirmPassword } from "../hooks";
import { SubmitButton } from "../components";
import { Forms } from "./forms";
import styles from "./ConfirmPassword.module.scss";

export const ConfirmPassword: React.FC = () => {
  const { form, onFinish, onFinishFailed } = useConfirmPassword();

  return (
    <FormLayout>
      <Form<ConfirmPasswordType>
        onFinish={onFinish}
        autoComplete="off"
        onFinishFailed={onFinishFailed}
        className={styles.form}
        layout="vertical"
        form={form}
      >
        <Forms />
        <SubmitButton form={form}>
          <Trans i18nKey="forgotPassword.send" />
        </SubmitButton>
      </Form>
    </FormLayout>
  );
};
