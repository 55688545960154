export const formatTimeDifference = (date: string) => {
  const time = new Date(date).getTime();
  const now = new Date().getTime();
  const differenceInSeconds = Math.floor((now - time) / 1000);

  if (differenceInSeconds < 60) {
    return `${differenceInSeconds <= 0 ? 1 : differenceInSeconds} сек.`;
  }
  if (differenceInSeconds < 3600) {
    const minutes = Math.floor(differenceInSeconds / 60);
    return `${minutes} мин.`;
  }
  if (differenceInSeconds < 86400) {
    const hours = Math.floor(differenceInSeconds / 3600);
    return `${hours} ч.`;
  }

  if (differenceInSeconds < 604800) {
    const days = Math.floor(differenceInSeconds / 86400);
    return `${days} дн.`;
  }
  const weeks = Math.floor(differenceInSeconds / 604800);
  return `${weeks} нед.`;
};
