import { ReactNode } from "react";
import styles from "./FormLayout.module.scss";

type PropsType = {
  children: ReactNode;
};

export const FormLayout: React.FC<PropsType> = ({ children }) => {
  return <div className={styles.formBlock}>{children}</div>;
};
