/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { motion } from "framer-motion";
import { Links } from "@enums/links";
import { UserOutlined } from "@ant-design/icons";
import { ImagesAlt } from "@constants/alt";
import { getProfileSelector } from "@store/selectors";
import { RoutePath } from "@router/routePath";
import { useAppSelector } from "@hooks/useAppSelector";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { logout } from "@store/slices";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey } from "@enums/localStorage";
import styles from "./LoginHeader.module.scss";

type PropsType = {
  culersVisible: boolean;
  onCancel: () => void;
};

export const LoginHeaderMobile = ({ culersVisible, onCancel }: PropsType) => {
  const { t } = useTranslation("translation");
  const profile = useAppSelector(getProfileSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout()).then(() => {
      LocalStorage.removeItem(LocalStorageKey.AccessToken);
    });
  };

  return culersVisible ? (
    <motion.div
      className={styles.culers}
      initial={{ x: "100%" }} // Starts from right (fully hidden)
      animate={{ x: 0 }} // Moves to left (fully visible)
      exit={{ x: "100%" }} // Moves back to right (fully hidden)
      transition={{ ease: "linear", duration: 0.22 }}
      onClick={(e) => e.stopPropagation()} // Prevent clicks from propagating
    >
      {profile.user.email ? (
        <>
          <Button
            className={styles.email}
            onClick={() => {
              navigate(RoutePath.profile);
              onCancel();
            }}
          >
            <UserOutlined /> {profile.user.email}
          </Button>
          <Button
            className={styles.loginButton}
            onClick={onLogout}
            icon={
              <img
                src={Links.logoHeader}
                alt={ImagesAlt}
                className={styles.imgButton}
                loading="lazy"
              />
            }
          >
            {t("login.logout")}
          </Button>
        </>
      ) : (
        <>
          <Button
            icon={<UserOutlined />}
            className={styles.registerButton}
            onClick={() => navigate(RoutePath.login)}
          >
            {t("login.submit")}
          </Button>
          <Button
            className={styles.loginButton}
            onClick={() => navigate(RoutePath.register)}
            icon={
              <img
                src={Links.logoHeader}
                alt={ImagesAlt}
                className={styles.imgButton}
                loading="lazy"
              />
            }
          >
            {t("login.register")}
          </Button>
        </>
      )}
      <div className={styles.closeIcon} onClick={onCancel} />
    </motion.div>
  ) : null;
};
