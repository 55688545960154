import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Slider,
  Greeting,
  OfferSlider,
  AcademyMap,
  News,
  Video,
} from "@features/main";
import { SEO } from "@utils/SEO";
import { getMainPages } from "@store/slices";
import { useAppDispatch } from "@hooks/useAppDispatch";
import styles from "./MainLayout.module.scss";

export const MainLayout = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMainPages({}));
  }, []);

  return (
    <div className={styles.mainBlock}>
      <SEO
        title={t("burger.filial.main")}
        description={t("main.subDesc")}
        url=""
        image="https://s3.barcelona.kg/static/academyLogo2.webp"
      />

      <OfferSlider />
      <Greeting />
      <div className={styles.wrapper}>
        <Video />
        <News />
      </div>
      <div className={styles.breadcrumbs} />
      <Slider />
      <AcademyMap />
    </div>
  );
};
