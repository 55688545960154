import { Routes } from "@enums/routes";

export const ABOUT_US_ITEMS = [
  { key: 2, href: Routes.ABOUT_US_PROJECT, label: "aboutUs.menu.project" },
  { key: 1, href: Routes.ABOUT_US_COACHES, label: "aboutUs.menu.coaches" },
];

export const ARTICLES_ITEMS = [
  { key: 1, href: Routes.ARTICLES_COMMON, label: "burger.news.newsCommon" },
  { key: 2, href: Routes.ARTICLES_BISH, label: "burger.news.newsB" },
  { key: 3, href: Routes.ARTICLES_JA, label: "burger.news.newsJ" },
];

export const SERVICES_ITEMS_BISH = [
  {
    key: 11,
    href: Routes.SERVICES_CAFE,
    label: "cafe.title",
  },
  {
    key: 12,
    href: Routes.MUSEUM,
    label: "museum.title",
  },
];

export const SERVICES_ITEMS_JA = [
  {
    key: 20,
    href: Routes.SERVICES_CAFE_JA,
    label: "cafe.title",
  },
  {
    key: 21,
    href: Routes.ACADEMY_CAMP_J,
    label: "camp.title",
  },
];

export const SERVICES_ITEMS = [
  {
    key: 1,
    type: "group",
    label: "Bishkek",
    href: "",
    children: SERVICES_ITEMS_BISH,
  },
  {
    key: 2,
    type: "group",
    label: "JalalAbad",
    href: "",
    children: SERVICES_ITEMS_JA,
  },
];

export const BARCA_EXP_ITEMS = [
  { key: 1, href: Routes.BISHKEK, label: "offices.menu.bishkek" },
  {
    key: 2,
    href: Routes.JALAL_ABAD,
    label: "offices.menu.jalal_abad",
  },
];

export const VACANCY_ITEMS = [
  { key: 1, href: Routes.VACANCIE, label: "services.vacancieBish" },
  {
    key: 2,
    href: Routes.VACANCIE_JALAL_ABAD,
    label: "services.vacancieJal",
  },
];
