import { Select } from "antd";
import { LocalStorage } from "@localStorage/localStorage";
import { LocalStorageKey } from "@enums/localStorage";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import styles from "./LanguageSelector.module.scss";

const changeLanguage = (language: string) => {
  i18n.changeLanguage(language);
  LocalStorage.setItem(LocalStorageKey.Lang, language);

  setTimeout(() => window.location.reload(), 300);
};

export const LanguageSelectorBurger = () => {
  const { t } = useTranslation();
  const lang = LocalStorage.getItem(LocalStorageKey.Lang) || "ru";

  return (
    <Select
      defaultValue={lang}
      className={styles.select}
      popupClassName={styles.menu}
      onChange={changeLanguage}
      options={[
        {
          label: <span>{t("lang")}</span>,
          title: "manager",
          options: [
            { label: <span>Кыргызча</span>, value: "ky" },
            { label: <span>Русский</span>, value: "ru" },
            { label: <span>English</span>, value: "en" },
          ],
        },
      ]}
      style={{ width: "100%" }}
    />
  );
};
