import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import XHR from "i18next-xhr-backend";
import { LocalStorage } from "./localStorage";
import { LocalStorageKey } from "./enums";

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    fallbackLng: LocalStorage.getItem(LocalStorageKey.Lang) ?? "ru",
    debug: true,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: ["en", "ru", "ky"],
  });

function applyFontBasedOnLanguage() {
  const htmlElement = document.documentElement; // Получаем корневой элемент HTML

  if (i18n.language === "en") {
    htmlElement.classList.add("font-barcelona");
    htmlElement.classList.remove("font-default");
  } else {
    htmlElement.classList.add("font-default");
    htmlElement.classList.remove("font-barcelona");
  }
}

// Вызывайте эту функцию каждый раз, когда язык меняется
i18n.on("languageChanged", applyFontBasedOnLanguage);

// И не забудьте вызвать её сразу после загрузки страницы
applyFontBasedOnLanguage();

export default i18n;
