import { AppRoutes, Routes } from "@enums/routes";

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.MAIN]: Routes.MAIN,
  [AppRoutes.LOGIN]: Routes.LOGIN,
  [AppRoutes.REGISTER]: Routes.REGISTER,
  [AppRoutes.ABOUT_US]: Routes.ABOUT_US,
  [AppRoutes.ACADEMY_CAMP]: Routes.ACADEMY_CAMP,
  [AppRoutes.ACADEMY_CAMP_J]: Routes.ACADEMY_CAMP_J,
  [AppRoutes.VACANCY_BISH_DETAILS]: Routes.VACANCY_BISH_DETAILS,
  [AppRoutes.VACANCY_JAL_DETAILS]: Routes.VACANCY_JAL_DETAILS,
  [AppRoutes.ABOUT_US_PROJECT]: Routes.ABOUT_US_PROJECT,
  [AppRoutes.ABOUT_US_COACHES]: Routes.ABOUT_US_COACHES,
  [AppRoutes.VACANCIE]: Routes.VACANCIE,
  [AppRoutes.VACANCIE_JALAL_ABAD]: Routes.VACANCIE_JALAL_ABAD,
  [AppRoutes.COACH_ID]: Routes.COACH_ID,
  [AppRoutes.ARTICLES]: Routes.ARTICLES,
  [AppRoutes.ARTICLES_COMMON]: Routes.ARTICLES_COMMON,
  [AppRoutes.ARTICLES_BISH]: Routes.ARTICLES_BISH,
  [AppRoutes.ARTICLES_JA]: Routes.ARTICLES_JA,
  [AppRoutes.ARTICLE_COMMON]: Routes.ARTICLE_COMMON,
  [AppRoutes.PROFILE]: Routes.PROFILE,
  [AppRoutes.ARTICLE_BISH]: Routes.ARTICLES_BISH,
  [AppRoutes.ARTICLE_JA]: Routes.ARTICLE_JA,
  [AppRoutes.SERVICES]: Routes.SERVICES,
  [AppRoutes.SERVICES_CAFE_JA]: Routes.SERVICES_CAFE_JA,
  [AppRoutes.SERVICES_CAFE]: Routes.SERVICES_CAFE,
  [AppRoutes.JALAL_ABAD]: Routes.JALAL_ABAD,
  [AppRoutes.BISHKEK]: Routes.BISHKEK,
  [AppRoutes.CHAMPIONSHIP]: Routes.CHAMPIONSHIP,
  [AppRoutes.FAQ]: Routes.FAQ,
  [AppRoutes.CONTACT]: Routes.CONTACT,
  [AppRoutes.PRIVACY]: Routes.PRIVACY,
  [AppRoutes.FORGOT_PASSWORD]: Routes.FORGOT_PASSWORD,
  [AppRoutes.CONFIRM_PASSWORD]: Routes.CONFIRM_PASSWORD,
  [AppRoutes.MUSEUM]: Routes.MUSEUM,
  [AppRoutes.CLINIC]: Routes.CLINIC,
  [AppRoutes.NOT_FOUND]: Routes.NOT_FOUND,
};
