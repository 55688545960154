import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RoutePath } from "@router/routePath";
import { Links } from "@enums/links";
import styles from "./ForgotPassword.module.scss";

export const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Link to={RoutePath["forgot-password"]}>
        <span className={styles.forgotPass}>{t("login.forgotPass")}</span>
      </Link>
      <img src={Links.line} alt="" className={styles.logo} />
      <Link to={RoutePath.register}>
        <span className={styles.forgotPass}>{t("login.register")}</span>
      </Link>
    </div>
  );
};
