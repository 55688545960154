/* eslint-disable camelcase */
import { FC } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Image } from "antd";
import { Links } from "@enums/links";
import cn from "classnames";
import { Routes } from "@enums/routes";
import { ITrainers } from "@enums/slices";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { setLoadingPreload } from "@store/slices";
import styles from "./TrainersRecommended.module.scss";

type PropsTypes = ITrainers;

export const TrainersRecommended: FC<PropsTypes> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { id, firstname, lastname, role_type, photo } = props;

  const onRedirect = () => {
    navigate(generatePath(Routes.COACH_ID.toString(), { id }));
    dispatch(setLoadingPreload(true));
  };

  const role = {
    trainer: t("trainer.trainer"),
    cto: t("trainer.cto"),
    manager: t("trainer.manager"),
    coordinator: t("trainer.coordinator"),
  };

  return (
    <div
      onClick={onRedirect}
      className={styles.newsRecommended}
      role="link"
      tabIndex={0}
      onKeyPress={onRedirect}
    >
      <Image
        rootClassName={styles.imageBlock}
        src={photo}
        preview={false}
        fallback={Links.preview}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={cn(styles.title)}>{`${firstname} ${lastname}`}</h2>
          <div className={styles.desc}>{role[role_type]}</div>
        </div>
      </div>
    </div>
  );
};
