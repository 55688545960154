import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { PlusOutlined } from "@ant-design/icons";
import { Regions, Routes } from "@enums/routes";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { CardBlock } from "@ui-kit/card";
import { getBishkekTrainersSelector } from "@store/selectors";
import { getTrainersBishkek } from "@store/slices";
import { Loader } from "@ui-kit/loader";
import { roleOrderTrainer } from "@enums/slices";
import styles from "./trainersBishkek.module.scss";

const LIMIT = 9;

export const BishkekTrainersBlock = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [skip, setSkip] = useState<number>(0);

  const { trainers, loading, amount } = useAppSelector(
    getBishkekTrainersSelector
  );

  const onlyTrainers = trainers.filter((item) => item.role_type === "trainer");
  const managements = trainers
    .filter((item) => item.role_type !== "trainer")
    .sort(
      (a, b) => roleOrderTrainer[a.role_type] - roleOrderTrainer[b.role_type]
    );

  const role = {
    trainer: t("trainer.trainer"),
    cto: t("trainer.cto"),
    manager: t("trainer.manager"),
    coordinator: t("trainer.coordinator"),
  };

  useEffect(() => {
    dispatch(
      getTrainersBishkek({ skip, limit: LIMIT, region: Regions.BISHKEK })
    );
  }, [skip]);

  const loadTrainers = () => {
    setSkip(skip + LIMIT);
  };

  if (!loading && !trainers.length) {
    return null;
  }

  return (
    <div className={styles.mainContent}>
      {managements.length > 0 && (
        <>
          <div className={cn(styles.title)}>{t("trainer.management_b")}</div>
          <div className={styles.trainers}>
            {managements.map((item) => (
              <CardBlock
                data={{
                  to: {
                    href: Routes.COACH_ID,
                    id: item.id,
                  },
                  title: `${item.firstname} ${item.lastname}`,
                  image: item.photo,
                  desc: role[item.role_type],
                }}
                key={item.id}
              />
            ))}
          </div>
        </>
      )}
      {onlyTrainers.length > 0 && (
        <>
          <div className={cn(styles.title)}>{t("trainer.bishkek")}</div>
          <div className={styles.trainers}>
            {onlyTrainers.map((item) => (
              <CardBlock
                data={{
                  to: {
                    href: Routes.COACH_ID,
                    id: item.id,
                  },
                  title: `${item.firstname} ${item.lastname}`,
                  image: item.photo,
                  desc: role[item.role_type],
                }}
                key={item.id}
              />
            ))}
          </div>
        </>
      )}
      {loading ? (
        <Loader dark />
      ) : (
        amount > trainers.length && (
          <button
            type="button"
            className={styles.loadBtn}
            onClick={loadTrainers}
          >
            {t("news.btn")} <PlusOutlined className={styles.loadIcon} />
          </button>
        )
      )}
    </div>
  );
};
