import { useEffect } from "react";
import { Links } from "@enums/links";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Routes } from "@enums/routes";
import styles from "./AcademyCamp.module.scss";

type BitrixFormProps = {
  fileName: "bishkekBitrixForm" | "campBitrixForm" | "jalalAbadBitrixForm";
  loaded?: boolean;
};

const types: Record<string, string> = {
  [Routes.BISHKEK]: "application.titleB",
  [`${Routes.BISHKEK}/`]: "application.titleB",
  [Routes.JALAL_ABAD]: "application.titleJ",
  [`${Routes.JALAL_ABAD}/`]: "application.titleJ",
  [Routes.ACADEMY_CAMP_J]: "application.titleCampJ",
  [`${Routes.ACADEMY_CAMP_J}/`]: "application.titleCampJ",
};

export const BitrixForm: React.FC<BitrixFormProps> = ({ fileName, loaded }) => {
  const { t } = useTranslation();

  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (hash && loaded) {
      const element = document.querySelector(hash);
      if (element) {
        const topOffset = element.getBoundingClientRect().top;

        window.scrollTo({
          top: window.pageYOffset + topOffset,
          behavior: "smooth",
        });
      }
    }
  }, [hash, loaded]);

  return (
    <div id="application" className={styles.formBlock}>
      <h2>{t(types[pathname])}</h2>
      <img src={Links.logo} alt="" />
      <iframe
        src={`../../files/${fileName}.html`}
        title="Form Bitrix"
        width="100%"
        frameBorder="0"
        height="520px"
      />
    </div>
  );
};
