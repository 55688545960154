import { Link, useLocation } from "react-router-dom";
import { Routes } from "@enums/routes";
import cn from "classnames";
import { IMenuItem } from "@utils/getLink";
import styles from "./Breadcrumbs.module.scss";

type PropsType = {
  menu: IMenuItem[];
};

export const Breadcrumbs: React.FC<PropsType> = ({ menu }) => {
  const { pathname: path } = useLocation();
  const pathname = path.replace(/\/+$/, "");

  return (
    <div
      className={cn(styles.breadcrumbs, {
        [styles.show]: pathname !== Routes.MAIN,
      })}
    >
      {menu?.map(({ key, href, label }) => (
        <Link
          key={key}
          to={href}
          className={cn(styles.subLink, {
            [styles.active]: pathname.includes(href),
          })}
        >
          {label}
        </Link>
      ))}
    </div>
  );
};
