import { useCallback, useEffect } from "react";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ForgotPasswordType } from "@models/login";
import { AppDispatch } from "@store/index";
import { forgotPassword, setEmail } from "@store/slices/barca/barcaSlice";
import { Form, FormInstance } from "antd";
import { useAlert } from "@hooks/useAlert";
import { useTranslation } from "react-i18next";

type ReturnType = {
  form: FormInstance;
  onFinish: (values: ForgotPasswordType) => void;
  onFinishFailed: (errorInfo: ValidateErrorEntity<ForgotPasswordType>) => void;
};

export const useForgotPassword = (): ReturnType => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { setAlert } = useAlert();

  const email = Form.useWatch("email", form);

  useEffect(() => {
    if (email) {
      dispatch(setEmail(email));
    }
  }, [email]);

  const onFinish = useCallback(
    (values: ForgotPasswordType) => {
      dispatch(forgotPassword({ values, navigate, setAlert })).then((data) => {
        if (data.type.includes("fulfilled")) {
          setAlert(t("alert.smsToEmail"), "info");
        }
      });
    },
    [dispatch]
  );

  const onFinishFailed = useCallback(
    (errorInfo: ValidateErrorEntity<ForgotPasswordType>) => {
      console.log("Failed:", errorInfo);
    },
    []
  );

  return {
    form,
    onFinish,
    onFinishFailed,
  };
};
