import { useShowCodeModal } from "@features/auth/hooks";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { getEmailSelector } from "@store/selectors/barca/barca";
import { checkCode } from "@store/slices";
import { Form, FormInstance } from "antd";
import { useCallback } from "react";

type FormValuesType = {
  code: string;
};

type ReturnType = {
  form: FormInstance;
  onFinish: (values: FormValuesType) => void;
};

export function useConfirmEmail(): ReturnType {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const email = useAppSelector(getEmailSelector);
  const { onClose } = useShowCodeModal();

  const onFinish = useCallback(
    (values: FormValuesType) => {
      dispatch(
        checkCode({
          verificationCode: values.code.replace(/-/g, ""),
          email,
        }) as any
      );
      form.resetFields();
      onClose();
    },
    [email]
  );

  return {
    form,
    onFinish,
  };
}
