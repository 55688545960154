import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Links } from "@enums/links";
import { SignInYandex } from "@store/slices";
import { useAppDispatch } from "@hooks/useAppDispatch";
import styles from "./Login.module.scss";

const REDIRECT_URI = "https://experience.barcelona.kg/login";

export const YandexAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  const hasEffectRun = useRef(false);

  useEffect(() => {
    if (code && !hasEffectRun.current) {
      dispatch(SignInYandex({ code, navigate })).then(() => {
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname
        );
      });
      hasEffectRun.current = true;
    }
  }, [code]);

  const onLogin = () => {
    window.location.href = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${
      process.env.REACT_APP_YANDEX_CLIENT_ID || ""
    }&redirect_uri=${REDIRECT_URI}`;
  };

  return (
    <button type="button" className={styles.authBlock} onClick={onLogin}>
      <img
        className={styles.authIcon}
        src={Links.yandexAuth}
        style={{ width: "100%", transform: "scale(1.2)" }}
        alt="yandexIcon"
      />
    </button>
  );
};
