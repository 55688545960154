import { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "@ui-kit/header";
import { Footer } from "@ui-kit/footer";
import { Loader } from "@ui-kit/loader";
import { setFirstRender } from "@store/slices";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { AppRouter } from "./providers";
import { Routes } from "./enums";

export default function App() {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const isHash = window.location.hash;

  const excludedPaths = [
    Routes.LOGIN,
    Routes.REGISTER,
    Routes.FORGOT_PASSWORD,
    Routes.CONFIRM_PASSWORD,
  ];

  useEffect(() => {
    if (!isHash) {
      window.scrollTo(0, 0);
    }

    if (pathname !== Routes.MAIN) {
      dispatch(setFirstRender(true));
    }
  }, [pathname]);

  return (
    <div className="wrapper" translate="no">
      <Suspense fallback={<Loader />}>
        {!excludedPaths.includes(pathname as Routes) && <Header />}
        <div
          style={{
            flex: 1,
          }}
        >
          <AppRouter />
        </div>

        {!excludedPaths.includes(pathname as Routes) && <Footer />}
      </Suspense>
    </div>
  );
}
