import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { motion } from "framer-motion";
import { useAppDispatch } from "@hooks/useAppDispatch";
import { useAppSelector } from "@hooks/useAppSelector";
import { setFirstRender } from "@store/slices";
import { Links } from "@enums/links";
import styles from "./Map.module.scss";

export const AcademyMap = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const renderFirst = useAppSelector((state) => state.barca.firstRender);

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  const defaultState = useMemo(
    () => ({
      center: [40.934404, 73.01274],
      zoom: 16,
    }),
    []
  );

  const academies = useMemo(
    () => [
      { coordinates: [40.934404, 73.01274], name: t("mapLocation.jalalAbad") },
      { coordinates: [18.932425, 72.831711], name: "" },
      { coordinates: [18.529289, 73.843663], name: "" },
      { coordinates: [12.979093, 77.591363], name: "" },
      { coordinates: [25.099315, 55.177327], name: "" },
      { coordinates: [31.94531, 35.928371], name: "" },
      { coordinates: [41.043287, 29.006304], name: "" },
      { coordinates: [47.446896, 18.999557], name: "" },
      { coordinates: [41.366471, 2.050854], name: "" },
      { coordinates: [33.959258, -6.875642], name: "" },
      { coordinates: [25.174661, 55.402742], name: "" },
      { coordinates: [35.444979, 139.64122], name: "" },
      { coordinates: [35.245002, -80.815892], name: "" },
      { coordinates: [32.972569, -111.537746], name: "" },
      { coordinates: [19.324371, -99.173891], name: "" },
      {
        coordinates: [42.81494, 74.612642],
        name: t("mapLocation.bishkek"),
      },
    ],
    []
  );

  const handlePlacemarkClick = (coordinates: number[]) => {
    window.open(
      `https://yandex.com/maps/?rtext=~${coordinates.join(",")}&rtt=auto`,
      "_blank"
    );
  };

  useEffect(() => {
    if (inView && !renderFirst) {
      dispatch(setFirstRender(true));
    }
  }, [inView]);

  return (
    <motion.div className={styles.map} ref={ref}>
      {(renderFirst || inView) && (
        <YMaps>
          <Map
            defaultState={defaultState}
            width="98%"
            height={550}
            options={{
              minZoom: 2,
            }}
          >
            {academies.map((academy, index) => (
              <Placemark
                key={Number(index + 1)}
                geometry={academy.coordinates}
                onClick={() => handlePlacemarkClick(academy.coordinates)}
                options={{
                  iconLayout: "default#image",
                  iconImageHref: Links.logo,
                  iconImageSize: [80, 80],
                  iconImageOffset: [-15, -15],
                }}
                properties={{
                  hintContent: academy.name,
                }}
                modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
              />
            ))}
          </Map>
        </YMaps>
      )}
    </motion.div>
  );
};
