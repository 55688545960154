import { ICommentAnswer } from "./news";

export type CityTypes = "bishkek" | "jalal-abad";
export type socialNTypes = "instagram" | "facebook" | "twitter" | "youtube";
export type ContactType = "academy" | "store" | "jalgroup";
export type InfoPageType = "faq" | "privacy-policy" | "cookies" | "fin-report";
export type InfoPageViewType = "barca-experience" | "jalgroup";
export type TrainerRoleType = "trainer" | "manager" | "coordinator" | "cto";

export interface IVideo {
  id: 1;
  preview_path: string;
  video: string;
  createdAt: string;
}

export interface IBanner {
  id: number;
  title: string;
  description: string;
  banner: string;
  link: string;
  createdAt: string;
}

export interface IResource {
  title: string;
  text: string;
  btn_link: string;
}

export interface IProject {
  id: number;
  title: string;
  text: string;
  description: string;
  videos?: IVideo;
  createdAt: string;
  images: string[];
  banners?: IBanner;
  resources?: IResource[];
}

export interface ITrainers {
  id: number;
  firstname: string;
  lastname?: string;
  description: string;
  trainer_type?: CityTypes;
  role_type: TrainerRoleType;
  photo: string;
  experience?: number;
  country?: string;
  status?: string;
  url?: string;
}

export const roleOrderTrainer: Record<TrainerRoleType, number> = {
  cto: 1,
  coordinator: 2,
  manager: 3,
  trainer: 4,
};

export type VacancyTypes = "barca-experience" | "jalgroup";
export type VacancyCities = "jalal-abad" | "bishkek";
export type VacancyStatuses = "active" | "archived" | "deleted";
export type VacancyWorkTypes = "office" | "remote";
export type VacancyExperienceTypes =
  | "none"
  | "less-one"
  | "one_three"
  | "three_six";
export type VacancyEmploymentTypes = "part-time" | "full-time";

export interface IVacancies {
  id: number;
  title: string;
  description: string;
  work_conditions: string;
  skills: string;
  status: VacancyStatuses;
  vacancy_city: VacancyCities;
  vacancy_type: VacancyTypes;
  experience: VacancyExperienceTypes;
  employment: VacancyEmploymentTypes;
  work_type: VacancyWorkTypes;
  min_salary: number;
  max_salary: number;
  createdAt: string;
}

export interface IServiceItem {
  id: string;
  title: string;
  description: string;
  text: string;
  banners: IBanner;
  createdAt: string;
}

export interface IService {
  id: string;
  title: string;
  description: string;
  text: string;
  banners: IBanner;
  images: string[];
  videos?: IVideo;
  status: string;
  service_items: IServiceItem[];
  createdAt: string;
}

export interface IChampionshipService
  extends Omit<IService, "banners" | "images" | "service_items"> {
  banners: IBanner[];
  service_items: (IServiceItem & { images?: string[]; table?: string })[];
}

export interface IServiceWithTo extends IService {
  to: string;
}

export interface IProfile {
  id: string;
  name: string;
  lastname: string;
  email: string;
  phone: string | null;
}

export interface IReply {
  id: number;
  createdAt: string;
  updatedAt: string;
  read: boolean;
  comment: Omit<ICommentAnswer, "reply">;
}

export interface IContact {
  id: number;
  title: string;
  adreses: string;
  type: ContactType;
  workingHours: string[];
  phone: { phoneNumber: string }[];
  email?: string;
  fax?: string;
  site?: {
    title: string;
    url: string;
  };
  note?: string;
  createdAt: string;
}

export interface IContactRes extends Omit<IContact, "workingHours"> {
  workingHours: { id: number; label: string; text: string }[];
}

export interface IQuestionAnswer {
  id: number;
  question: string;
  answer: string;
  createdAt: string;
}

export interface IExpPages {
  id: number;
  leftBanner?: IBanner;
  centerBanner?: IBanner;
  rightBanner?: IBanner;
  videos?: IVideo;
  createdAt: string;
}

export interface IExpPagesRes {
  id: number;
  banners: IBanner[];
  videos?: IVideo;
  createdAt: string;
}

export interface IInfoPage {
  id: number;
  title: string;
  text: string;
  images: string[];
  question_answers: IQuestionAnswer[];
  info_page_type: InfoPageType;
  createdAt: string;
}

export interface INetworks {
  id: number;
  title: string;
  url: string;
  type: CityTypes;
  social_network_type: socialNTypes;
}

export interface IGetParams {
  skip?: number;
  limit?: number;
}
